import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { FeedbackQuestion } from "../components/feedback/FeedbackTypes";
import {
  Feedback,
  FeedbackEdge,
  FeedbackFormat,
  FeedbackMetricTargetType,
  useSiteFeedbackQuery,
} from "../generated/gateway-client";
import { useViewerTokens } from "../hooks/useViewerTokens";
import { FeedbackBox } from "./FeedbackBox";

const mapFormatToMetricTargetType = (format: FeedbackFormat): FeedbackMetricTargetType => {
  switch(format) {
    case "NPS":
      return FeedbackMetricTargetType.Nps;
    case "FACE":
      return FeedbackMetricTargetType.Face;
    default:
      return FeedbackMetricTargetType.Nps;
  }
}

export const FeedbackWidget: React.FC<{}> = () => {
  const viewerTokens = useViewerTokens();
  const router = useRouter();

  const transformFeedbackQuestion = (feedback: Feedback): FeedbackQuestion => ({
    title: viewerTokens(feedback.question.title),
    format: feedback.question.format,
    satisfied_text_label: feedback.question.satisfiedLabelText,
    unsatisfied_text_label: feedback.question.unsatisfiedLabelText,
    uuid: feedback.uuid,
    accentColour: feedback.contrastColor ?? "#2600aa",
    metricTargetType: mapFormatToMetricTargetType(feedback.question.format),
    repeatDurationMonths: feedback.repeatDurationMonths!,
  });

  const { data, error, refetch } = useSiteFeedbackQuery();

  useEffect(() => {
    const handleRouteChange = async () => {
      await refetch();
    }

    router.events.on("routeChangeStart", handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, []);

  if (error != null) {
    return null;
  }

  let questions: FeedbackQuestion[] | null;

  if (data != null) {
    const feedbackEdge = data.feedbackFeed.edges as FeedbackEdge[];
    questions = feedbackEdge != null ?
      feedbackEdge.map((edge) => transformFeedbackQuestion(edge.node!))
      : [];
  } else {
    return null;
  }

  if (questions == null) {
    return null;
  }

  const question = questions[0];

  if (question == null) {
    return null;
  }

  return <FeedbackBox question={question} key={question.uuid} />;
};
