
import { PushNotificationPayloads } from "@equiem/user-pubsub";
import { Site } from "@equiem/web-ng-lib";
import { useContext, useMemo } from "react";
import { usePushTrackMetricMutation } from "../../generated/gateway-client";

export const useDeepLinkHandler = () => {
  const [mutation] = usePushTrackMetricMutation();
  const site = useContext(Site);

  return useMemo(() => ({
    onClick: async (data: Partial<PushNotificationPayloads.Data.DeepLinkData>, title?: string | null, body?: string | null) => {
      if (data.link !== undefined && title != null && body != null) {
        await mutation({
          variables: {
            input: {
              timestamp: new Date().getTime(),
              siteUuid: site.uuid,
              title,
              body,
              link: data.link,
            },
          },
        });
        window.location.href = data.link;
      }
    },
  }), []);
};
