import { createContext, useEffect, useState } from "react";
import {
  useClearChatHistoryMutation,
  useKeepChatHistoryAliveMutation,
  useSendAiChatMessageMutation,
} from "../generated/ai-client";
import { notNullOrUndefined } from "@equiem/web-ng-lib";

export const ASSISTANT = "assistant";

export interface ChatThread {
  role: string;
  date: Date;
  message: string;
}

interface ChatContext {
  thread: ChatThread[];
  open: boolean;
  setOpen: (open: boolean, clear?: boolean) => Promise<void>;
  loading: boolean;
  sendMessage: (message: string) => Promise<void>;
  chatStarted: boolean;
  helloMessage: string;
  showPrivacy: boolean;
  setShowPrivacy: (show: boolean) => void;
}

export const ChatContext = createContext<ChatContext>({
  thread: [],
  open: false,
  setOpen: async () => Promise.resolve(),
  loading: false,
  sendMessage: async () => Promise.resolve(),
  chatStarted: false,
  helloMessage: "",
  showPrivacy: true,
  setShowPrivacy: () => {},
});

interface Props {
  userName?: string;
}

export const ChatProvider: React.FC<Props> = ({ children, userName }) => {
  const helloMessage = `Hi${userName != null ? ` ${userName}` : ""}! I'm AI Concierge and here to help with your building related questions!`;

  const defaultThread: ChatThread[] = [
    {
      role: ASSISTANT,
      date: new Date(),
      message: helloMessage,
    },
  ];

  const [chatStarted, setChatStarted] = useState(false);
  const [open, _setOpen] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(true);
  // the thread shall contain messages from newest to oldest
  const [thread, setThread] = useState<Array<{ role: string; date: Date; message: string }>>(defaultThread);
  const [loading, _setLoading] = useState(false);
  const [chatMutation] = useSendAiChatMessageMutation();
  const [clearThreadMutation] = useClearChatHistoryMutation();
  const [keepAliveMutation] = useKeepChatHistoryAliveMutation();
  const [lastMessageId, setLastMessageId] = useState<string>();
  const [threadId, setThreadId] = useState<string>();
  const [keepAlive, setKeepAlive] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (keepAlive && threadId != null) {
      interval = setInterval(() => {
        keepAliveMutation({
          variables: {
            threadId,
          },
        }).catch(() => {});
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [keepAlive, threadId, keepAliveMutation]);

  const setOpen = async (val: boolean, clear = false) => {
    _setOpen(val);
    if (clear) {
      setThread(defaultThread);
      setLastMessageId(undefined);
      setKeepAlive(false);
      setChatStarted(false);
      if (threadId != null) {
        await clearThreadMutation({
          variables: {
            threadId,
          },
        });
      }
    } else {
      setKeepAlive(true);
    }
  };

  const setLoading = (val: boolean) => {
    _setLoading(val);
  };

  const sendMessage = async (message: string) => {
    try {
      setLoading(true);
      setChatStarted(true);
      setShowPrivacy(false);
      setThread((prev) => [
        ...prev,
        {
          role: "user",
          date: new Date(),
          message,
        },
      ]);
      const response = await chatMutation({
        variables: {
          threadId,
          lastMessageId,
          message,
        },
      });

      setLoading(false);

      if (response.data == null) {
        return;
      }

      setThreadId(response.data.sendAiLlamaChatMessage.threadId);
      setLastMessageId(response.data.sendAiLlamaChatMessage.lastMessageId);
      const { messages } = response.data.sendAiLlamaChatMessage;
      setThread((prev) => [
        ...prev,
        ...messages
          .map(({ role, content }) =>
            role === ASSISTANT
              ? content.map((item) =>
                  item.__typename === "AiChatMessageContentText"
                    ? {
                        role,
                        date: new Date(),
                        message: item.value,
                      }
                    : null,
                )
              : null,
          )
          .flat()
          .filter(notNullOrUndefined),
      ]);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <ChatContext.Provider
      value={{ thread, sendMessage, open, setOpen, loading, chatStarted, helloMessage, showPrivacy, setShowPrivacy }}
    >
      {children}
    </ChatContext.Provider>
  );
};
