import { formatters, Site, useTranslation } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { Clock } from "../../icons/Clock";
import { Location } from "../../icons/Location";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  occurrence: SearchNode<"EventOccurrenceEdge">;
}

export const EventSearchResult: React.FC<Props> = ({ occurrence }) => {
  const { t, i18n } = useTranslation();

  const { timezone } = useContext(Site);

  const eventStartDate = formatters.dateshort(occurrence.startDate, i18n.language, { timezone });
  const eventStartTime = formatters.timeshort(occurrence.startDate, i18n.language, { timezone });
  const eventEndDate = formatters.dateshort(occurrence.endDate, i18n.language, { timezone });
  const eventEndTime = formatters.timeshort(occurrence.endDate, i18n.language, { timezone });

  const body = (
    <>
      <div className="d-flex">
        <span><Clock /></span>
        <span className="label">
          {eventStartDate} {eventStartTime} - {eventEndDate !== eventStartDate ? `${eventEndDate} ` : ""}{eventEndTime}
        </span>
      </div>
      { occurrence.location != null ? (
        <div className="d-flex">
          <span><Location /></span>
          <span className="label">{occurrence.location}</span>
        </div>
      ) : null }
      <style jsx>{`
        .label {
          margin: .125rem 0 0 .3125rem;
        }
      `}</style>
    </>
  );

  return (
    <SearchResult
      href="/event/[uuid]"
      as={`/event/${occurrence.uuid}`}
      title={occurrence.title}
      body={body}
      imageUrl={occurrence.eventPost?.featuredImage.url ?? undefined}
      footer={t("main.eventPostedOn", {
        date: formatters.dateshort(
          occurrence.eventPost?.publishDate ?? 0,
          i18n.language,
          { timezone },
        ),
      })}
    />
  );
}
