import { ErrorBoundary } from "@equiem/web-ng-lib";
import React from "react";

export interface Props {
  className?: string;
  background?: "white" | "grey";
  children?: React.ReactNode;
}

export const headerAndFooterHeight = 320;

export const PageBody: React.FC<Props> = ({ background, className, children }) => {
  const bgClass = background == null ? "" : (background === "grey" ? "bg-grey" : "bg-white");

  return (
    <>
      <div className={`page-body ${bgClass} ${className ?? ""}`}>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </div>
      <style jsx>{`
          .page-body {
            min-height: calc(100vh - ${headerAndFooterHeight}px);
          }
        `}
      </style>
    </>
  );
};
