import { Viewer } from "@equiem/web-ng-lib";
import React from "react";
import { useAuthenticatedViewer } from "../hooks/useAuthenticatedViewer";

export const ViewerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const viewer = useAuthenticatedViewer();

  return (
    <Viewer.Provider value={viewer}>{children}</Viewer.Provider>
  );
};
