import { formatters, Site, useTranslation } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  newsPost: SearchNode<"NewsEdge">;
}

export const NewsSearchResult: React.FC<Props> = ({ newsPost }) => {
  const { t, i18n } = useTranslation();

  const { timezone } = useContext(Site);

  return (
    <SearchResult
      href="/news/[uuid]"
      as={`/news/${newsPost.uuid}`}
      title={newsPost.title}
      body={newsPost.excerpt}
      imageUrl={newsPost.featuredImage.url ?? undefined}
      footer={t("main.newsPostedOn", {
        date: formatters.dateshort(newsPost.publishDate, i18n.language, {
          timezone,
        }),
      })}
    />
  );
};
