import { formatters, useTranslation } from "@equiem/web-ng-lib";
import { Button } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Cart } from "../contexts/CartProvider";
import { MainSubmenu } from "../contexts/MainSubmenuProvider";

export const SiteNavbarCart: React.FC = () => {
  const { t, i18n } = useTranslation();

  const subnavs = useContext(MainSubmenu);
  const { itemCount, show, showCartBtn } = useContext(Cart);
  const cartEmpty = itemCount === 0;
  const containerClass = (!showCartBtn || subnavs.cartPrefix == null && cartEmpty) ? "d-none" : "";
  const cartPrefixMd = cartEmpty ? "12" : "8";
  const cartPrefixLg = cartEmpty ? "12" : "9";

  return (
    <div className={`${containerClass} cart-submenu border-bottom px-3`}>
      <Container fluid="md" className="px-0">
        <Row noGutters>
          {subnavs.cartPrefix ? (
            <Col md={cartPrefixMd} lg={cartPrefixLg}>
              {subnavs.cartPrefix}
            </Col>
          ) : null }
          {
            cartEmpty ? null : (
              <Col md="auto" className="d-none d-md-flex ml-auto py-2">
                <Button
                  variant="outline"
                  className="text-center d-flex justify-content-center align-items-center py-2"
                  onClick={show}
                >
                  {t("main.viewShoppingCart")} <div className="count px-2 ml-2">{formatters.integer(itemCount, i18n.language)}</div>
                </Button>
              </Col>
            )
          }
        </Row>
      </Container>
      <style jsx>{`
        .cart-submenu {
          background: #fff;
        }
        .count {
          background: #FF3737;
          border-radius: 22px;
          color: #fff;
          line-height: 22px;
          padding-top: 2px;
        }
      `}</style>
    </div>
  );
}
