import { useTranslation } from "@equiem/web-ng-lib";
import { Site, Theme } from "@equiem/web-ng-lib";
import React, { useCallback, useContext } from "react";
import { Form } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { SearchContext } from "../../contexts/SearchContext";
import { ContentSortType, SearchContentType } from "../../generated/gateway-client";
import { useLogoBackground } from "../../hooks/useLogoBackground";

export const SearchFilters: React.FC = () => {
  const { t } = useTranslation();

  const search = useContext(SearchContext);
  const site = useContext(Site);
  const on = useCallback((type: SearchContentType) => search.typeOn(type) && !search.allTypesOn(), [search]);
  const { bgColor } = useLogoBackground();
  const { breakpoints } = useContext(Theme);

  const commonClasses = "py-1 px-2 px-md-3 mb-2 mb-md-0 mr-1 mr-md-2";

  return (
    <div className="search-filters d-flex flex-wrap align-items-center justify-content-between">
      <div>
        <a onClick={() => {
          search.updateTypes([]);
        }} className={`${commonClasses} ${search.allTypesOn() ? "on" : "off"}`}>{t("main.all")} <span><AiOutlinePlus /></span></a>
        <a onClick={() => {
          search.toggleType(SearchContentType.NewsPost);
        }} className={`${commonClasses} ${on(SearchContentType.NewsPost) ? "on" : "off"}`}>{t("main.news")} <span><AiOutlinePlus /></span></a>
        <a onClick={() => {
          search.toggleType(SearchContentType.EventPost);
        }} className={`${commonClasses} ${on(SearchContentType.EventPost) ? "on" : "off"}`}>{t("main.events")} <span><AiOutlinePlus /></span></a>
        <a onClick={() => {
          search.toggleType(SearchContentType.BuildingInfoPost);
        }} className={`${commonClasses} ${on(SearchContentType.BuildingInfoPost) ? "on" : "off"}`}>{t("main.buildingInfo")} <span><AiOutlinePlus /></span></a>
        {
          site.store.navEnabled ? (
            <a onClick={() => {
              search.toggleStoreType();
            }} className={`${commonClasses} ${on(SearchContentType.Vendor) ? "on" : "off"}`}>{site.store.title} <span><AiOutlinePlus /></span></a>
          ) : null
        }
      </div>
      <div className="sort d-flex align-items-center">
        <label htmlFor="sort-select" className="mb-0 mr-2">{t("main.sortBy")}</label>
        <Form.Control
          as="select"
          id="sort-select"
          custom
          value={search.sort}
          onChange={(e) => {
            search.updateSortType(e.target.value as ContentSortType)
          }}>
          <option value={ContentSortType.Relevance}>{t("main.mostRelevant")}</option>
          <option value={ContentSortType.DistanceFromNow}>{t("main.latest")}</option>
        </Form.Control>
      </div>
      <style jsx>{`
      a {
        display: inline-block;
        border: 1px solid #000;
        border-radius: 32px;
        opacity: .3;
        cursor: pointer;
        color: #000;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        transition: .2s ease-in-out;
      }
      a span {
        display: inline-block;
        transition: .25s ease-in-out;
      }
      a.on {
        opacity: 1;
        border-color: ${bgColor};
        color: ${bgColor};
      }
      a.on span {
        transform: rotate(45deg);
      }
      a:hover {
        text-decoration: none;
      }
      a:last-child {
        margin-right: 0 !important; )
      }
      .search-filters :global(select) {
        border-radius: 0;
        background-color: transparent;
        border: 0;
        padding: 0 16px 0 0;
        background-position: center right;
        width: auto;
        font-size: 12px;
        flex-grow: 1;
        color: #212529;
        font-weight: 600;
      }
      .sort {
        color: #212529;
        border: 1px solid rgba(0, 0, 0, .1);
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
      }
      .sort label {
        opacity: .6;
      }
      @media (max-width: 34.5rem) {
        .search-filters {
          display: block !important;
        }
      }
      @media (min-width: ${breakpoints.md}px) {
        a {
          font-size: 14px;
          line-height: 22px;
          border-width: 2px;
          border-radius: 40px;
        }
        .sort {
          border: 0;
          font-size: 14px;
        }
        .search-filters :global(select) {
          font-size: 14px;
        }
      }
      `}</style>
    </div>
  );
};
