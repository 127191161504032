import { PushNotificationPayloads } from "@equiem/user-pubsub";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useRsvpReminderHandler = () => {
  const router = useRouter();

  return useMemo(() => ({
    onClick: async (data: Partial<PushNotificationPayloads.Data.RsvpReminderData>) => {
      if (data.order == null) {
        await router.push(`/event/${data.occurrence}`);
      }
      else {
        await router.push(`/order/${data.order}/status`);
      }
    },
  }), [router]);
};
