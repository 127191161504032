import { useTranslation } from "@equiem/web-ng-lib";
import { Button, SkeletonLine } from "@equiem/web-ng-lib";
import React, { useCallback, useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Cart } from "../../contexts/CartProvider";
import { UserCartItem } from "./UserCartItem";
import { UserCartItemBookable } from "./UserCartItemBookable";

interface Props {
  showChildrenWhenEmpty?: boolean;
  children?: React.ReactNode;
}

interface Status {
  id: string;
  loading: boolean;
  invalid: boolean;
}

export const UserCartItems: React.FC<Props> = ({ children, showChildrenWhenEmpty = false }) => {
  const { t } = useTranslation();

  const { itemCount, items, remove } = useContext(Cart);
  const [statuses, setStatuses] = useState<Status[]>(items.map((item) => ({
    id: item.id,
    loading: true,
    invalid: false,
  })));

  const invalidCb = useCallback((id: string) => {
    setStatuses((current) => current.map((status) => ({
      ...status,
      invalid: status.id === id ? true : status.invalid,
    })));
  }, [setStatuses]);

  const finishLoadingCb = useCallback((id: string) => {
    setStatuses((current) => current.map((status) => ({
      ...status,
      loading: status.id === id ? false : status.loading,
    })));
  }, [setStatuses]);

  const invalids = statuses.flatMap((status) => status.invalid ? [status.id] : []);

  const loading = statuses.some((status) => status.loading);

  const clearAll = invalids.length === itemCount;

  const clearCallback = useCallback(() => {
    remove(invalids);
    setStatuses((current) => current.filter((x) => invalids.findIndex((id) => id === x.id) === -1));
  }, [invalids, itemCount, clearAll]);

  if (itemCount === 0) {
    return (
      <>
        <div>{t("main.cartIsEmpty")}</div>
        { showChildrenWhenEmpty && children != null ? <div>{children}</div> : null }
      </>
    );
  }

  return (
    <>
      <Container>
        <Row>
          <Col className="pb-3">{t("main.from")} <strong>{items[0].vendor.name}</strong></Col>
        </Row>
        {items.map((item) => item.type === "BOOKING" ?
          <UserCartItemBookable key={item.id} item={item} setInvalid={invalidCb} finishLoading={finishLoadingCb} /> :
          <UserCartItem key={item.id} item={item} setInvalid={invalidCb} finishLoading={finishLoadingCb} /> )}
      </Container>
      {
        loading ? <SkeletonLine width="100%" height="40px" /> : (
          <>
            { invalids.length === 0 && children != null ? children : (
              <Button variant="outline" size="lg" className="w-100" onClick={clearCallback}>
                {clearAll ? t("main.clearCart") : t("main.removeUnavailableItems", { count: invalids.length })}
              </Button>
            )}
          </>
        )
      }
    </>
  );
}
