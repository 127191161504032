import { useTranslation } from "@equiem/web-ng-lib";
import { Button, ProgressCircle } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { BrowserNotifications } from "../../contexts/BrowserNotifications";
import { usePushNotificationHandler } from "../../hooks/usePushNotificationHandler";
import { PushNotifications } from "../../hooks/usePushNotifications";
import { SlideToggle } from "../form/SlideToggle";
import { PartyPopper } from "../icons/PartyPopper";
import { Notification } from "./Notification";

interface Props {
  className?: string;
  loading: boolean;
  hasMore: boolean;
  fetchMore: () => Promise<void>,
  messages: PushNotifications["messages"];
  deleteNotification: (message: PushNotifications["messages"][number]) => Promise<void>;
  onClickNotification: () => void,
}

export const Notifications: React.FC<Props> = ({
  messages,
  loading,
  fetchMore,
  hasMore,
  className = "",
  deleteNotification,
  onClickNotification = () => undefined,
}) => {
  const { t } = useTranslation();

  const browserNotifications = useContext(BrowserNotifications);
  const handler = usePushNotificationHandler({ onDelete: deleteNotification });

  return (
    <>
      { browserNotifications.supported ? (
        <div className="browser-notifications d-flex justify-content-between">
          <small className="mr-1">{t("main.browserNotifications")}</small>
          <SlideToggle
            id="necessary"
            color="contrast"
            size="sm"
            checked={browserNotifications.enabled}
              onChange={(e) => {
              browserNotifications.setEnabled(e.target.checked);
            }} />
        </div>
      ) : null}
      <div className={`notifications ${className}`}>
        {messages.map((message) => (
          <Notification
            key={message.uuid}
            message={message}
            onClick={!handler.supportsClick(message) ? undefined : () => {
              handler.onClick(message);
              onClickNotification();
            }}
            onDismiss={() => (
              deleteNotification(message)
            )}
          />
        ))}
        {loading || messages.length > 0 || hasMore ? null : (
          <div className="text-center">
            <p className="empty-icon m-2"><PartyPopper /></p>
            <p className="m-3">{t("main.allUpToDate")}</p>
          </div>
        )}
        {!loading && !hasMore ? null : (
          <div className="px-3 pt-3 text-center">{loading ? <ProgressCircle size="md" /> : hasMore ? (
            <Button variant="link" size="sm" onClick={() => {
              fetchMore().catch((e) => {
                console.error(e);
              })
            }}><AiFillCaretDown /> {t("main.loadMore")}</Button>
          ) : null
          }</div>
        )}
      </div>
      <style jsx>{`
        .notifications {
          max-height: 90vh;
          overflow: auto;
          overflow-x: hidden;
        }
        .browser-notifications {
          padding: 32px 0 8px;
        }
        .empty-icon {
          opacity: 0.2;
        }
      `}</style>
    </>
  );
}
