import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";

interface Props {
  top?: string;
  left?: string;
  children?: React.ReactNode;
}

export const ImageTag: React.FC<Props> = ({ children, top = "0", left = "0" }) => {
  const site = useContext(Site);

  return (
    <>
      <div className="image-tag rounded-left">
        {children}
      </div>
      <style jsx>{`
        .image-tag {
          position: absolute;
          background: linear-gradient(to bottom right, ${site.primaryColour} 0%, ${site.primaryColour} 50%, transparent 50%, transparent 100%);
          color: ${site.primaryContrastColour};
          z-index: 1;
          padding: 8px 0 0 8px;
          width: 80px;
          height: 80px;
          border-bottom-left-radius: 0 !important;
          top: ${top};
          left: ${left};
        }
      `}</style>
    </>
  )
}
