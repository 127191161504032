import { stringNotEmpty } from "@equiem/web-ng-lib";
import React from "react";
import { useLinktype } from "../hooks/useLinkType";
import { useViewerTokens } from "../hooks/useViewerTokens";

interface Props {
  uuid: string;
  title: string;
  url: string;
  iconUrl?: string | null;
  iconHeight?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  appendInternalNsNx?: boolean;
  position?: number;
}

const appendQs = (url: string, keyVal: Array<{ key: string; value: string}>) =>
  `${url}${url.indexOf("?") >= 0 ? "&" : "?"}${keyVal.map((kv) => `${encodeURIComponent(kv.key)}=${encodeURIComponent(kv.value)}`).join("&")}`;

export const QuickLink: React.FC<Props> = ({
  uuid,
  title,
  url,
  iconUrl,
  onClick,
  position,
  appendInternalNsNx = false,
  className = "",
  iconHeight = "14px",
}) => {
  const viewerTokens = useViewerTokens();
  const { linkType } = useLinktype();
  const type = linkType(url);
  let processedUrl = viewerTokens(url);
  let targetBlank = type === "external";
  let openInIframe = false;

  try {
    openInIframe = new URL(processedUrl, window.location.origin).searchParams.get("eq.iframe") != null;
    if (openInIframe) {
      processedUrl = `/embed/${uuid}`;
      targetBlank = false;
    }
  } catch (e) {
    // Ignore as probably caused by browser issue, or invalid URL..
  }

  const href = ((type === "internal" || openInIframe) && appendInternalNsNx && position != null) ?
    appendQs(processedUrl, [{ key: "ns", value: "quicklink" }, { key: "nx", value: `${position}` }]) : processedUrl;

  return (
    <>
      <a
        className={`${className} d-flex align-items-center`}
        onClick={onClick}
        href={href}
        target={targetBlank ? "_blank" : undefined}
        rel={targetBlank ? "noopener noreferrer" : undefined}
      >
        {stringNotEmpty(iconUrl) ? <img src={iconUrl} style={{ height: iconHeight }} className="mr-2" /> : null}
        {viewerTokens(title)}
      </a>
      <style jsx>{`
        a {
          font-size: 14px;
        }
        img {
          image-rendering: -moz-crisp-edges;         /* Firefox */
          image-rendering:   -o-crisp-edges;         /* Opera */
          image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
        }
      `}</style>
    </>
  );
}
