import { useRouter } from "next/router";
import React, { useEffect, useReducer } from "react";

interface Item {
  id: string;
  el: JSX.Element;
}

interface ReducerState {
  submenus: Item[];
  cartPrefix: JSX.Element | null;
}

const emptyState: ReducerState = {
  submenus: [],
  cartPrefix: null,
};

type AddRemoveCartPrefix = { type: "CARTPREFIX"; el: JSX.Element | null };
type AddItem = { type: "ADDITEM"; el: JSX.Element; id: string };
type RemoveItem = { type: "REMOVEITEM"; id: string };
type Reset = { type: "RESET"; initialState: ReducerState };
type Action = AddItem | RemoveItem | AddRemoveCartPrefix | Reset;

const MainSubmenuReducer: React.Reducer<ReducerState, Action> = (
  state,
  action,
) => {
  switch (action.type) {
    case "CARTPREFIX":
      return { ...state, cartPrefix: action.el };

    case "ADDITEM":
      return {
        ...state,
        submenus: [
          ...state.submenus.filter((s) => s.id !== action.id),
          { el: action.el, id: action.id },
        ],
      };

    case "REMOVEITEM":
      return {
        ...state,
        submenus: state.submenus.filter((s) => s.id === action.id),
      };

    case "RESET":
      return action.initialState;

    default:
      return state;
  }
};

export type MainSubmenuContext = {
  addCartPrefix: (el: JSX.Element) => void;
  removeCartPrefix: () => void;
  add: (id: string, el: JSX.Element) => void;
  remove: (id: string) => void;
} & ReducerState;

export const MainSubmenu = React.createContext<MainSubmenuContext>({
  addCartPrefix: () => {
    throw new Error("Not implemented.");
  },
  removeCartPrefix: () => {
    throw new Error("Not implemented.");
  },
  add: () => {
    throw new Error("Not implemented.");
  },
  remove: () => {
    throw new Error("Not implemented.");
  },
  ...emptyState,
});

export const MainSubmenuProvider: React.FC<{
  initialState?: ReducerState;
  children?: React.ReactNode;
}> = ({ children, initialState = emptyState }) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(MainSubmenuReducer, initialState);

  const contextValues: MainSubmenuContext = {
    ...state,
    addCartPrefix: (el) => {
      dispatch({ type: "CARTPREFIX", el });
    },
    removeCartPrefix: () => {
      dispatch({ type: "CARTPREFIX", el: null });
    },
    add: (id, el) => {
      dispatch({ type: "ADDITEM", id, el });
    },
    remove: (id) => {
      dispatch({ type: "REMOVEITEM", id });
    },
  };

  useEffect(() => {
    dispatch({ type: "RESET", initialState });
  }, [router.asPath]);

  return (
    <MainSubmenu.Provider value={contextValues}>
      {children}
    </MainSubmenu.Provider>
  );
};
