import {
  MobileView,
  ProgressCircle,
  Site,
  stringNotEmpty,
  Theme,
} from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext } from "react";

import { useLogoBackground } from "../hooks/useLogoBackground";
import { PageBody } from "./PageBody";

export const SiteLoading: React.FC = () => {
  const { inMobileView } = useContext(MobileView);
  const site = useContext(Site);
  const { whiteBg, bgColor, fgColor } = useLogoBackground();
  const { breakpoints } = useContext(Theme);

  return inMobileView ? (
    <PageBody background="white">
      <div className="text-center py-3">
        <ProgressCircle size="lg" />
      </div>
    </PageBody>
  ) : (
    <>
      <div className="site-nav-cont">
        <div
          className={`site-nav d-flex align-items-center ${
            whiteBg ? " border-bottom shadow-sm" : ""
          }`}
        >
          {stringNotEmpty(site.logo) ? (
            <Link href="/" passHref>
              <a className="logo-container d-block h-100">
                <img src={site.logo ?? ""} />
              </a>
            </Link>
          ) : null}
        </div>
      </div>
      <PageBody background="white">
        <div className="text-center py-3">
          <ProgressCircle size="lg" />
        </div>
      </PageBody>
      <style jsx>{`
        .site-nav-cont {
          position: sticky;
          top: 0;
        }
        .site-nav {
          background: ${bgColor};
          color: ${fgColor} !important;
          padding: 8px;
        }
        .site-nav :global(a) {
          color: inherit;
        }
        @media (min-width: ${breakpoints.md}px) {
          .site-nav {
            padding: 8px 32px;
            min-height: 72px;
          }
        }
      `}</style>
    </>
  );
};
