import { ApolloClient } from "@apollo/client";
import React from "react";

export interface AuthenticationContext {
  authenticated: boolean;
  authenticationError?: string;
  idToken?: string;
  accessToken?: string;
  refresh?: () => Promise<void>;
  setLocale?: (locale: string) => void;
}

export type SessionContext =
| AuthenticationContext & { side: "server" }
| AuthenticationContext & { side: "client"; authenticatedClient: ApolloClient<any> };

export const Session = React.createContext<SessionContext>({
  side: "server",
  authenticated: false,
});
