import { useContext, useState, useEffect, useCallback } from "react";
import { Runtype } from "runtypes";

import { Site } from "../contexts/SiteContext";
import { Viewer } from "../contexts/ViewerContext";

const safeParse = <T>(val: string | null, runtype?: Runtype<T>): T | null => {
  if (val == null) {
    return null;
  }
  try {
    const parsed = JSON.parse(val);
    return runtype?.check(parsed) ?? (parsed as T);
  } catch {
    return null;
  }
};

export const useLocalStorageState = <T>(
  key: string,
  initialValue: T,
  runtype?: Runtype<T>
) => {
  const site = useContext(Site);
  const viewer = useContext(Viewer);
  const storageKey = `_eq_localstoragestate_${site.uuid}_${viewer?.profile?.uuid}_${key}`;
  const loading = viewer?.loading ?? true;

  // if the viewer context hasn't loaded yet, we can't meaningfully initialize
  // the state
  const [state, setState] = useState(
    !loading
      ? safeParse(localStorage.getItem(storageKey), runtype) ?? initialValue
      : null
  );

  const handleUpdate = useCallback(
    (newValue: T) => {
      if (!loading) {
        localStorage.setItem(storageKey, JSON.stringify(newValue));
      }
      setState(newValue);
    },
    [storageKey]
  );

  // handle syncing to local storage when profile loads
  useEffect(() => {
    if (!loading) {
      handleUpdate(
        state ??
          safeParse(localStorage.getItem(storageKey), runtype) ??
          initialValue
      );
    }
  }, [storageKey]);

  return [{ state: state ?? initialValue, loading }, handleUpdate] as const;
};
