import { useMemo } from "react";
import { Item } from "../../../../contexts/CartInterfaces";
import {
  CartBookableProductQuery,
  CartProductQuery,
  ModType,
} from "../../../../generated/gateway-client";

type Mod = NonNullable<CartProductQuery["product"]>["mods"][number];

export const useCartSubTotalCalculator = (
  cartItem: Item,
  product?: CartBookableProductQuery["product"] | CartProductQuery["product"],
) => {
  const choicesTotal = useMemo(() => cartItem.selected.modChoices.reduce((prev, userChoice) => {
    if (product == null || product.mods == null || product.mods.length === 0) {
      return prev;
    }

    const mod: Mod | undefined = product.mods.find((m) => m.uuid === userChoice.modUuid);
    if (mod == null) {
      return prev;
    }

    if (mod.type === ModType.SingleChoice && userChoice.modChoiceUuids[0] != null) {
      const unitPrice = (mod.modChoices ?? []).find((mc) => mc.choice?.uuid === userChoice.modChoiceUuids[0])?.unitPrice;

      return prev + (unitPrice ?? 0);
    }

    if (mod.type === ModType.MultiChoice) {
      const choicesPrices = userChoice.modChoiceUuids.reduce((value, uuid) => {
        const unitPrice = (mod.modChoices ?? []).find((mc) => mc.choice?.uuid === uuid)?.unitPrice;

        return value + (unitPrice ?? 0);
      }, 0);

      return prev + choicesPrices;
    }

    return prev;
  }, 0), [cartItem, product]);

  const subtotal = useMemo(() => product == null ? 0 : (product.unitPrice + choicesTotal), [product, choicesTotal]);

  return {
    subtotal,
  };
};
