import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  buildingInfoPost: SearchNode<"BuildingInfoEdge">;
}

export const BuildingInfoSearchResult: React.FC<Props> = ({
  buildingInfoPost,
}) => {
  const { t } = useTranslation();

  return (
    <SearchResult
      href="/info/[uuid]"
      as={`/info/${buildingInfoPost.uuid}`}
      title={buildingInfoPost.title}
      body={buildingInfoPost.excerpt}
      imageUrl={buildingInfoPost.featuredImage.url ?? undefined}
      footer={t("main.buildingInfo")}
    />
  );
};
