import { useShowError } from "@equiem/web-ng-lib";
import { useCallback, useMemo } from "react";
import { usePushNotificationHandlers } from "./push-notifications/usePushNotificationHandlers";
import { PushNotificationMessage } from "./usePushNotifications";
import { useShowBrowserNotification } from "./useShowBrowserNotification";

/**
 * Hook which defines a handler for all push notification click / message events.
 */
export const usePushNotificationHandler = ({
  onDelete,
}: {
  onDelete?: (message: PushNotificationMessage) => Promise<void>;
} = {}) => {
  const showError = useShowError();
  const handlers = usePushNotificationHandlers();

  const supportsClick = useCallback((message: PushNotificationMessage) => {
    if (message.data == null) {
      return false;
    }

    return handlers.supportsClick(message);
  }, [handlers]);

  const onClick = useCallback((message: PushNotificationMessage) => {
    handlers.onClick(message).catch(showError);
  }, [handlers]);

  const onClickDismiss = useCallback((message: PushNotificationMessage) => {
    onClick(message);

    if (onDelete != null) {
      onDelete(message).catch(showError);
    }
  }, [onClick, onDelete]);

  const showBrowserNotification = useShowBrowserNotification({ onClick: onClickDismiss });

  const onMessage = useCallback((message: PushNotificationMessage) => {
    showBrowserNotification(message);

    const { data } = message;
    if (data == null) {
      return;
    }

    handlers.onMessage(message).catch(showError);
  }, [
    showError,
    showBrowserNotification,
    handlers,
  ]);

  return useMemo(() => ({
    supportsClick,
    onClick,
    onMessage,
  }), [onClick, supportsClick, onMessage]);
};
