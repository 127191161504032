import { useContext } from "react";
import { ChatContext } from "./ChatContext";
import { useChatSuggestionsQuery } from "../generated/ai-client";
import { Suggestion } from "./Suggestion";
import { useTheme } from "@equiem/react-end-user-ui";

export const ChatWindowSuggestions = () => {
  const { chatStarted } = useContext(ChatContext);
  const { data } = useChatSuggestionsQuery();
  const { spacers } = useTheme();

  const suggestions = data?.aiChatSuggestions ?? [];

  return (
    <>
      {!chatStarted && suggestions.length > 0 && (
        <div className="suggestions">
          {suggestions.map((suggestion) => (
            <Suggestion key={suggestion.uuid} suggestion={suggestion} />
          ))}
        </div>
      )}
      <style jsx>{`
        .suggestions {
          display: flex;
          flex-wrap: nowrap;
          overflow: auto;
          padding: ${spacers.s3} ${spacers.s4};
        }
      `}</style>
    </>
  );
};
