import { Number, Partial, Record, Static, String } from "runtypes";

export const Session = Record({
  expiresAt: Number,
}).And(Partial({
  accessToken: String,
  idToken: String,
}));

export type Session = Static<typeof Session>;
