import { useCallback, useEffect, useState, type FC } from "react";
import { animated, useSpring } from "@react-spring/web";

const timing = 150;

interface Props {
  style?: React.CSSProperties;
  rotation?: number;
  scale?: number;
}

export const useBoop = ({ rotation = 0, scale = 1, style }: Props) => {
  const [isBooped, setIsBooped] = useState(false);
  const springStyle = useSpring({
    display: "inline-block",
    backfaceVisibility: "hidden" as const,
    transform: isBooped
      ? `rotate(${rotation}deg)
       scale(${scale})`
      : `rotate(0deg)
       scale(1)`,
    config: {
      tension: 300,
      friction: 10,
    },
    ...style,
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isBooped) {
      timeout = setTimeout(() => {
        setIsBooped(false);
      }, timing);
    }

    return () => clearTimeout(timeout);
  }, [isBooped]);

  const trigger = useCallback(() => {
    setIsBooped(true);
  }, []);

  return {
    style: springStyle,
    trigger,
  };
};

export const Boop: FC<Props> = ({ children, ...boopConfig }) => {
  const { style, trigger } = useBoop(boopConfig);

  return (
    <animated.span onMouseEnter={trigger} style={style}>
      {children}
    </animated.span>
  );
};
