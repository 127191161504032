import { Session } from "@equiem/web-ng-lib";
import React, { useCallback, useContext, useState } from "react";
import { CookieSettings } from "../lib/CookieSettings";

export interface CookieContext {
  consentRequired: boolean;
  settings: CookieSettings | null;
  hasConsented: boolean;
  update: (settings: CookieSettings | null) => void;
}

export const Cookie = React.createContext<CookieContext>({
  consentRequired: false,
  hasConsented: false,
  settings: null,
  update: (_settings: CookieSettings | null) => undefined,
});

const settingsKey = "cookieSettings";

export const CookieProvider: React.FC<{
  skipConsent?: boolean;
  children?: React.ReactNode;
}> = ({
  skipConsent = false,
  children,
}) => {
  const session = useContext(Session);
  const [settings, setSettings] = useState((() => {
    try {
      return CookieSettings.check(JSON.parse(localStorage.getItem(settingsKey) ?? ""));
    }
    catch (e) {
      return null;
    }
  })());

  const update = useCallback((newSettings: CookieSettings | null) => {
    if (newSettings == null) {
      localStorage.removeItem(settingsKey);
    }
    else {
      localStorage.setItem(settingsKey, JSON.stringify(newSettings));
    }
    setSettings(newSettings);
  }, []);

  return (
    <Cookie.Provider value={{
      consentRequired: session.authenticated && !skipConsent,
      hasConsented: settings != null,
      settings,
      update,
    }}>{children}</Cookie.Provider>
  );
};
