import React from "react";

export interface ThemeContext {
  colors: {
    defaultLink: string;
    bodyColor: string;
    greyBackground: string;
  };
  breakpoints: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
}

export const Theme = React.createContext<ThemeContext>({
  colors: {
    defaultLink: "#007bff",
    bodyColor: "#212529",
    greyBackground: "rgba(0, 0, 0, 0.04)",
  },
  breakpoints: {
    xs: 0,
    sm: 577,
    md: 769,
    lg: 993,
    xl: 1201,
  },
});
