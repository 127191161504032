import { ApolloProvider } from "@apollo/react-hoc";
import { Session } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { ViewerProvider } from "./ViewerProvider";

export const Personalized: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const session = useContext(Session);

  return session.side === "server" || !session.authenticated ? null : (
    <ApolloProvider client={session.authenticatedClient}>
      <ViewerProvider>
        {children}
      </ViewerProvider>
    </ApolloProvider>
  );
};
