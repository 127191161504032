import { useMemo } from "react";
import { PushNotificationMessage } from "../usePushNotifications";
import { useCommentReplyHandler } from "./useCommentReplyHandler";
import { useDeepLinkHandler } from "./useDeepLinkHandler";
import { useOrderAdjustmentHandler } from "./useOrderAdjustmentHandler";
import { useOrderInfoFieldUpdatedHandler } from "./useOrderInfoFieldUpdatedHandler";
import { useOrderRefundLineItemsHandler } from "./useOrderRefundLineItemsHandler";
import { useOrderStatusHandler } from "./useOrderStatusHandler";
import { usePaymentFailureHandler } from "./usePaymentFailureHandler";
import { useProfileUpdatedHandler } from "./useProfileUpdatedHandler";
import { useRecurringSubscriptionHandler } from "./useRecurringSubscriptionHandler";
import { useRsvpReminderHandler } from "./useRsvpReminderHandler";

const withMessageData = (
  handler: (message: PushNotificationMessage) => Promise<void>,
) => async (message: PushNotificationMessage) => (
  message.data == null ? undefined : handler(message)
);

/**
 * Hook which defines onClick/onMessage event handlers for different types of notifications.
 *
 * Clicks may come from the Notification's DOM element, or a browser Notification.
 */
export const usePushNotificationHandlers = () => {
  const commentReply = useCommentReplyHandler();
  const orderStatus = useOrderStatusHandler();
  const profileUpdated = useProfileUpdatedHandler();
  const recurringSubscription = useRecurringSubscriptionHandler();
  const rsvpReminder = useRsvpReminderHandler();
  const orderAdjustment = useOrderAdjustmentHandler();
  const orderInfoFieldUpdated = useOrderInfoFieldUpdatedHandler();
  const orderRefundLineItems = useOrderRefundLineItemsHandler();
  const paymentFailure = usePaymentFailureHandler();
  const deepLink = useDeepLinkHandler();

  return useMemo(() => ({

    supportsClick: (message: PushNotificationMessage) => {
      switch (message.data?.type!) {
        case "rsvpReminder":
        case "commentReply":
        case "orderStatus":
        case "recurringSubscription":
        case "deeplink":
          return true;
        default: return false;
      }
    },

    onClick: withMessageData(async (message) => {
      if (!message.data) {
        return;
      }

      switch (message.data.type) {
        case "commentReply": return commentReply.onClick(message.data) ?? false;
        case "rsvpReminder": return rsvpReminder.onClick(message.data) ?? false;
        case "orderStatus": return orderStatus.onClick(message.data) ?? false;
        case "recurringSubscription": return recurringSubscription.onClick(message.data) ?? false;
        case "deeplink": return  deepLink.onClick(message.data, message.notification?.title, message.notification?.body) ?? false;
      }
    }),

    onMessage: withMessageData(async (message) => {
      if (!message.data) {
        return;
      }

      switch (message.data.type) {
        case "orderAdjustment": return orderAdjustment.onMessage(message.data) ?? false;
        case "orderInfoFieldUpdated": return orderInfoFieldUpdated.onMessage(message.data) ?? false;
        case "orderRefundLineItems": return orderRefundLineItems.onMessage(message.data) ?? false;
        case "orderStatus": return orderStatus.onMessage(message.data);
        case "paymentFailure": return paymentFailure.onMessage(message.data) ?? false;
        case "profileUpdated": return profileUpdated.onMessage(message.data);
        case "recurringSubscription": return recurringSubscription.onMessage(message.data) ?? false;
      }
    }),

  }), [
    commentReply,
    profileUpdated,
    orderStatus,
    recurringSubscription,
    rsvpReminder,
    deepLink,
  ])
};
