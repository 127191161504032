import { useTranslation } from "@equiem/web-ng-lib";
import { useShowError } from "@equiem/web-ng-lib";
import { createContext, useEffect, useMemo, useState } from "react";
import { requestNotificationPermission, supportsNotifications } from "../lib/requestNotificationPermission";

export interface BrowserNotifications {
  supported: boolean;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void,
}

/**
 * Context for representing the state of browser notifications:
 * - are they supported by the browser?
 * - does the user want to receive them (ie. are they enabled)?
 */
export const BrowserNotifications = createContext<BrowserNotifications>({
  supported: false,
  enabled: false,
  setEnabled: (_enabled: boolean) => undefined,
});

export const BrowserNotificationsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();

  const showError = useShowError();
  const supported = useMemo(supportsNotifications, [typeof window]);

  const [enabled, setEnabled] = useState(
    supported &&
    localStorage.getItem("browserNotificationsEnabled") === "1"
    && Notification.permission === "granted",
  );

  useEffect(() => {
    if (enabled) {
      const onError = () => {
        showError(t("main.notAbleToGetPermissionToEnableNotifications"));
        setEnabled(false);
      };

      try {
        requestNotificationPermission().then((status) => {
          if (status === "granted") {
            localStorage.setItem("browserNotificationsEnabled", "1");
          }
          else {
            onError();
          }
        }).catch((e) => {
          console.error(e);
          onError();
        });
      }
      catch(e) {
        console.error(e);
        onError();
      }
    }
    else {
      localStorage.removeItem("browserNotificationsEnabled");
    }
  }, [enabled]);

  const value = {
    enabled,
    supported,
    setEnabled: (e: boolean) => {
      setEnabled(e);
    },
  };

  return (
    <BrowserNotifications.Provider value={value}>
      {children}
    </BrowserNotifications.Provider>
  );
};
