import { Site } from "@equiem/web-ng-lib";
import color from "color";
import React, { forwardRef, useContext } from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";

type Props = ReplaceProps<"input", BsPrefixProps<"input"> & FormCheckProps>

export const Check = forwardRef<HTMLInputElement, Props>(({ className = "", ...props }, ref) => {
  const site = useContext(Site);

  return (
    <div className={`checkbox ${className}`}>
      <Form.Check {...props} ref={ref} />
      <style jsx>{`
      .checkbox :global(.custom-checkbox) {
        padding-left: 2.5rem;
      }
      .checkbox :global(.custom-checkbox label) {
        cursor: pointer;
      }
      .checkbox :global(.custom-checkbox label:before),
      .checkbox :global(.custom-checkbox label:after) {
        border-radius: 0;
        top: 0.2rem;
        left: -2.5rem;
        width: 1.125rem;
        height: 1.125rem;
      }
      .checkbox :global(.custom-checkbox :checked+label:before) {
        background-color: ${site.primaryColour};
        border-color: ${site.primaryColour};
      }
      .checkbox :global(.custom-checkbox :focus+label:before) {
        border-color: ${site.primaryColour};
        box-shadow: 0 0 0 0.15rem ${color(site.primaryColour).fade(0.8)};
      }
    `}</style>
    </div>
  )
});
