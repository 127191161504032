import { useTranslation } from "@equiem/web-ng-lib";
import { Button } from "@equiem/web-ng-lib";
import React, { useContext, useEffect, useMemo } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Item } from "../../contexts/CartInterfaces";
import { Cart } from "../../contexts/CartProvider";
import { ProductVisibility, useCartBookableProductQuery } from "../../generated/gateway-client";
import { PriceFormat } from "../PriceFormat";
import { useCartSubTotalCalculator } from "./checkout/hooks/useCartSubTotalCalculator";
import { ProductBookableItem } from "./products/ProductBookableItem";
import { UserCartItemSkeleton } from "./UserCartItemSkeleton";

interface Props {
  item: Extract<Item, { type: "BOOKING" }>;
  setInvalid: (id: string) => void;
  finishLoading: (id: string) => void;
}

export const UserCartItemBookable: React.FC<Props> = ({ item, setInvalid, finishLoading }) => {
  const { t } = useTranslation();

  const { updateQuantity, updateSubtotal } = useContext(Cart);

  const { data, loading } = useCartBookableProductQuery({
    variables: {
      uuid: item.uuid,
      endDate: item.selected.session.end + 1,
      startDate: item.selected.session.start - 1,
    },
  });

  const product = data?.product;

  const prodNotAvailable = useMemo(() => product == null || product.visibility === ProductVisibility.Hidden, [product]);
  const invalid = product == null || prodNotAvailable || !product.vendor.open;

  const { subtotal } = useCartSubTotalCalculator(item, product);

  useEffect(() => {
    if (!loading) {
      finishLoading(item.id);
    }

    if (loading || product == null) {
      return;
    }

    if (invalid) {
      setInvalid(item.id);
    }

    updateSubtotal(item.id, subtotal);
  }, [loading, product]);

  if (loading) {
    return <Row><Col><UserCartItemSkeleton /></Col></Row>;
  }
  if (product == null || product.__typename !== "BookableProduct") {
    return null;
  }

  return (
    <>
      <Row className={`pb-3 cart-item ${invalid ? "invalid" : ""}`} key={item.id}>
        <Col md="2">
          <Button variant="solid" onClick={() => {
            updateQuantity(item.id, 0);
          }}>{t("main.remove")}</Button>
        </Col>
        <Col>
          <div className="description">
            <div className="font-weight-bold">{product.name}</div>
            <div>
              {product.groupedSessions.flatMap((gs) => {
                if (gs.timestamp < item.selected.session.start) {
                  return [];
                }

                const session = gs.sessions.find((s) =>
                  s.sessionTemplateUuid === item.selected.session.sessionTemplateUuid &&
                  s.startDate === item.selected.session.start &&
                  s.endDate === item.selected.session.end)

                return session == null ? [] : [(<ProductBookableItem showDate key={`${session.sessionTemplateUuid}:${session.startDate}:${session.endDate}`} session={session} />)];
              })}
              {item.selected.session.attendees > 1 ? (
                <div className="pt-2">
                  <span className="font-weight-bold">{t("main.totalAttendees")}:</span> {item.selected.session.attendees}
                </div>
              ) : null}
            </div>
          </div>
          {prodNotAvailable ? (<Alert variant="warning">{t("main.productNoLongerAvailable")}</Alert>) : null}
          {!product.vendor.open ? (<Alert variant="warning">{t("main.vendorCurrentlyClosed")}</Alert>) : null}
        </Col>
        <Col md="2" className="text-right price">
          <PriceFormat price={subtotal * item.quantity} freeTxt="" />
        </Col>
      </Row>
      <style jsx global>{`
        .cart-item.invalid .description, .cart-item.invalid .price {
          text-decoration: line-through;
          opacity: 0.6;
        }
      `}</style>
    </>
  );
}
