import { Session, Site, Viewer } from "@equiem/web-ng-lib";
import { useContext } from "react";

export const useViewerTokens = () => {
  const site = useContext(Site);
  const viewer = useContext(Viewer);
  const session = useContext(Session)

  const fallback = viewer?.loading === true ? "" : "Unknown";
  const profile = viewer?.profile;

  const tokens = {
    "equiem:first_name": profile?.firstName ?? fallback,
    "equiem:last_name": profile?.lastName ?? fallback,
    "equiem:email": profile?.email ?? fallback,
    "equiem:company": profile?.company.name ?? fallback,
    "equiem:sitename": site.name,
    "equiem:siteurl": site.siteUrl,
    "equiem:building_name": site.name,
    "equiem:site_uuid": site.uuid,
    "equiem:source": "web",
    "equiem:id_token": session.idToken ?? "",
  };

  return (text: string) => Object.keys(tokens).reduce((a, token) => a.replace(
    new RegExp(`\\[${token}\\]`, "g"),
    tokens[token as keyof typeof tokens],
  ), text);
};
