import { notNullOrUndefined, Site } from "@equiem/web-ng-lib";
import React, { forwardRef, HTMLAttributes, MouseEventHandler, useContext, useMemo } from "react";

export interface ButtonProps {
  className?: string;
  contrast?: boolean;
  disabled?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  rel?: string;
  rounded?: boolean;
  roundedRadius?: string;
  borderWidth?: string;
  size?: "sm" | "lg";
  style?: HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>["style"];
  tabIndex?: number;
  target?: string;
  type?: "button" | "submit" | "reset";
  variant: "solid" | "outline" | "link";
  onMouseOver?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onMouseOut?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  children?: React.ReactNode;
}

export const Button = forwardRef<any, ButtonProps>(({
  children,
  className: inputClassName = "",
  contrast = false,
  disabled = false,
  href,
  onClick,
  rel,
  rounded = true,
  roundedRadius = "4px",
  borderWidth = "1px",
  size,
  style,
  tabIndex = 0,
  target,
  type,
  variant,
  onMouseOver,
  onMouseOut,
}, ref) => {
  const site = useContext(Site);

  const primaryColor = site.primaryColour;
  const contrastColor = site.primaryContrastColour;
  const whiteContrastColor = site.whiteContrastColour;

  const BtnEl = useMemo(() => (
    href == null ? "button" : "a"
  ), [variant, href]);

  const typeClassName = useMemo(() => `btn-${variant}`, [variant]);

  const className = useMemo(() => `${[
    "btn",
    typeClassName,
    disabled ? "disabled" : null,
    size != null ? `btn-${size}` : null,
  ].filter(notNullOrUndefined).join(" ")} ${inputClassName
    }`,
    [variant, inputClassName, disabled, size],
  );

  const borderRadius = rounded ? roundedRadius : "0";
  const hoverShadow = variant === "link" ? undefined : "0 0 10px rgba(0, 0, 0, 0.3)";

  const styles = useMemo(() => {
    switch (variant) {
      case "solid": return {
        btn: `
          background: ${contrast ? contrastColor : primaryColor};
          color: ${contrast ? primaryColor : contrastColor};
          border-radius: ${borderRadius};
        `,
        hover: `
          box-shadow: ${hoverShadow};
        `,
      };
      case "outline": return {
        btn: `
          color: ${contrast ? contrastColor : whiteContrastColor};
          border-radius: ${borderRadius};
          border: ${borderWidth} solid ${contrast ? contrastColor : primaryColor};
        `,
        hover: `
          box-shadow: ${hoverShadow};
        `,
      };
      case "link": return {
        btn: `
          color: ${contrast ? contrastColor : whiteContrastColor};
          font-weight: 600;
          text-decoration: underline;
        `,
        hover: `
          color: ${contrast ? contrastColor : whiteContrastColor};
        `,
      };
    }
  }, [variant, contrast]);

  const params = {
    onClick,
    type,
    className,
    style,
    tabIndex,
    onMouseOver,
    onMouseOut,
    ...(BtnEl === "a" ? { href, target, rel } : { disabled }),
  };

  return (
    <>
      <BtnEl {...params} ref={ref}>
        <>{children}</>
      </BtnEl>
      <style jsx>{`
        .btn,
        a.btn:active,
        a.btn:visited {
          ${styles.btn}
        }
        .btn:hover:not(:disabled):not(.disabled),
        .btn:focus:not(:disabled):not(.disabled) {
          ${styles.hover}
        }
        .btn.disabled,
        .btn:disabled {
          opacity: .5;
        }
      `}</style>
    </>
  );
});
