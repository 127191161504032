import { RiMoreLine } from "@equiem/react-admin-ui/icons";
import { animated, useSpring } from "@react-spring/web";

export const ChatLoadingContent = () => {
  const style = useSpring({
    from: {
      scale: 1,
    },
    to: [{ scale: 1.2 }, { scale: 0.8 }, { scale: 1 }],
    loop: true,
  });

  return (
    <animated.div style={style}>
      <RiMoreLine />
    </animated.div>
  );
};
