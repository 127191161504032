import { useTheme } from "@equiem/react-end-user-ui";
import { useTranslation } from "@equiem/web-ng-lib";
import {
  Button,
  EscapedHtmlDiv,
  MobileView,
  Site,
  stringNotEmpty,
} from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaEnvelope,
  FaFacebookF,
  FaLink,
  FaLinkedinIn,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import { FeedbackProvider } from "../contexts/FeedbackContext";
import { Personalized } from "../contexts/Personalized";
import { useAuthenticatedViewer } from "../hooks/useAuthenticatedViewer";
import { CookieSettingsDrawer, Ref } from "./CookieSettingsDrawer";
import { FeedbackWidget } from "./FeedbackWidget";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  const site = useContext(Site);
  const { inMobileView } = useContext(MobileView);
  const { fontSize } = useTheme();
  const cookiesRef = useRef<Ref>(null);
  const viewer = useAuthenticatedViewer();

  const socialLinks = site.socialLinks.map((sc) => {
    let icon = <FaFacebookF size="22px" />;
    if (sc.type === "EMAIL") {
      icon = <FaEnvelope size="22px" className="mb-0" />;
    } else if (sc.type === "INSTAGRAM") {
      icon = <AiFillInstagram size="26px" />;
    } else if (sc.type === "LINKEDIN") {
      icon = <FaLinkedinIn size="24px" />;
    } else if (sc.type === "PHONE") {
      icon = <FaPhoneAlt size="22px" />;
    } else if (sc.type === "TWITTER") {
      icon = <FaTwitter size="24px" />;
    } else if (sc.type === "OTHER") {
      icon = <FaLink size="21px" />;
    }

    return {
      ...sc,
      icon,
    };
  });

  return inMobileView ? null : (
    <div className="footer-container bg-site py-5 text-center text-md-left">
      <Container>
        <Row>
          <Col md="5">
            {stringNotEmpty(site.footerLogo) ? (
              <div className="pb-4">
                <img src={site.footerLogo} />
              </div>
            ) : null}
            <div className="pb-4 mx-auto mx-md-0">
              <Link href="/legal" passHref>
                <a>{t("main.terms")}</a>
              </Link>
              <Link href="/privacy" passHref>
                <a className="mx-3 mx-md-4 mx-lg-5">{t("main.privacy")}</a>
              </Link>
              {viewer?.profile ? (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    cookiesRef.current?.open();
                  }}
                >
                  {t("main.cookieSettings")}
                </a>
              ) : null}
            </div>
          </Col>
          <Col md="4">
            <EscapedHtmlDiv
              className="pb-4 site-address"
              html={site.address.replace(/,\s/g, "<br/>")}
            />

            {socialLinks.length > 0 ? (
              <div className="pb-4 px-4 px-md-0">
                {socialLinks.map((sc, i) => (
                  <a
                    key={`${sc.type}-${i}`}
                    href={sc.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={[
                      "d-inline-block",
                      "mb-3",
                      "mx-3",
                      "mb-md-4",
                      "mr-md-4",
                      "ml-md-0",
                      "pr-lg-4",
                      "pl-lg-0",
                      "pr-xl-2",
                      `social-${sc.type.toLowerCase()}`,
                    ].join(" ")}
                  >
                    {sc.icon}
                  </a>
                ))}
              </div>
            ) : null}
          </Col>
          <Col md="3" className="pt-2 pt-md-0">
            {stringNotEmpty(site.iosAppId) ? (
              <div className="pb-3">
                <Button
                  href={`https://apps.apple.com/app/id${site.iosAppId}`}
                  variant="outline"
                  contrast
                  className="text-nowrap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("main.getIosApp")}
                </Button>
              </div>
            ) : null}
            {stringNotEmpty(site.androidAppId) ? (
              <Button
                href={`https://play.google.com/store/apps/details?id=${site.androidAppId}`}
                variant="outline"
                contrast
                className="text-nowrap"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("main.getAndroidApp")}
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>

      <CookieSettingsDrawer ref={cookiesRef} />
      {viewer?.profile ? (
        <Personalized>
          <FeedbackProvider>
            <FeedbackWidget />
          </FeedbackProvider>
        </Personalized>
      ) : null}

      <style jsx>
        {`
          .footer-container {
            font-size: ${fontSize};
            color: ${site.primaryContrastColour};
            min-height: 250px;
          }
          .footer-container a {
            color: ${site.primaryContrastColour};
          }
          .footer-container img {
            max-width: 150px;
          }
        `}
      </style>
    </div>
  );
};
