import { useTranslation } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import React, { useContext, useEffect } from "react";
import { useAlert } from "react-alert";

export const ReportingCheck: React.FC = () => {
  const { t } = useTranslation();

  const site = useContext(Site);
  const alert = useAlert();

  useEffect(() => {
    if (!site.reportingEnabled) {
      alert.error(t("main.reportingCurrentlyDisabled"));
    }
  }, []);

  return null;
}
