import { ChatWindowHeader } from "./ChatWindowHeader";
import { ChatWindowInput } from "./ChatWindowInput";
import { ChatWindowBody } from "./ChatWindowBody";
import { useTheme } from "@equiem/react-end-user-ui";
import type { FC } from "react";

interface Props {
  fullPage: boolean;
}

export const ChatWindow: FC<Props> = ({ fullPage }) => {
  const { colors } = useTheme();

  return (
    <>
      <div>
        {!fullPage && <ChatWindowHeader />}
        <ChatWindowBody />
        <ChatWindowInput />
      </div>
      <style jsx>{`
        div {
          background: ${colors.white};
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      `}</style>
    </>
  );
};
