import { useTranslation } from "@equiem/web-ng-lib";
import React, { useCallback, useContext } from "react";
import { SideMenuContext } from "../../contexts/SideMenuProvider";
import { OpenCloseBtn } from "./OpenCloseBtn";

interface Props {
  className?: string;
}

export const Burger: React.FC<Props> = ({ className = "" }) => {
  const { t } = useTranslation();

  const sideMenu = useContext(SideMenuContext);
  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === "Enter") {
      sideMenu.toggle();
    }
  }, [sideMenu]);
  const onClick = useCallback(() => {
    sideMenu.toggle();
  }, [sideMenu]);

  return (
    <a
      tabIndex={0}
      onKeyDown={onKeyDown}
      onClick={onClick}
      className={`${sideMenu.isShown ? "open" : ""} ${className}`}>
      <div className="burger">
        <OpenCloseBtn open={sideMenu.isShown} openTxt={t("main.menu")} />
      </div>
      <style jsx>{`
        a:hover {
          text-decoration: none;
        }
        .burger {
          position: relative;
          z-index: ${sideMenu.zIndex.sidemenu + 1};
        }
      `}</style>
    </a>
  );
}
