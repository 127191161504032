import { useTranslation } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { FeatureModuleType } from "../generated/gateway-client";
import { GeneralLayout } from "./layout/GeneralLayout";

interface Props {
  featureModuleCheck?: FeatureModuleType
}

export const useFeatureEnabled = (type?: FeatureModuleType) => {
  const site = useContext(Site);
  let featureEnabled: boolean;

  switch (type) {
    case FeatureModuleType.Bookings:
      featureEnabled = site.featureModules.bookings.enabled
      break;

    case FeatureModuleType.Visitors:
      featureEnabled = site.featureModules.visitors.enabled
      break;

    default:
      // Cases where there's no feature module check specified.
      featureEnabled = true
      break;
  }

  return {
    featureEnabled,
  };
};

export const FeatureModuleCheck: React.FC<Props> = ({ children, featureModuleCheck }) => {
  const { t } = useTranslation();

  const { featureEnabled } = useFeatureEnabled(featureModuleCheck)

  if (featureModuleCheck == null) {
    return <>{children}</>
  }

  return (
    featureEnabled ? <>{children}</> :
      <GeneralLayout background="white" personalized>
        <div className="d-flex justify-content-center vh-50 align-items-center py-3 access-denied">
          <h1>{t("main.featureNotAvailableOnThisSite")}</h1>
        </div>
        <style jsx>{`
          .access-denied {
            min-height: calc(100vh - 320px);
          }
        `}</style>
      </GeneralLayout>
  )
}
