import { useTranslation } from "@equiem/web-ng-lib";
import { Button } from "@equiem/web-ng-lib";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { useFeedbackSubmission } from "../../hooks/useFeedbackSubmission";

export interface FeedbackActionsProps {
  onDismiss: () => void;
}

export const FeedbackActions: React.FC<FeedbackActionsProps> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const context = useFeedbackContext();
  const submission = useFeedbackSubmission();

  if (context.submitted) {
    return null;
  }

  return (
    <>
      <hr className="mt-2 mb-3 ml-1 mr-1" />
      <div className=" button-container m-auto">
        <div className="d-flex flex-column justify-content-center">
          <Button type="button" className="btn-later" variant="outline" onClick={async () => {
            context.dismiss();
            await submission.dismissFeedback();
            if (onDismiss != null) {
              onDismiss();
            }
          }}>{t("main.maybeLater")}</Button>
          <Button type="button" variant="outline" onClick={async () => {
            context.dismissForever();
            await submission.submitFeedback(1, true);
          }}>{t("common.dismiss")}</Button>
        </div>
      </div>
      <style jsx global>
        {`
          .btn-later {
            margin-bottom: .5rem;
          }
        `}
      </style>
    </>
  )
};
