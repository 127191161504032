import { useEffect } from "react"
import { FunctionReturnType } from "../components/feedback/FeedbackTypes";

export const useTimeout = (fn: FunctionReturnType, seconds: number): void => {
  const miliseconds = seconds * 1000;
  useEffect(() => {
    const timer = setTimeout(fn, miliseconds);

    // tslint:disable-next-line: no-void-expression
    return () => clearTimeout(timer);
  }, []);
};
