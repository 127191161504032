import { PushNotificationPayloads } from "@equiem/user-pubsub";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useCommentReplyHandler = () => {
  const router = useRouter();

  return useMemo(() => ({
    onClick: async (data: Partial<PushNotificationPayloads.Data.CommentReplyData>) => {
      const type = data.contentType === "NEWS_POST" ? "news" : (
        data.contentType === "EVENT_POST" ? "event" : null
      );

      await router.push(`/${type}/${data.contentUuid}?comment=${data.reply}`);
    },
  }), [router]);
};
