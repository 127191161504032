export {
  languages,
  formatters,
  useTranslation,
  withTranslation,
  type WithTranslationProps,
  useServerMessageTranslation,
  useApolloErrorTranslation,
  useErrorTranslation,
  Trans,
  type TFunction,
  getCustomStandaloneTestT,
} from "../generated/localisation";

export const defaultNamespace = "web-ng";

export const languageOptionList = [
  { value: "en-US", label: "English US" },
  { value: "en-AU", label: "English AU" },
  { value: "en-CA", label: "English CA" },
  { value: "en-GB", label: "English UK" },
  { value: "de", label: "Deutsch" },
  { value: "es", label: "Español" },
  { value: "fr", label: "Français" },
  { value: "pl", label: "Polski" },
  { value: "hu", label: "Magyar" },
  { value: "nl", label: "Nederlands" },
  { value: "sk", label: "Slovenčina" },
  { value: "cs", label: "Čeština" },
  { value: "pt-PT", label: "Português" },
];

export const debugLanguageOptionList = [
  { value: "ja", label: "日本語" },
  { value: "en", label: "English (Base)" },
  { value: "en-US-debug", label: "English (Debug)" },
  { value: "en-US-debug-longer", label: "English (Debug +25%)" },
];
