import { Theme } from "@equiem/react-end-user-ui";
import { AlertTemplate, AuthenticationContext } from "@equiem/web-ng-lib";
import { AppProps } from "next/app";
import React, { useState } from "react";
import {
  AlertProviderProps,
  positions,
  Provider as AlertProvider,
  transitions,
} from "react-alert";

// tslint:disable: ordered-imports

import "../lib/polyfills";
import "swiper/css/bundle";
import "../styles/styles.scss";

// tslint:enable: ordered-imports

import { CortexPageViewMetric } from "../components/cortex/CortexPageViewMetric";
import { EquiemOembedListener } from "../components/EquiemOembedListener";
import { FeatureModuleCheck } from "../components/FeatureModuleCheck";
import { LegalCheck } from "../components/LegalCheck";
import { ReportingCheck } from "../components/ReportingCheck";
import { SiteNotFound } from "../components/SiteNotFound";
import { UserCart } from "../components/store/UserCart";
import { UserCartButton } from "../components/store/UserCartButton";
import { ApolloLocaleProvider } from "../contexts/ApolloLocaleProvider";
import { BrowserNotificationsProvider } from "../contexts/BrowserNotifications";
import { CartProvider } from "../contexts/CartProvider";
import { CookieProvider } from "../contexts/CookieContext";
import { LocaleProvider } from "../contexts/LocaleProvider";
import { MainSubmenuProvider } from "../contexts/MainSubmenuProvider";
import { Personalized } from "../contexts/Personalized";
import { PubsubProvider } from "../contexts/Pubsub";
import { SearchProvider } from "../contexts/SearchContext";
import { SessionProvider } from "../contexts/SessionProvider";
import { SideMenuProvider } from "../contexts/SideMenuProvider";
import { SiteProviders } from "../contexts/SiteProviders";
import { Tawk } from "../contexts/Tawk";
import type { SiteProps } from "../contexts/withAppContexts";
import { ChatBot } from "@equiem/ai";

const alertOptions: AlertProviderProps & { className: string } = {
  position: positions.BOTTOM_CENTER,
  timeout: 0,
  transition: transitions.SCALE,
  template: AlertTemplate,
  containerStyle: {
    pointerEvents: "all",
  },
  className: "eq-alert",
};

// Client-side cache of the last site passed as initial props.
let site: SiteProps["siteInput"];

const PageWithSiteContext: React.FC<SiteProps> = ({
  authenticated,
  siteInput,
  errors,
  isInMobileView,
  args,
  children,
}) => {
  site = siteInput != null ? siteInput : site;

  if (site == null || site.gatewayEndpoint == null) {
    return <SiteNotFound errors={errors ?? ["Unknown error"]} />;
  }

  const [authState, setAuthState] = useState<AuthenticationContext>({
    authenticated,
  });

  return (
    <Theme
      primaryColor={site.primaryColour}
      zIndexes={{ modal: 2000000002, toast: 2000000003 }}
    >
      <AlertProvider {...alertOptions}>
        <SessionProvider
          gatewayEndpoint={site.gatewayEndpoint}
          authState={authState}
          setAuthState={setAuthState}
          isInMobileView={isInMobileView}
        >
          <SiteProviders site={site}>
            <CookieProvider
              skipConsent={args.skipCookieConsent === true || isInMobileView}
            >
              <LocaleProvider>
                <ApolloLocaleProvider>
                  <SideMenuProvider>
                    <SearchProvider>
                      <EquiemOembedListener>
                        <MainSubmenuProvider>
                          <CartProvider>
                            <PubsubProvider>
                              <BrowserNotificationsProvider>
                                <ReportingCheck />
                                <FeatureModuleCheck
                                  featureModuleCheck={args.featureModuleCheck}
                                >
                                  {children}
                                  {args.skipLegalCheck === true ? null : (
                                    <Personalized>
                                      <LegalCheck />
                                      <UserCart />
                                      <UserCartButton />
                                      {args.displayAiChat === true ? (
                                        <ChatBot primaryColor={site.primaryColour} />
                                      ) : null}
                                    </Personalized>
                                  )}
                                  {args.skipMetrics ? null : (
                                    <CortexPageViewMetric
                                      pageTitle={args.pageTitle}
                                      pageViewType={args.pageViewType}
                                    />
                                  )}
                                </FeatureModuleCheck>
                              </BrowserNotificationsProvider>
                            </PubsubProvider>
                          </CartProvider>
                        </MainSubmenuProvider>
                      </EquiemOembedListener>
                    </SearchProvider>
                  </SideMenuProvider>
                  {args.displayChatButton === false || isInMobileView ? null : (
                    <Tawk />
                  )}
                </ApolloLocaleProvider>
              </LocaleProvider>
            </CookieProvider>
          </SiteProviders>
        </SessionProvider>
      </AlertProvider>
    </Theme>
  );
};

interface Props extends AppProps {
  pageProps: AppProps["pageProps"] & { siteProps?: SiteProps };
}

const App = ({ Component, pageProps }: Props) => {
  const { siteProps, ...props } = pageProps;

  const Wrapper = siteProps != null ? PageWithSiteContext : React.Fragment;
  const wrapperProps = siteProps != null ? siteProps : {};

  return (
    <Wrapper {...(wrapperProps as any)}>
      <Component {...props} />
    </Wrapper>
  );
};

export default App;
