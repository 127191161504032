import { notNullOrUndefined } from "@equiem/web-ng-lib";
import { useContext, useMemo, useState } from "react";
import { SearchContext } from "../contexts/SearchContext";
import { useSearchQuery as useQuery } from "../generated/gateway-client";

export const useSearchQuery = () => {
  const search = useContext(SearchContext);
  const [fetchingMore, setFetchingMore] = useState(false);
  const skip = search.term.length < 1;
  const pageSize = 10;

  const { loading, data, fetchMore } = useQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip,
    notifyOnNetworkStatusChange: true,
    variables: {
      term: search.term,
      first: pageSize,
      contentTypes: search.types,
      sort: search.sort,
    },
  });

  const nodes = data?.contentFeed?.edges.map((e) => {
    switch (e?.__typename) {
      case "DealContentEdge":
      case "BookableProductContentEdge":
      case "SubscriptionProductContentEdge":
      case "PlainProductContentEdge":
      case "DealVendorContentEdge":
      case "VendorContentEdge":
      case "NewsEdge":
      case "EventOccurrenceEdge":
      case "BuildingInfoEdge": return e.node;
      default: return null;
    }
  }).filter(notNullOrUndefined) ?? [];

  return {
    loading,
    skipped: skip,
    data,
    nodes,
    fetchMore: useMemo(() => async () => {
      const pageInfo = data?.contentFeed.pageInfo;
      if (pageInfo?.hasNextPage !== true || pageInfo.endCursor == null) {
        return;
      }

      setFetchingMore(true);

      await fetchMore({
        variables: { after:pageInfo.endCursor },
      });

      setFetchingMore(false);
    }, [data?.contentFeed.pageInfo]),
    fetchingMore,
    pageNum: Math.ceil(nodes.length / pageSize),
    hasMore: data?.contentFeed.pageInfo.hasNextPage ?? false,
    term: search.term,
  };
};
