import React from "react";
import { CardFragmentFragment, ProfileFragmentFragment } from "../generated/gateway-client";

export interface ViewerContext {
  loading: boolean;
  termsAccepted: boolean | null;
  permissions: {
    readComments: boolean;
    writeComments: boolean;
    canSubscribeToEmails: boolean;
    canSubscribeToNotifications: boolean;
  };
  optInNewsletter: boolean | null;
  uuid: string;
  stripePk: string | null;
  refetch: () => Promise<any>;
  profile?: {
    uuid: string;
    updated: number;
    avatar?: string;
    email: string;
    emailVerified: boolean;
    subscribedToEmails: boolean;
    subscribedToNotifications: boolean;
    isAppointmentOrganizer: boolean;
    name: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    referenceId: string;
    company: {
      uuid: string;
      name: string;
    };
    paymentGatewayCustomerId: string;
    cards: CardFragmentFragment[];
    siteProfile: ProfileFragmentFragment["siteProfiles"]["edges"][number]["node"];
  };
}

export const Viewer = React.createContext<ViewerContext | undefined>(undefined);
