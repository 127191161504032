import { useTranslation } from "@equiem/web-ng-lib";
import { Button, Theme } from "@equiem/web-ng-lib";
import React, { useContext, useEffect, useState } from "react";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { useFeedbackSubmission } from "../../hooks/useFeedbackSubmission";
import { FeedbackComment } from "./FeedbackComment";
import { Score } from "./FeedbackTypes";

export const Nps: React.FC<{}> = () => {
  const { t } = useTranslation();
  const initScores: Score[] = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((score) => ({
    score,
    selected: false,
  }));

  const feedbackContext = useFeedbackContext();
  const submission = useFeedbackSubmission();
  const { breakpoints } = useContext(Theme);

  const [scores, setScores] = useState<Score[]>(initScores);
  const selectScore = (selected: Score) => {
    setScores(() => scores.map((s) => ({ ...s, selected: (s.score <= selected.score)})));
  };

  const resetScore = () => {
    setScores(() => scores.map((s) => ({ ...s, selected: false })));
  };

  const submitScore = async (selected: Score) => {
    if (!feedbackContext.submitted) {
      feedbackContext.submitFeedback(selected);
      selectScore(selected);
      await submission.submitFeedback(selected.score);
    }
  };

  const [mouseOutTimeout, setMouseOutTimeout] = useState<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => () => {
    if (mouseOutTimeout != null) {
      clearTimeout(mouseOutTimeout);
    }
  }, [mouseOutTimeout]);

  return <>
    <div className="nps d-flex justify-content-center align-items-center">
      {
        scores.map((score, k) =>
          <Button
          key={k}
          className={ feedbackContext.submitted ? "nps-box rating-submitted" : "nps-box" }
          type="button"
          variant={score.selected ? "solid" : "outline"}
          size="sm"
          onClick={async() => {
            await submitScore(score);
          }}
          onMouseOver={() => {
            if (!feedbackContext.submitted) {
              if (mouseOutTimeout != null) {
                clearTimeout(mouseOutTimeout);
                setMouseOutTimeout(undefined)
              }
              selectScore(score);
            }
          }}
          onMouseOut={() => {
            if (!feedbackContext.submitted) {
              setMouseOutTimeout(setTimeout(() => {
                resetScore();
              }, 100));
            }
          }}
          >
            {score.score}
          </Button>)
      }
    </div>

    <div className="d-flex justify-content-between text-muted">
      <p className="unsatisfied">{feedbackContext.question?.unsatisfied_text_label ?? t("main.notAtAllLikely")}</p>
      <p className="satisfied">{feedbackContext.question?.satisfied_text_label ?? t("main.extremelyLikely")}</p>
    </div>

    { feedbackContext.submitted ?
      <div className="d-flex flex-column justify-content-center align-items-center rounded p-2 mt-2 nps-comment-container">
        <FeedbackComment />
      </div>
      : null
    }

    <style jsx global>{`
      .nps {
        cursor: pointer;
      }
      .nps-box {
        padding: 5px 8px 4px 8px;
        border: 1px solid ${feedbackContext.question?.accentColour ?? "#cccccc"} !important;
        margin-right: 5px;
        color: ${feedbackContext.question?.accentColour ?? "#cccccc"} !important;
      }
      .rating-submitted {
        cursor: inherit !important;
      }

      .nps-box.btn-solid {
        border-color: ${feedbackContext.question?.accentColour ?? "#cccccc"} !important;
        color: #ffffff !important;
        background: ${feedbackContext.question?.accentColour ?? "#cccccc"} !important;
      }
      .rating-submitted:hover,
      .rating-submitted:focus {
        box-shadow: none !important;
      }
      .unsatisfied {
        width: 51px;
        font-size: 11px;
        margin-top: 7px;
        margin-left: 30px;
        margin-bottom: 0;
      }
      .satisfied {
        width: 51px;
        margin-top: 7px;
        font-size: 11px;
        margin-right: 38px;
        margin-bottom: 0;
      }
      @media (max-width: ${breakpoints.md - 1}px) {
        .unsatisfied {
          margin-left: -15px;
        }
        .satisfied {
          margin-right: -10px;
        }
      }
    `}
    </style>
  </>
};
