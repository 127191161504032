import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { PriceFormat } from "../../PriceFormat";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  product: SearchNode<"PlainProductContentEdge">;
}

export const PlainProductSearchResult: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  const description = (
    <>
      <div>{product.vendor.name}</div>
      <div>{product.excerpt}</div>
    </>
  );

  return (
    <SearchResult
      href="/store/product/[uuid]"
      as={`/store/product/${product.uuid}`}
      title={product.name}
      body={description}
      imageUrl={product.image ?? undefined}
      footer={
        <PriceFormat price={product.unitPrice} freeTxt={t("main.free")} />
      }
    />
  );
};
