import { useTheme } from "@equiem/react-end-user-ui";
import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";

interface P {
  className?: string;
}
export const VendorExternalLabel: React.FC<P> = ({ className }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <>
      <div className={className}>
        <span className="label-container">
          <span className="label-text">{t("main.ordersViaThirdParty")}</span>{" "}
          <span>
            <BsBoxArrowUpRight className="ml-1" />
          </span>
        </span>
      </div>
      <style jsx>{`
        .label-container {
          display: flex;
          justify-content: space-between;
          background: ${theme.colors.primary};
          color: ${theme.colors.primaryContrast};
          font-size: 12px;
          font-weight: 500;
          border-radius: ${theme.borderRadius};
          padding: ${theme.spacers.s1} ${theme.spacers.s3};
          gap: ${theme.spacers.s3};
        }
        .label-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};
