import { useTheme } from "@equiem/react-end-user-ui";
import { RiRobot3Line } from "@equiem/react-admin-ui/icons";
import type { FC } from "react";

interface Props {
  invert?: boolean;
}

export const BotAvatar: FC<Props> = ({ invert = false }) => {
  const { colors } = useTheme();

  return (
    <>
      <span className={"botAvatar"}>
        <RiRobot3Line size={20} />
      </span>
      <style jsx>{`
        .botAvatar {
          font-weight: 500;
          letter-spacing: 0.04em;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          background-color: ${invert ? colors.primaryContrast : colors.primary};
          color: ${invert ? colors.primary : colors.primaryContrast};
          position: relative;
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 12px;
        }
      `}</style>
    </>
  );
};
