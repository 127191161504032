import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { OperationDefinitionNode } from "graphql";
import { AlertContainer } from "react-alert";
import { getTokensFromPostMessage as postMessageTokens } from "./getAccessTokenFromPostMessage";
import { applicationId as webApplicationId } from "../config/applicationId";
import { MobileViewContext } from "../contexts";
import { AuthenticationContext } from "../contexts/SessionContext";
import fragments from "../generated/gateway-client-fragments.json";
import { typePolicies } from "./apollo/typePolicies";
import { errorPolicies as defaultOptions } from "./errorPolicies";
import { noticeError } from "./noticeError";
import { FetchError } from "./FetchError";
import { Session } from "./Session";
import omit from "lodash.omit";
import { stringNotEmpty } from "./stringNotEmpty";


interface SessionState {
  authenticated: boolean;
  authenticationError?: string;
  expiresAt: number;
  accessToken?: string;
  idToken?: string;
}

type AuthSetter = (ctx: AuthenticationContext) => void;
type MobileViewSetter = (ctx: MobileViewContext) => void;

let anonymousGateway: ApolloClient<any> | undefined;
let authenticatedGateway: ApolloClient<any> | undefined;

let inflightAuthentication: Promise<SessionState> | undefined;
let refreshTimeout: NodeJS.Timeout;

let session: SessionState = {
  authenticated: false,
  expiresAt: new Date().getTime(),
};
let mobileView: MobileViewContext = {
  inMobileView: false,
}

let authSetter: AuthSetter = (_ctx) => undefined;
let mobileViewSetter: MobileViewSetter = (_ctx) => undefined;

const clearRefreshTimeout = () => {
  if (refreshTimeout != null) {
    clearTimeout(refreshTimeout);
  }
};

const getTokensFromPostMessage = async (): Promise<SessionState> => {
  try {
    const tokens = await postMessageTokens();
    mobileView = {
      inMobileView: true,
      appId: tokens.appId
    }
    mobileViewSetter({
      appId: tokens.appId,
      inMobileView: true,
    })
    return {
      authenticated: true,
      accessToken: tokens.accessToken,
      expiresAt: tokens.expiresAt,
      idToken: tokens.idToken
    };
  }
  catch(e: unknown) {
    mobileView = {
      inMobileView: false,
    }
    mobileViewSetter({
      inMobileView: false,
    })
    return {
      authenticated: false,
      authenticationError: "Authentication from post message failed.",
      expiresAt: Date.now(),
    }
  }
}

const getTokensFromAuth0 = async (force: boolean) => {
  // @TODO: Share this refresh login between getTokensFromAuth0 and getTokensFromPostMessage
  // functions.
  if (force || session.accessToken == null || session.expiresAt < new Date().getTime()) {
    try {
      const res = await fetch(`/api/token${force ? "?refresh" : ""}`, {
        method: "post",
        credentials: "same-origin",
      });

      if (!res.ok) {
        const errorBody = await res.text().catch(e => `Failed to get response: ${e}`)
        console.error(`Token refresh failed (${res.status}):`, errorBody);
        // For HTTP/2 responses, `res.statusText` will be empty-string
        throw new FetchError(res.statusText || `${res.status}`, res.status);
      }

      const resSession = Session.check(await res.json());

      if (resSession.accessToken != null) {
        const expiresAt = resSession.expiresAt * 1000;
        /**
         * Refresh between 5 and 1 minute prior to expiry, no more than 1 hour in the future.
         * Randomised to avoid multiple tabs interfering with each other.
         */
        const randomLeeway = Math.round((Math.random() * 60 * 4 * 1000) + 60 * 1000);
        const refreshIn = Math.min(expiresAt - Date.now() - randomLeeway, 3_600_000);

        clearRefreshTimeout();
        refreshTimeout = setTimeout(() => {
          console.log("Refreshing token");
          authenticate(true, false).catch((e) => {
            noticeError(e);
            console.error(e);
          });
        }, refreshIn);

        console.log(`Will refresh token at ${Date.now() + refreshIn}`);

        return { authenticated: true, accessToken: resSession.accessToken, expiresAt, idToken: resSession.idToken };
      }
      else {
        return {
          authenticated: false,
          authenticationError: "Session expired.",
          expiresAt: Date.now(),
        };
      }
    }
    catch (e) {
      console.error(e);
      noticeError(e);

      return {
        authenticated: false,
        authenticationError:
          e instanceof Error && stringNotEmpty(e.message)
            ? e.message
            : "Unknown error",
        expiresAt: Date.now(),
      };
    }
  }
  else {
    return session;
  }
}

const authenticate = async (force = false, isInMobileView: boolean) => {
  if (inflightAuthentication != null) {
    return inflightAuthentication;
  }

  return inflightAuthentication = inflightAuthentication = (async () => {
    if (isInMobileView) {
      return getTokensFromPostMessage();
    }

    return getTokensFromAuth0(force);
  })().then((result) => {
    inflightAuthentication = undefined;
    session = result;
    authSetter({
      authenticated: result.authenticated,
      authenticationError: result.authenticationError,
      idToken: result.idToken,
      accessToken: result.accessToken,
    });

    return result;
  });
};

const with401Retry = (
  origFetch: typeof fetch,
  isInMobileView: boolean,
) =>
  async (...args: Parameters<typeof fetch>) => {
    const res = await origFetch(...args);

    if (res.status === 401) {
      // The user's JWT has probably expired. Attempt to reauthenticate.
      const authRes = await authenticate(false, isInMobileView);

      if (authRes.authenticated && authRes.accessToken != null) {
        return origFetch(args[0], {
          ...args[1],
          headers: { ...withAppIdHeader(args[1]?.headers), authorization: `Bearer ${authRes.accessToken}` },
        });
      }
    }

    return res;
  }

const withAuth = (
  origFetch: typeof fetch,
  isInMobileView: boolean,
) => {
  const wrappedFetch: typeof fetch = async (...args: Parameters<typeof fetch>) => {
    const authRes = await authenticate(false, isInMobileView);

    if (authRes == null || authRes.accessToken == null) {
      throw new Error(`Failed to authenticate: ${authRes.authenticationError ?? "Unknown error"}`);
    }

    return origFetch(args[0], {
      ...args[1],
      headers: { ...withAppIdHeader(args[1]?.headers), authorization: `Bearer ${authRes.accessToken}` },
    });
  }

  return with401Retry(wrappedFetch, isInMobileView);
};

const withAppIdHeader = (headers?: HeadersInit): HeadersInit | undefined => {
  if (!mobileView.inMobileView || mobileView.appId == null) {
    return headers
  }

  // Multiple references to the same header with different values will get comma concatenated in the
  // request. The previous headers must be stripped before injecting the new application id.
  return {
    ...omit(headers, "x-equiem-application"),
    "X-Equiem-Application": mobileView.appId
  } as HeadersInit
}

export const dataIdFromObject = (obj: { uuid?: string; __typename?: string }) => (
  obj.__typename != null && obj.uuid != null ? `${obj.__typename}:${obj.uuid}` : undefined
);

let alert: AlertContainer | undefined;
let lastErrorMessage: string | undefined;

const contentFeedOperationNames = [
  "newsContentFeed",
  "promotedHomeDiscounts",
  "upcomingEventsContentFeed",
  "forMeContentFeed",
  "featuredContentFeed",
  "trendingContent",
  "curatedFeed"
];

const showError = (message: string, operationName: string) => {
  const errorMessage = contentFeedOperationNames.includes(operationName) ? "Problem loading content, please refresh." : message;

  if (alert != null && lastErrorMessage !== errorMessage) {
    alert.error(lastErrorMessage = errorMessage, {
      onClose: () => {
        lastErrorMessage = undefined;
      },
    });
  }
};

const errorLink = onError((res) => {
  console.error(res);

  res.graphQLErrors = res.graphQLErrors?.filter((e) => (
    e.message.indexOf("Cannot return null for non-nullable field") === -1
  ));

  for (let e of res.graphQLErrors ?? []) {
    e.message = e.message.includes("503: Service Temporarily Unavailable") ? "We're currently experiencing degraded performance issues with some of our services" : e.message;
  }

  const operation = res.operation.query.definitions.find(
    (def): def is OperationDefinitionNode => def.kind === "OperationDefinition",
  );

  if (operation?.operation !== "query" || res.operation.getContext().ignoreErrors === true) {
    return;
  }

  if (res.networkError != null) {
    showError(res.networkError.message, res.operation.operationName);
  }

  if (res.graphQLErrors != null && res.graphQLErrors.length > 0) {
    showError(res.graphQLErrors[0].message, res.operation.operationName);
  }
});

// Default application id.
const headers = {
  "X-Equiem-Application": webApplicationId,
}

export const getAnonymousGateway = (
  endpoint: string,
  alertManager: AlertContainer,
) => {
  alert = alertManager;

  return anonymousGateway ?? (
    anonymousGateway = new ApolloClient({
      cache: new InMemoryCache({
        dataIdFromObject,
        possibleTypes: fragments.possibleTypes,
        typePolicies,
      }),
      link: errorLink.concat(new HttpLink({
        uri: endpoint,
        fetch,
        headers,
      })),
      defaultOptions,
      name: "web-ng",
    })
  );
}

let cachedLocale: string;

export const getAuthenticatedGateway = (
  endpoint: string,
  setAuth: AuthSetter,
  alertManager: AlertContainer,
  isInMobileView: boolean,
  setMobileView: MobileViewSetter,
  locale: string,
) => {
  authSetter = setAuth;
  mobileViewSetter = setMobileView;
  alert = alertManager;

  // Cache locale to avoid creating a new client when it hasn't changed.
  const currentCachedValue = cachedLocale;
  cachedLocale = locale;

  return {
    authenticate,
    client: authenticatedGateway && locale === currentCachedValue ? authenticatedGateway : (
      authenticatedGateway = new ApolloClient({
        cache: new InMemoryCache({
          dataIdFromObject,
          possibleTypes: fragments.possibleTypes,
          typePolicies,
        }),
        link: errorLink.concat(new HttpLink({
          uri: endpoint,
          fetch: withAuth(fetch, isInMobileView),
          headers: {
            ...headers,
            "X-Equiem-Client-Locale": locale,
          },
        })),
        defaultOptions,
        name: "web-ng",
      })
    ),
  };
}

export const regionalGateway = (uri: string) => new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({ uri, fetch }),
  name: "web-ng",
  defaultOptions,
});
