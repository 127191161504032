import moment from "moment";
import React, { createContext, useContext, useReducer } from "react";
import { Score } from "../components/feedback/FeedbackTypes";
import { FeedbackQuestion, FeedbackResponse, FeedbackVisibility } from "../components/feedback/FeedbackTypes";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { FeedbackReducers } from "./FeedbackReduces";

export interface FeedbackState {
  readonly submitted: boolean;
  readonly visibility: FeedbackVisibility;
  readonly score: Score;
  readonly dismissed: boolean;
  readonly response: FeedbackResponse;
  readonly comment?: string | null;
  readonly question?: FeedbackQuestion;
}

export type FeedbackContext = {
  dismiss: () => void;
  dismissForever: () => void;
  submitFeedback: (score: Score, comment?: string) => void;
  submitResponse: (uuid: string) => void;
  toggleVisibility: (visibility: FeedbackVisibility) => void;
  setFeedbackQuestion: (question: FeedbackQuestion) => void;
  setResponseComment: (comment: string) => void;
} & FeedbackState;

const initState = {
  submitted: false,
  score: { score: 1, selected: false },
  dismissed: false,
  visibility: "hidden" as const,
  response: {
    value: 1,
  },
};

const initContext = {
  dismiss: () => {
    throw new Error("Not implemented.");
  },
  dismissForever: () => {
    throw new Error("Not implemented.");
  },
  submitFeedback: (_score: Score, _comment?: string) => {
    throw new Error("Not implemented.");
  },
  submitResponse: (_uuid: string) => {
    throw new Error("Not implemented.");
  },
  toggleVisibility: (_visibility: FeedbackVisibility ) => {
    throw new Error("Not implemented.");
  },
  setFeedbackQuestion: (_question: FeedbackQuestion) => {
    throw new Error("Not implemented.");
  },
  setResponseComment: (_comment: string) => {
    throw new Error("Not implemented.");
  },
  ...initState,
}

export const FeedbackContext = createContext<FeedbackContext>(initContext);

export const FeedbackProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const localStore = useLocalStorage();
  const storedDate = localStore.getItem("FEEDBACK_DISMISSED");
  let dismissed = false;

  if (storedDate != null) {
    const m = moment(storedDate).valueOf();
    const now = moment().valueOf();
    dismissed = (m > now) ? true : false;
  }

  const [ state, dispatch ] = useReducer(FeedbackReducers, { ...initState, dismissed });

  const context: FeedbackContext = {
    ...state,
    dismiss: () => {
      dispatch({ type: "DISMISS" });
    },
    dismissForever: () => {
      dispatch({ type: "DISMISS_FOREVER" });
    },
    submitFeedback: (score: Score, comment?: string) => {
      dispatch({ type: "SUBMIT_FEEDBACK", payload: { score, comment } });
    },
    submitResponse: (uuid: string) => {
      dispatch({ type: "SUBMIT_RESPONSE", payload: { uuid } });
    },
    toggleVisibility: (visibility: FeedbackVisibility) => {
      dispatch({ type: "TOGGLE_VISIBILITY", payload: { visibility } });
    },
    setFeedbackQuestion: (question: FeedbackQuestion) => {
      dispatch({ type: "SET_FEEDBACK_QUESTION", payload: { question } });
    },
    setResponseComment: (comment: string) => {
      dispatch({ type: "SET_RESPONSE_COMMENT", payload: { comment }});
    },
  };

  return (
    <FeedbackContext.Provider value={context}>
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedbackContext = () => useContext(FeedbackContext);
