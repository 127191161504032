import { TranslationProvider } from "@equiem/localisation";
import { defaultNamespace, languages, Session, Site } from "@equiem/web-ng-lib";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { useRouter } from "next/router";
import { SiteLoading } from "../components/SiteLoading";
import { checkLanguageCode } from "../lib/checkLanguageCode";
import { globalGateway } from "../lib/globalGateway";

export const LocaleProvider: React.FC = ({ children }) => {
  const session = useContext(Session);
  const site = useContext(Site);
  const { query } = useRouter();
  // language to stick when navigating to paths without query parameter
  const preferredLanguage = useMemo(
    () => checkLanguageCode(`${query.ui_locale}`),
    [],
  );

  const [translationsReady, setTranslationsReady] = useState(false);
  const onTranslationsReadyStateChanged = useCallback((isReady: boolean) => {
    if (isReady) {
      setTranslationsReady(true);
    }
  }, []);

  const scope =
    session.side === "client" && session.authenticated
      ? { useViewer: true, preferredLanguage }
      : { siteUuid: site.uuid, preferredLanguage };

  const client =
    session.side === "client" && session.authenticated
      ? session.authenticatedClient
      : globalGateway;

  return (
    <TranslationProvider
      localePreferenceScope={scope}
      defaultNamespace={defaultNamespace}
      languages={languages}
      client={client}
      onReadyStateChanged={onTranslationsReadyStateChanged}
    >
      {translationsReady ? children : <SiteLoading />}
    </TranslationProvider>
  );
};
