import { useState, useEffect, useContext } from "react";
import { Theme } from "../contexts";

interface WindowsState {
  width?: number;
  height?: number;
  isMobileWidth: boolean;
}

export function useWindowSize() {
  const { breakpoints } = useContext(Theme);
  const [windowSize, setWindowSize] = useState<WindowsState>({ isMobileWidth: false });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileWidth: (window.innerWidth ?? 0) <= breakpoints.sm,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
