import { Button, ProgressCircle, Site, TextField, useShowError, Viewer } from "@equiem/web-ng-lib";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { setInputValue } from "./setInputValue";
import { CreditCardBrand } from "./CreditCardBrand";
import { CardBrand, useSaveCardMutation } from "../../generated/gateway-client";
import { useTranslation } from "../../generated/localisation";

interface Props {
  className?: string;
  autofocus?: boolean;
  setDefault: boolean;
  onCardAdded?: (card: { uuid: string }) => void;
  onCancel?: () => void;
}

export const NewCreditCard: React.FC<Props> = ({
  className = "",
  autofocus = false,
  setDefault,
  onCardAdded = () => undefined,
  onCancel = () => undefined,
}) => {
  const { t } = useTranslation();
  const showError = useShowError();
  const alert = useAlert();
  const site = useContext(Site);
  const viewer = useContext(Viewer);
  const stripe = useStripe();
  const elements = useElements();
  const [saveCard] = useSaveCardMutation();
  const [brand, setBrand] = useState<CardBrand>(CardBrand.Unknown);
  const [focused, setFocused] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [description, setDescription] = useState("");
  const [saving, setSaving] = useState(false);
  const descriptionRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (descriptionRef.current == null) {
      return;
    }

    const onInput = () => {
      setDescription(descriptionRef.current?.value ?? "");
    };

    descriptionRef.current.addEventListener("input", onInput);

    return () => {
      descriptionRef.current?.removeEventListener("input", onInput);
    }
  }, [descriptionRef.current])

  const onSaveCard = useCallback(async () => {
    try {
      const cardElement = elements?.getElement(CardElement);
      if (cardElement == null || stripe == null || viewer == null) {
        throw new Error(t("lib.stripeElementIsNotInitialised").toString());
      }

      setSaving(true);
      const { token, error } = await stripe.createToken(cardElement, {
        name: description,
      });

      if (error != null) {
        throw error;
      }

      if (token?.id == null) {
        showError(t("lib.tokenisationFailed"));

        return;
      }

      const result = await saveCard({
        variables: {
          card: {
            newCardToken: token.id,
            description,
            setDefault,
          },
        },
      });

      const saved = result.data?.saveOwnCard;
      if (saved == null) {
        throw new Error(t("lib.somethingWentWrongPlsTryAgain").toString());
      }

      if (saved.created < (Date.now() - 60_000)) {
        alert.success(saved.default
          ? t("lib.cardDetailsExistandDefault")
          : t("lib.cardDetailsExist"),
        );
      }
      else {
        setInputValue(descriptionRef.current, "");
        alert.success(saved.default
          ? t("lib.cardSavedAndDefault")
          : t("lib.cardSaved"),
        );
      }

      await viewer.refetch();
      onCardAdded({ uuid: saved.uuid });
      try {
        cardElement.clear();
      }
      catch (e) {
        // Element has probably been destroyed.
      }
    }
    catch (e) {
      console.error(e);
      throw e;
    }
    finally {
      setSaving(false);
    }
  }, [description, setDefault, elements, stripe, showError, saveCard, viewer, t]);

  return (
    <div className={`new-card ${className}`}>
      <div className="border rounded p-3 my-3">
        <div className={`card-input border-bottom d-flex align-items-center ${focused ? "focused" : ""}`}>
          <CreditCardBrand brand={brand} />
          <div className="flex-grow-1 hide-in-percy">
            <CardElement
              onReady={(e) => {
                if (autofocus) {
                  e.focus();
                }
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
              onChange={(e) => {
                setBrand({
                  amex: CardBrand.AmericanExpress,
                  diners: CardBrand.DinersClub,
                  discover: CardBrand.Discover,
                  jcb: CardBrand.Jcb,
                  mastercard: CardBrand.Mastercard,
                  unionpay: CardBrand.Unionpay,
                  visa: CardBrand.Visa,
                  unknown: CardBrand.Unknown,
                }[e.brand]);
                setCardComplete(e.complete);
              }}
              options={{
                hideIcon: true,
                hidePostalCode: true,
                style: {
                  base: {
                    "::placeholder": {
                      color: "#bbb",
                    },
                    "fontFamily": "Roboto Flex",
                    "fontSize": "16px",
                    "color": "#495057",
                  },
                },
              }}
            />
          </div>
        </div>
        <TextField
          label={t("lib.shortDescription")}
          name="description"
          required
          className="mt-4"
          value={description}
          ref={descriptionRef}
        />
        <div className="mt-3 text-right">
          <Button
            variant="link"
            onClick={() => {
              onCancel();
            }}
          >{t("lib.cancel")}</Button>
          <Button
            variant="solid"
            type="button"
            disabled={!cardComplete || description.trim() === "" || saving}
            onClick={(e) => {
              e.preventDefault();
              onSaveCard().catch(showError);
            }}
          >
            {saving ? <ProgressCircle size="sm" className="mr-1" color={site.primaryContrastColour} /> : null}
            {t("lib.addCard")}
          </Button>
        </div>
      </div>
      <style jsx>{`
        h2 {
          font-size: 1rem;
        }
        .card-input.focused {
          border-color: ${site.whiteContrastColour} !important;
        }
        .new-card :global(.form-group) {
          margin-bottom: 0;
        }
        @media (max-width: 374px) {
          .card-input {
            display: block !important;
          }
        }
      `}</style>
    </div>
  );
};
