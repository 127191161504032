import { useTranslation } from "@equiem/web-ng-lib";
import color from "color";
import React from "react";
import { useLogoBackground } from "../../hooks/useLogoBackground";

interface Props {
  open: boolean;
  openTxt?: string;
  closeTxt?: string;
}

export const OpenCloseBtn: React.FC<Props> = ({ open, openTxt, closeTxt }) => {
  const { t } = useTranslation();

  const { bgColor, fgColor } = useLogoBackground();
  const background = color(fgColor).alpha(0.15).rgb().string();

  return (
    <>
      <div className={`oc-btn ${open ? "open" : ""}`}>
        <div className="oc-btn-holder">
          <span></span>
          <span></span>
          <span></span>
          <div className="txt">
            {open ? closeTxt ?? t("main.close") : openTxt ?? t("main.open")}
          </div>
        </div>
      </div>
      <style jsx>{`
        a:hover {
          text-decoration: none;
        }
        .oc-btn {
          padding: 12px 16px;
          background: ${background};
          color: ${fgColor}
          display: block;
          position: relative;
          cursor: pointer;
          border-radius: 28px;
          transition: .25s ease-in;
        }
        .oc-btn:hover, .oc-btn:active {
          background: ${fgColor};
          color: ${bgColor};
        }
        .oc-btn-holder {
          position: relative;
          padding-left: 24px;
          min-width: 64px;
        }
        .oc-btn .txt {
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
        }
        .oc-btn span {
          display: block;
          position: absolute;
          height: 2px;
          width: 16px;
          background: ${fgColor};
          border-radius: 9px;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .25s .25s ease-in-out, .25s background ease-in;
        }
        .oc-btn:hover span, .oc-btn:active span {
          background: ${bgColor};
        }

        .oc-btn span:nth-child(1) {
          top: -1px;
        }
        .oc-btn span:nth-child(2) {
          top: 5px;
        }
        .oc-btn span:nth-child(3) {
          top: 11px;
        }

        .open.oc-btn span:nth-child(1) {
          top: 5px;
          transform: rotate(135deg);
        }
        .open.oc-btn span:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        .open.oc-btn span:nth-child(3) {
          top: 5px;
          transform: rotate(-135deg);
        }
      `}</style>
    </>
  );
};
