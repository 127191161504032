import { Form, useTheme } from "@equiem/react-end-user-ui";
import { ChatContext } from "./ChatContext";
import { useContext, useEffect, useRef, useState } from "react";

export const ChatWindowInput = () => {
  const { sendMessage, loading, open } = useContext(ChatContext);
  const { spacers } = useTheme();
  const [input, setInput] = useState<string>("");
  const ref = useRef<HTMLInputElement>(null);

  const onSubmit = async (message: string) => {
    setInput("");
    await sendMessage(message);
    ref.current?.focus();
  };

  useEffect(() => {
    if (open) {
      ref.current?.focus();
    }
  }, [open]);

  return (
    <>
      <div>
        <Form.Input
          ref={ref}
          placeholder={loading ? "Awaiting answer..." : "How can I help?"}
          autoFocus
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={loading}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              void onSubmit(input);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          padding: ${spacers.s4};
        }
      `}</style>
    </>
  );
};
