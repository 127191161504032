import { PageTitleContext } from "@equiem/web-ng-lib";
import React, { useContext, useEffect } from "react";

interface Props {
  children?: string;
}

export const PageTitle: React.FC<Props> = ({ children: title }) => {
  const { setPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle(typeof title === "string" ? title : null);

    return () => setPageTitle(null);
  }, [title]);

  return <></>;
};
