import { random } from "lodash";
import React from "react";
import { DateTime } from "../lib/DateTime";
import { CartReducerState, Item, ItemInput } from "./CartInterfaces";

type ShowCart = { type: "SHOWCART" };
type HideCart = { type: "HIDECART" };
type ToggleCartBtn = { type: "TOGGLECARTBTN"; value: boolean };
type UpdateQuantity = { type: "UPDATEQTY"; id: string; quantity: number };
type AddItem = { type: "ADDITEM"; item: ItemInput; quantity?: number };
type RemoveItems = { type: "REMOVEITEMS"; ids: string[] };
type UpdateSubtotal = { type: "UPDATESUBTOTAL"; id: string; subtotal: number };
type Restore = { type: "RESTORE"; state: CartReducerState };
type AddDiscountCode = { type: "ADD_DISCOUNT_CODE"; code: string | null };

export type Action = AddDiscountCode | UpdateQuantity | AddItem | RemoveItems | UpdateSubtotal | Restore | ShowCart | HideCart | ToggleCartBtn;

export const sumItems = (items: Item[]) => ({
  itemCount: items.reduce((total, item) =>
    total + item.quantity, 0),
  total: items.length === 0 ? null : (items.some((i) => i.subtotal == null) ? null : items.reduce((prev, item) => prev + (item.subtotal ?? 0) * item.quantity, 0)),
});

/**
 * NOTE: To avoid double up the work here, this method assume that you have called allowToAdd function.
 */
const addItem = (action: AddItem, state: CartReducerState) => {
  const updated = DateTime.today().valueOf();

  const quantity = action.item.type === "BOOKING" ? action.item.selected.session.attendees : (
    action.quantity == null ? 1 : +action.quantity
  );

  const updateQty = action.item.type === "PLAIN" &&
    action.item.selected.modChoices.length === 0 &&
    state.items.findIndex((i) => i.type === "PLAIN" && i.uuid === action.item.uuid) > -1;

  const items = updateQty ? state.items.map((i) => (i.type === "PLAIN" && i.uuid === action.item.uuid) ? {
    ...i,
    quantity: i.quantity + quantity,
  } : i) : [...state.items, {
    ...action.item,
    id: `${updated}-${random(100)}-${action.item.uuid}`,
    quantity,
  }];

  return {
    ...state,
    updated,
    items,
    ...sumItems(items),
  };
}

const updateQuantity = (state: CartReducerState, action: UpdateQuantity) => {
  const target = state.items.find((item) => item.id === action.id);
  if (target == null) {
    return state;
  }

  // Booking product can only be 1.
  if (action.quantity > 1 && (target.type === "BOOKING" || target.type === "SUBSCRIPTION")) {
    return state;
  }

  const items = action.quantity === 0 ?
    state.items.filter((item) => item.id !== action.id) :
    state.items.map((item) => item.id === action.id ? { ...item, quantity: action.quantity } : item)
  ;

  return {
    ...state,
    updated: DateTime.today().valueOf(),
    items,
    ...sumItems(items),
  };
}

const updateSubTotal = (state: CartReducerState, action: UpdateSubtotal) => {
  const target = state.items.find((item) => item.id === action.id);
  if (target == null) {
    return state;
  }

  const items = state.items.map((item) => item.id === action.id ? { ...item, subtotal: action.subtotal } : item);

  return {
    ...state,
    updated: DateTime.today().valueOf(),
    items,
    ...sumItems(items),
  };
}

const removeItems = (state: CartReducerState, action: RemoveItems) => {
  const items = state.items.flatMap((item) => action.ids.some((id) => id === item.id) ? [] : [item]);

  return {
    ...state,
    updated: DateTime.today().valueOf(),
    items,
    ...sumItems(items),
  };
}

export const CartReducer: React.Reducer<CartReducerState, Action> = (state, action) => {
  switch (action.type) {
    case "TOGGLECARTBTN":
      return { ...state, showCartBtn: action.value };
    case "HIDECART":
      return { ...state, showCartModal: false };
    case "SHOWCART":
      return { ...state, showCartModal: true };
    case "ADDITEM":
      return addItem(action, state);
    case "REMOVEITEMS":
      return removeItems(state, action);
    case "UPDATESUBTOTAL":
      return updateSubTotal(state, action);
    case "UPDATEQTY":
      return updateQuantity(state, action);
    case "ADD_DISCOUNT_CODE":
      return { ...state, discountCode: action.code };
    case "RESTORE":
      return action.state;
    default:
      return state;
  }
}
