import { Trans, useTranslation } from "@equiem/web-ng-lib";
import { Button, Check, ProgressCircle, Site, useShowError, Viewer } from "@equiem/web-ng-lib";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import {
  useAcceptTermsForDestinationMutation,
  ViewerCurrentDestinationFragment,
  ViewerCurrentDestinationFragmentDoc,
} from "../generated/gateway-client";

export const LegalCheck: React.FC = () => {
  const { t } = useTranslation();

  const viewer = useContext(Viewer);
  const site = useContext(Site);
  const router = useRouter();
  const alert = useAlert();
  const showError = useShowError();
  const [declining, setDeclining] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [mutation, { loading }] = useAcceptTermsForDestinationMutation({
    variables: { input: { destination: site.uuid } },
  });

  if (viewer == null || viewer.termsAccepted === true) {
    return null;
  }

  const onDecline = () => {
    setDeclining(true);
    router.push("/api/logout").catch((e) => {
      console.error(e);
    });
  };

  const onAccept = async () => {
    try {
      const mutationResult = await mutation({
        update: (cache, result) => {
          if (result.data?.acceptTermsForDestination !== true) {
            return;
          }

          cache.writeFragment<ViewerCurrentDestinationFragment>({
            id: `User:${viewer.uuid}`,
            fragment: ViewerCurrentDestinationFragmentDoc,
            data: {
              __typename: "User",
              currentDestination: {
                __typename: "UserDestination",
                termsAccepted: true,
                permissions: {
                  readComments: viewer.permissions.readComments,
                  writeComments: viewer.permissions.writeComments,
                  canSubscribeToEmails: viewer.permissions.canSubscribeToEmails,
                  canSubscribeToNotifications: viewer.permissions.canSubscribeToNotifications,
                },
              },
            },
          });
        },
      });

      if (mutationResult.data?.acceptTermsForDestination === false) {
        alert.error(t("main.somethingWentWrongTryAgainError"));
      }
    }
    catch (e) {
      showError(e);
    }
  }

  return (
    <Modal
      dialogClassName="legal-check-dialog"
      key="legal-modal"
      size="sm"
      backdrop="static"
      show={viewer.termsAccepted != null ? !viewer.termsAccepted : false}
      keyboard={true}
      centered={true}
      scrollable={true}
    >
      <Modal.Header>
        {/* Do not remove terms-modal id. It's used in external automated tests */}
        <Modal.Title id="terms-modal">
          {t("main.weHaveUpdatedTermsAndConditionsAndPrivacyPolicy")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          {/* Do not remove terms-checkbox id. It's used in external automated tests */}
          <Check id="terms-checkbox" onChange={(e) => {
            setAccepting(e.currentTarget.checked);
          }} />
          <p>
            <Trans i18nKey="main.acceptTermsAndConditionsSeePrivacyPolicy">
              Accept the <a href="/legal" target="_blank">Terms & Conditions</a>. Please also see our <a href="/privacy" target="_blank">Privacy Policy</a> for how we collect and use your personal information.
            </Trans>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          onClick={onDecline}
          disabled={declining || loading}
        >
          {declining ? (
            <span className="mr-1">
              <ProgressCircle size="sm" />
            </span>
          ) : null}
          {t("main.decline")}
        </Button>
        <Button
          variant="solid"
          onClick={onAccept}
          disabled={declining || loading || !accepting}
          // Do not remove terms-accept-button class name. It's used in external automated tests.
          className="terms-accept-button"
        >
          {loading ? (
            <span className="mr-1">
              <ProgressCircle size="sm" color={site.primaryContrastColour} />
            </span>
          ) : null}
          {t("main.accept")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
