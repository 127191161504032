import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { FieldError } from "react-hook-form";

interface Props {
  id: string;
  label?: string;
  empty?: boolean;
  focused?: boolean;
  required?: boolean;
  error?: FieldError;
  className?: string;
  elType?: string;
  children?: React.ReactNode;
}

export const FormGroup: React.FC<Props> = ({
  label,
  empty = true,
  focused = false,
  required = false,
  id,
  className = "",
  error,
  children,
  elType = "input",
}) => {
  const site = useContext(Site);

  return (
    <div className={`form-group ${className} ${
      !focused ? "not-" : ""}focused ${
      !empty ? "not-" : ""}empty ${
      error != null ? "text-warning" : ""}`
    }>
      <Form.Group style={{ maxWidth: "100%" }}>
        { label != null ? (
          <Form.Label htmlFor={id}>{label}{required ? " *" : ""}</Form.Label>
        ) : null }
        { children }
        {error != null ? <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback> : null}
      </Form.Group>
      <style jsx>{`
      .form-group :global(.form-group) {
        position: relative;
        padding-top: 1rem;
        margin-top: -1rem;
        margin-bottom: 2.5rem;
      }
      .form-group :global(${elType}) {
        border-radius: 0;
        border-width: 0 0 1px 0;
        padding-left: 0;
        padding-right: 0;
      }
      .form-group :global(${elType}:focus) {
        outline: none;
        box-shadow: none;
      }
      .form-group :global(${elType}:focus:not(.is-invalid)) {
        border-color: ${site.whiteContrastColour};
      }
      .form-group :global(label) {
        position: absolute;
        z-index: 1;
        font-size: 1rem;
        top: 1.5rem;
        left: 0;
        transition: all 100ms;
        opacity: 0.3;
        cursor: text;
      }
      .form-group.focused :global(label),
      .form-group.not-empty :global(label) {
        top: 0;
        font-size: .8rem;
        font-weight: 500;
        opacity: 0.3;
        cursor: default;
      }
      .form-group.not-empty:not(.text-warning) :global(label),
      .form-group.focused:not(.text-warning) :global(label) {
        color: ${site.whiteContrastColour};
      }
      .form-group.text-warning.focused :global(label),
      .form-group.focused :global(label),
      .form-group.not-empty :global(label) {
        opacity: 1;
      }
    `}</style>
    </div>
  );
};
