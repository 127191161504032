import { FeedbackQuestion, FeedbackVisibility, Score } from "../components/feedback/FeedbackTypes";
import { FeedbackState } from "./FeedbackContext";

type SubmitFeedback = { type: "SUBMIT_FEEDBACK"; payload: { score: Score; comment?: string }  };
type Dismiss = { type: "DISMISS" };
type DismissForever = { type: "DISMISS_FOREVER" };
type ToggleVisibility = { type: "TOGGLE_VISIBILITY"; payload: { visibility: FeedbackVisibility } };
type SubmitResponse = { type: "SUBMIT_RESPONSE"; payload: { uuid: string } };
type SetFeedbackQuestion = { type: "SET_FEEDBACK_QUESTION"; payload: { question: FeedbackQuestion } };
type SetResponseQuestion = { type: "SET_RESPONSE_COMMENT"; payload: { comment: string } };

export type Action = SubmitFeedback |
  Dismiss |
  DismissForever |
  ToggleVisibility |
  SubmitResponse |
  SetFeedbackQuestion |
  SetResponseQuestion;

export const FeedbackReducers: React.Reducer<FeedbackState, Action> = (state, action) => {
  switch (action.type) {
    case "SUBMIT_FEEDBACK":
      return {
        ...state,
        submitted: true,
        score: action.payload.score,
        comment: action.payload.comment,
        response: {
          ...state.response,
          comment: action.payload.comment,
          value: action.payload.score.score,
        },
      };

    case "DISMISS":
      return { ...state, dismissed: true };

    case "DISMISS_FOREVER":
      return { ...state, dismissed: true, visibility: "hidden" };

    case "TOGGLE_VISIBILITY":
      return { ...state, visibility: action.payload.visibility };

    case "SUBMIT_RESPONSE":
      return { ...state, response: { ...state.response, uuid: action.payload.uuid } }

    case "SET_FEEDBACK_QUESTION":
      return {
        ...state,
        question: action.payload.question,
        submited: false,
        response: { value: 1 },
      };

    case "SET_RESPONSE_COMMENT":
      return {
        ...state,
        response: {
          ...state.response,
          comment: action.payload.comment,
        },
      }

    default:
      return state;
  }
};
