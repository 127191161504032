import { Session } from "@equiem/web-ng-lib";
import { useCallback, useContext, useEffect } from "react";
import { applicationId } from "../config/applicationId";

interface ResizeMessage {
  method: "embed_resize";
  height: number;
}

export const EquiemOembedListener: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const session = useContext(Session);

  const tokenOnMessage = useCallback((event: MessageEvent) => {
    if (!/^https\:\/\/(.+)\.getequiem\.com$/.test(event.origin) || event.data !== "request_token") {
      return;
    }

    const source = event.source as Window

    source.postMessage(
      { access_token: session.accessToken, app_id: applicationId },
      event.origin,
    );
  }, [session.accessToken]);

  const resizeOnMessage = useCallback((event: MessageEvent<ResizeMessage>) => {
    const frames = document.getElementsByTagName("iframe");
    if (
      !/^https\:\/\/(.+)\.getequiem\.com$/.test(event.origin) ||
      event.data.method !== "embed_resize"
    ) {
      return;
    }
    for (const frame of frames) {
      if (frame.contentWindow === event.source) {
        frame.parentElement!.style.height = `${event.data.height}px`;
        break;
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("message", tokenOnMessage, false);
    window.addEventListener("message", resizeOnMessage, false);

    return () => {
      window.removeEventListener("message", tokenOnMessage);
      window.removeEventListener("message", resizeOnMessage);
    }
  }, [session.accessToken]);

  return <>{children}</>;
};
