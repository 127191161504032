import { Subscriber } from "@equiem/user-pubsub/dist/Subscriber";
import { useEffect, useState } from "react";
import { logError } from "../lib/logError";

export interface SubscriberState<S extends Subscriber<any>> {
  subscriber: S | null;
  subscribed: boolean;
  connected: boolean;
  error?: string | Error;
};

/**
 * A hook which can be used for any Subscriber implementation to manage it's subscription / connection status.
 *
 * @param subscriber The Subscriber instance (consider whether this should be a cached singleton instance).
 * @param cache The cached state of the Subscriber, if available.
 */
export const useSubscriber = <S extends Subscriber<any>>(
  subscriber: S | null,
  cache?: SubscriberState<S>,
) => {
  const [connected, setConnected] = useState(cache?.connected ?? false);
  const [subscribed, setSubscribed] = useState(cache?.subscribed ?? false);
  const [error, setError] = useState<Error | string | undefined>();

  useEffect(() => {
    if (subscriber == null) {
      return;
    }

    const onClose = () => {
      setConnected(false);
    };
    subscriber.on("close", onClose).catch(logError);

    const onConnect = () => {
      setConnected(true);
    };
    subscriber.on("connect", onConnect).catch(logError);

    subscriber.subscribe().then(() => {
      setSubscribed(true);
      setConnected(true);
    }).catch((e) => {
      console.error(e);
      setSubscribed(false);
      setConnected(false);
      setError(e instanceof Error ? e : `${e}`);
    });

    return () => {
      subscriber.off("close", onClose).catch(logError);
      subscriber.off("connect", onConnect).catch(logError);
    };
  }, [subscriber]);

  const state = { subscriber, subscribed, connected, error };

  return state;
};
