import { Card, useTheme } from "@equiem/react-end-user-ui";
import { Boop } from "./Boop";
import type { AiChatSuggestion } from "../generated/ai-client";
import { useContext, type FC } from "react";
import { ChatContext } from "./ChatContext";

interface Props {
  suggestion: AiChatSuggestion;
}

export const Suggestion: FC<Props> = ({ suggestion }) => {
  const { colors } = useTheme();
  const { sendMessage } = useContext(ChatContext);

  const submitSuggestion = async (message: string) => {
    await sendMessage(message);
  };

  return (
    <Boop
      rotation={5}
      scale={1.05}
      style={{
        display: "flex",
        margin: "0 10px 0 0",
        position: "relative",
        flex: "0 0 140px",
      }}
    >
      <Card.Card
        style={{
          cursor: "pointer",
          backgroundColor: colors.greyBackground,
        }}
        onClick={() => {
          void submitSuggestion(suggestion.message);
        }}
      >
        <Card.CardBody>
          <h5>{suggestion.category}</h5>
          {suggestion.message}
        </Card.CardBody>
      </Card.Card>
      <style jsx>{`
        h5 {
          font-weight: 700;
          font-size: 12px;
          margin-bottom: 4px;
        }
      `}</style>
    </Boop>
  );
};
