// tslint:disable: no-floating-promises

export const supportsNotifications = () => typeof window !== "undefined" && typeof Notification !== "undefined";

export const requestNotificationPermission = async () => new Promise(async (resolve) => {
  try {
    await Notification.requestPermission(resolve).then(resolve);
  }
  catch (e) {
    // Don't need to do anything.
  }
});
