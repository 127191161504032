const whitelistEmails = [
  "jeremy.kirkham@equiem.com.au",
  "ryan.ghanbari@getequiem.com",
  "rinita.sur@getequiem.com",
  "jen.leibhart@getequiem.com",
  "faraz.ahmad@getequiem.com",
];

export const useShowAi = (email?: string) => {
  return email != null && whitelistEmails.includes(email);
};
