import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { useQuickLinksQuery } from "../../generated/gateway-client";
import { QuickLink } from "../QuickLink";

interface Props {
  quickLinksQuery: ReturnType<typeof useQuickLinksQuery>;
}

export const QuickLinks: React.FC<Props> = ({ quickLinksQuery: { data } }) => {
  const { t } = useTranslation();

  const links = data?.quickLinks ?? [];

  return (
    <div className="quick-links">
      <h3 className="pb-2 mb-2">{t("main.quicklinks")}</h3>
      {links.map(({ uuid, title, url }, i) => (
        <QuickLink
          key={`qlink-${i}`}
          className="font-weight-bold d-block quick-link py-2 mb-2"
          uuid={uuid}
          url={url}
          title={title}
        />
      ))}
      <style jsx>{`
        h3 {
          font-size: 12px;
          font-weight: 700;
        }
      `}</style>
    </div>
  );
};
