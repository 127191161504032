import { formatters, useTranslation } from "@equiem/web-ng-lib";
import { Site, Theme, useShowError } from "@equiem/web-ng-lib";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { PushNotifications } from "../../hooks/usePushNotifications";
import { Cross } from "../icons/Cross";

type Message = PushNotifications["messages"][number];

interface Props {
  message: Message;
  onDismiss: () => Promise<void>;
  onClick?: () => void;
}

let mounted = false;

export const Notification: React.FC<Props> = ({
  message,
  onClick,
  onDismiss,
}) => {
  const { i18n } = useTranslation();

  const site = useContext(Site);
  const { breakpoints } = useContext(Theme);
  const [dismissing, setDismissing] = useState(false);
  const showError = useShowError();

  useEffect(() => {
    mounted = true;

    return () => {
      mounted = false;
    }
  }, [])

  if (message.notification == null) {
    return null;
  }

  const handleDelete = async () => {
    if (dismissing) {
      return;
    }

    if (mounted) {
      setDismissing(true);
    }

    try {
      await onDismiss();
    }
    catch (e) {
      if (mounted) {
        showError(e);
        setDismissing(false);
      }
      else {
        console.error(e);
      }
    };
  };

  const handleClick = async () => {
    if (onClick != null) {
      onClick();
    }
    await handleDelete();
  };

  const time = useMemo(() => formatters.relativelong(message.timestamp, i18n.language), [message.timestamp, i18n.language]);

  const body = (
    <>
      <div className="title">{`${message.notification.title} `}</div>
      <div className="body pt-1">{message.notification.body}</div>
      <div className="time pt-1">{time}</div>
      {(message.data?.type === "deeplink" && message.data.image !== undefined) ? (
        <Image src={message.data.image} className="mt-2 mx-auto d-block" fluid />
      ) : null}
      <style jsx>{`
        .time {
          opacity: 0.5;
        }
        .title {
          font-weight: 600;
          text-transform: capitalize;
        }
        .body {
          font-size: 12px;
        }
        @media (min-width: ${breakpoints.md}px) {
          .body {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );

  return (
    <div className={`message cursor-pointer rounded ${dismissing ? "deleting" : ""}`}>
      <a className="delete" onClick={handleDelete}><Cross size={13} /></a>
      { onClick != null
        ? <a className="text" onClick={handleClick}>{body}</a>
        : <div className="text">{body}</div>
      }
      <style jsx>{`
        .message {
          border: 1px solid #5F80F8;
          padding: 11px 16px;
          font-size: 10px;
          transition: border-color .2s ease-in-out;
          margin: 0 0 16px;
          color: ${site.primaryContrastColour};
        }
        .message:hover {
          border-color: #fff;
        }
        .deleting {
          opacity: 0.2;
        }
        a.delete {
          float: right;
          cursor: pointer;
          position: relative;
          z-index: 1;
        }
        a {
          color: ${site.primaryContrastColour};
        }
        a:hover {
          text-decoration: none;
        }
        a:hover:after {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
        a.delete:hover {
          opacity: 1;
        }
        @media (min-width: ${breakpoints.md}px) {
          .message {
            padding: 13px 16px;
            font-size: 12px;
            margin: 0 0 16px;
          }
        }
      `}</style>
    </div>
  );
};
