import React, { useContext, useEffect } from "react";
import { SideMenuContext } from "../../contexts/SideMenuProvider";

export const SideMenuOverlay: React.FC = () => {
  const sideMenu = useContext(SideMenuContext);

  useEffect(() => {
    const escListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        sideMenu.hide();
      }
    }
    window.addEventListener("keydown", escListener);

    return () => {
      window.removeEventListener("keydown", escListener);
    };
  }, []);

  return (
    <>
      <div className={`site-navbar-shadow ${sideMenu.isShown ? "open" : ""}`} onClick={() => {
        sideMenu.hide();
      }}></div>
      <style jsx>{`
        .site-navbar-shadow {
          position: fixed;
          z-index: ${sideMenu.zIndex.overlay};
          top: 0;
          height: 100vh;
          right: -200%;
          background: rgba(0, 0, 0, .7);
          width: 100%;
          height: 100vh;
          opacity: 0;
          transition: .8s ease-in-out;
        }
        .site-navbar-shadow.open {
          right: auto;
          left: 0;
          display: block;
          opacity: 1
        }
      `}</style>
    </>
  );
}
