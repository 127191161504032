import React from "react";

export const Location: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      transform="scale(1.2) translate(-1, -1.5)"
      d="M13.3618 12.5376C14.2158 11.3267 14.6725 9.98335 14.7126 8.4902C14.6835 8.30312 14.6591 8.11491 14.6347 7.92669C14.5817 7.51702 14.5287 7.10733 14.4267 6.70929C13.5318 3.21741 9.81713 0.877522 6.32843 1.58626C1.17774 2.63268 -1.16303 8.322 1.82723 12.5576C3.03558 14.2694 4.26071 15.9702 5.48568 17.6707C5.8789 18.2166 6.2721 18.7624 6.66473 19.3086C6.77933 19.4682 6.9102 19.6224 7.05931 19.7506C7.39333 20.038 7.81852 20.038 8.15156 19.7458C8.31044 19.6062 8.44587 19.4354 8.56861 19.2634C8.85149 18.8678 9.1345 18.4722 9.4175 18.0767C10.7366 16.233 12.0556 14.3895 13.3618 12.5376ZM8.6847 17.1885C8.3261 17.6915 7.96578 18.1969 7.60325 18.7054C7.35069 18.3547 7.10222 18.0101 6.85663 17.6695C6.35606 16.9753 5.86748 16.2977 5.38065 15.6189C5.13786 15.2801 4.89401 14.9419 4.65017 14.6037C4.00403 13.7077 3.35793 12.8117 2.73157 11.9026C1.43682 10.0253 1.23953 8.02025 2.22337 5.9732C3.22544 3.88927 4.96328 2.75096 7.3135 2.56114C10.0707 2.33793 12.8711 4.52361 13.4246 7.30483C13.7361 8.87048 13.4477 10.3338 12.5905 11.6905C12.5013 11.832 12.4053 11.969 12.3079 12.1051C11.1072 13.7908 9.90596 15.4756 8.6847 17.1885Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      transform="scale(1.2) translate(-1, -1.5)"
      d="M7.5862 11.781C9.35334 11.7756 10.7549 10.3219 10.7503 8.49847C10.7458 6.6811 9.32404 5.21897 7.5696 5.22801C5.80506 5.23638 4.4045 6.69315 4.40841 8.51521C4.41232 10.3393 5.82036 11.786 7.5862 11.781ZM7.61361 6.37575C8.71693 6.39316 9.63632 7.35709 9.63566 8.49512C9.63469 9.67166 8.72312 10.6245 7.58952 10.6332C6.46113 10.6426 5.52677 9.68672 5.52286 8.51889C5.51863 7.32729 6.46471 6.35767 7.61361 6.37575Z"
    />
  </svg>
)
