export const setInputValue = (
  input: HTMLInputElement | null,
  value: string,
) => {
  // tslint:disable-next-line: no-unbound-method
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
  nativeInputValueSetter?.call(input, value);

  input?.dispatchEvent(new CustomEvent("input", { bubbles: true }));
};
