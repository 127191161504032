import { ApolloClient } from "@apollo/client";
import React from "react";
import { Array, Boolean, Literal, Null, Partial, Record, Static, String, Undefined, Union } from "runtypes";
import { FeatureModuleType, Maybe, SiteSettingsQuery, TaxRegionDocument, TaxRegionQuery, TaxRegionQueryVariables } from "../generated/gateway-client";
import { regionalGateway } from "../lib/regionGateway";
import { stringNotEmpty } from "../lib/stringNotEmpty";

const FeatureModuleFeatureType = Record({
  enabled: Boolean
}).And(Partial({
  url: String,
  label: String,
  enableMenu: Boolean,
}));

type FeatureModuleFeatureType = Static<typeof FeatureModuleFeatureType>;

export const SiteContextInput = Record({
  uuid: String,
  name: String,
  primaryColour: String,
  primaryColourIsDark: Boolean,
  primaryContrastColour: String,
  whiteContrastColour: String,
  favicon: String,
  siteUrl: String,
  timezone: String,
  defaultLoginRealm: String,
  reportingEnabled: Boolean,
  accountAppUrl: Union(String, Null),
  androidAppId: Union(String, Null),
  iosAppId: Union(String, Null),
  address: String,
  email: Union(String, Null),
  taxRegion: Record({
    currency: Record({
      code: String,
    }),
  }),
  loggedInHome: Record({
    showSiteName: Boolean,
  }).And(
    Partial({
      heroImage: Union(String, Null),
    }),
  ),
  transitScreen: Union(
    Record({
      apiKey: String,
      locationCode: String,
      enabled: Boolean,
    }),
    Null,
    Undefined,
  ),
  socialLinks: Array(
    Record({
      value: String,
      type: Union(
        Literal("FACEBOOK"),
        Literal("INSTAGRAM"),
        Literal("TWITTER"),
        Literal("LINKEDIN"),
        Literal("EMAIL"),
        Literal("PHONE"),
        Literal("OTHER"),
      ),
    }),
  ),
  buildingInfo: Record({
    showSiteName: Boolean,
    title: String,
    categories: Array(
      Record({
        uuid: String,
        name: String,
        slug: String,
      }),
    ),
  }).And(
    Partial({
      heroImage: Union(String, Null),
    }),
  ),
  loggedOutHome: Partial({
    heading: Union(String, Null),
    subHeading: Union(String, Null),
    heroImage: Union(String, Null),
  }),
  domains: Array(
    Record({
      host: String,
      primary: Boolean,
      redirect: Boolean,
    }),
  ),
  domainAliases: Array(String),
  showLogoOnWhiteBackground: Boolean,
  store: Record({
    enabled: Boolean,
    navEnabled: Boolean,
    title: String,
  }),
  featureModules: Record({
    bookings: FeatureModuleFeatureType,
    visitors: FeatureModuleFeatureType,
    requests: FeatureModuleFeatureType,
  }),
}).And(
  Partial({
    gatewayEndpoint: Union(String, Null),
    webSocketEndpoint: Union(String, Null),
    logo: Union(String, Null),
    logoSize: Union(String, Null),
    footerLogo: Union(String, Null),
    liveChatPk: Union(String, Null),
    equiemOneHost: Union(String, Null),
  }),
);

export type SiteContextInput = Static<typeof SiteContextInput>;

export const SiteContext = SiteContextInput.And(
  Record({
    registrationEnabled: Boolean,
  }),
);

export type SiteContext = Static<typeof SiteContext>;

export const defaultSite: SiteContext = {
  uuid: "",
  name: "Site Not Found",
  primaryColour: "#000",
  primaryContrastColour: "#FFF",
  whiteContrastColour: "#000",
  primaryColourIsDark: true,
  siteUrl: "",
  equiemOneHost: "",
  timezone: "Australia/Melbourne",
  defaultLoginRealm: "equiem",
  registrationEnabled: true,
  reportingEnabled: true,
  favicon: "",
  email: "",
  taxRegion: {
    currency: {
      code: "AUD",
    },
  },
  buildingInfo: {
    title: "",
    showSiteName: true,
    categories: [],
  },
  loggedOutHome: {
    heading: "",
    subHeading: "",
  },
  loggedInHome: {
    showSiteName: true,
  },
  androidAppId: null,
  iosAppId: null,
  address: "None",
  socialLinks: [],
  accountAppUrl: "",
  domains: [],
  domainAliases: [],
  showLogoOnWhiteBackground: false,
  store: {
    enabled: false,
    navEnabled: false,
    title: "Store",
  },
  transitScreen: null,
  featureModules: {
    bookings: {
      enabled: false,
      enableMenu: false,
    },
    visitors: {
      enabled: false,
      enableMenu: false,
    },
    requests: {
      enabled: false,
      enableMenu: false,
    },
  },
};

export const inputToSiteContext = (input: SiteContextInput): SiteContext => ({
  ...input,
  registrationEnabled:
    input.defaultLoginRealm === process.env.AUTH0_DEFAULT_CONNECTION,
});

export const Site = React.createContext<SiteContext>(defaultSite);

export const getCurrencyCode = async (
  siteUuid: string,
  client: ApolloClient<any> | null = null,
  defaultCode = "AUD",
) => {
  if (client == null) {
    return defaultCode;
  }

  const result = await client.query<
    Maybe<TaxRegionQuery>,
    TaxRegionQueryVariables
  >({
    query: TaxRegionDocument,
    variables: { uuid: siteUuid },
    fetchPolicy: "network-only",
  });

  return stringNotEmpty(result.data?.destination.taxRegion?.currency.code)
    ? result.data!.destination.taxRegion!.currency.code
    : defaultCode;
};

export const transformSite = async (
  site: NonNullable<SiteSettingsQuery["destinationByUrl"]>,
): Promise<SiteContext> => {
  const client =
    site.gatewayEndpoint != null ? regionalGateway(site.gatewayEndpoint) : null;
  const code = await getCurrencyCode(site.uuid, client);
  const domains =
    site.infrastructure?.webDomains.map(({ domain, primary, redirect }) => ({
      host: domain.hostname,
      primary,
      redirect,
    })) ?? [];
  const getFeature = (type: FeatureModuleType) => {
    const feature = site.featureModules?.find((fm) => fm.type === type);

    return {
      enabled: feature != null,
      url: feature?.url ?? undefined,
      label: feature?.label,
      enableMenu: feature?.enableMenu ?? undefined,
    };
  };

  return inputToSiteContext({
    uuid: site.uuid,
    name: site.name,
    accountAppUrl: site.accountAppUrl ?? "",
    primaryColour: site.settings.branding.primaryColour,
    primaryColourIsDark: site.settings.branding.primaryColourIsDark,
    primaryContrastColour: site.settings.branding.primaryContrastColour,
    whiteContrastColour: site.settings.branding.primaryWhiteContrastColour,
    email: site.email ?? null,
    gatewayEndpoint: site.gatewayEndpoint,
    webSocketEndpoint: site.webSocketEndpoint,
    logo: site.settings.branding.web.siteLogoImage,
    logoSize: site.settings.branding.web.siteLogoImageSize,
    showLogoOnWhiteBackground:
      site.settings.branding.web.showLogoOnWhiteBackground,
    favicon: stringNotEmpty(site.settings.branding.web.browserIconImage)
      ? site.settings.branding.web.browserIconImage
      : "/favicon.ico",
    footerLogo: site.settings.branding.web.footerLogoImage,
    timezone: site.timezone,
    defaultLoginRealm: site.defaultLoginRealm,
    reportingEnabled: site.reportingEnabled,
    siteUrl: site.webAppUrl ?? "",
    androidAppId: site.androidAppId,
    iosAppId: site.iosAppId,
    address: site.address ?? "",
    socialLinks: site.socialLinks.filter((x) => stringNotEmpty(x.value)),
    taxRegion: { currency: { code } },
    loggedInHome: {
      heroImage: site.settings.branding.web.homePageHeaderImage,
      showSiteName: site.settings.branding.web.textOverHomePageImage,
    },
    buildingInfo: {
      title: stringNotEmpty(site.settings.buildingInfo.title)
        ? site.settings.buildingInfo.title
        : "My Building",
      categories: site.settings.buildingInfo.categories,
      heroImage: site.settings.branding.web.buildingPageHeaderImage,
      showSiteName: site.settings.branding.web.textOverBuildingPageImage,
    },
    loggedOutHome: {
      heading: site.settings.branding.web.welcomePageHeading,
      subHeading: site.settings.branding.web.welcomePageSubHeading,
      heroImage: site.settings.branding.web.welcomePageImage,
    },
    liveChatPk: site.settings.liveChatPK
      ?.trim()
      .match(/https\:\/\/(embed\.)?tawk.to\/chat\/(.+)/)?.[2],
    domains,
    store: {
      enabled: site.settings.store.enabled,
      navEnabled: site.settings.store.enabled && site.settings.store.webEnabled,
      title: site.settings.store.title ?? "Store",
    },
    transitScreen: site.transitScreenV2,
    domainAliases: [
      ...domains.map((d) => d.host),
      site.infrastructure?.referenceDomain.hostname ?? "",
    ],
    featureModules: {
      bookings: getFeature(FeatureModuleType.Bookings),
      visitors: getFeature(FeatureModuleType.Visitors),
      requests: getFeature(FeatureModuleType.Requests),
    },
    equiemOneHost: site.infrastructure?.equiemOneDomain?.hostname,
  });
};
