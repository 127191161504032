import { useApolloClient } from "@apollo/client";
import { PushNotificationPayloads } from "@equiem/user-pubsub";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useOrderStatusHandler = () => {
  const router = useRouter();
  const { cache } = useApolloClient();

  return useMemo(() => ({
    onClick: async (data: Partial<PushNotificationPayloads.Data.OrderStatusData>) => {
      await router.push(`/order/${data.order}/status`);
    },
    onMessage: async (_data: Partial<PushNotificationPayloads.Data.OrderStatusData>) => {
      cache.evict({ fieldName: "activityFeedV2", broadcast: true });
      cache.evict({ fieldName: "customerOrders", broadcast: true });
      cache.gc();
    },
  }), [cache, router]);
};
