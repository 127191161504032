import React from "react";
import { AiOutlineTag } from "react-icons/ai";
import { ImageTag } from "./ImageTag";

interface Props {
  top?: string;
  left?: string;
}

export const DealTag: React.FC<Props> = ({ top = "0", left = "0" }) => (
  <ImageTag top={top} left={left}>
    <AiOutlineTag size="30px" />
  </ImageTag>
);
