import type { FC } from "react";
import { useContext, useEffect } from "react";
import { Button } from "@equiem/react-end-user-ui";
import { ChatContext } from "./ChatContext";
import { Boop } from "./Boop";
import { RiCloseLine, RiRobot3Line } from "@equiem/react-admin-ui/icons";
import { animated, useSpring } from "@react-spring/web";

interface Props {
  showWhenOpen: boolean;
}

const AnimatedIcon: FC<Props> = ({ children, showWhenOpen }) => {
  const { open } = useContext(ChatContext);
  const [springs, api] = useSpring(() => ({
    from: { opacity: 1 },
  }));

  const start = showWhenOpen ? 1 : 0;
  const end = showWhenOpen ? 0 : 1;

  useEffect(() => {
    let from = { opacity: start, scale: start };
    let to = { opacity: end, scale: end };
    const duration = 200;

    if (open) {
      from = { opacity: end, scale: end };
      to = { opacity: start, scale: start };
    }

    (async () => {
      await Promise.all(
        api.start({
          from,
          to,
          config: {
            duration,
            tension: 300,
            friction: 10,
          },
        }),
      ).catch(console.error);
    })().catch(console.error);
  }, [open, api, start, end]);

  return (
    <animated.span
      style={{
        position: "absolute",
        ...springs,
      }}
    >
      {children}
    </animated.span>
  );
};

export const ChatBotButton = () => {
  const { open, setOpen } = useContext(ChatContext);

  return (
    <>
      <div className="buttonWindow">
        <Boop rotation={30} scale={1.1}>
          <Button
            round
            className="chatBotButton"
            style={{ width: 56, height: 56, display: "flex", justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              void setOpen(!open, open);
            }}
          >
            <AnimatedIcon showWhenOpen={false}>
              <RiRobot3Line size={24} />
            </AnimatedIcon>
            <AnimatedIcon showWhenOpen={true}>
              <RiCloseLine size={24} />
            </AnimatedIcon>
          </Button>
        </Boop>
      </div>
      <style jsx>{`
        .buttonWindow {
          display: flex;
          justify-content: flex-end;
          margin: 20px;
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 2000000002 !important; /* needs to be larger than the header. */
        }
      `}</style>
    </>
  );
};
