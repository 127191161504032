import { Theme } from "@equiem/react-end-user-ui";

import { ChatBotInner } from "./ChatBotInner";
import { ChatProvider } from "./ChatContext";
import { useContext, type FC } from "react";
import { useShowAi } from "../hooks/useShowAi";
import { Viewer } from "@equiem/web-ng-lib";

interface Props {
  primaryColor: string;
  fullPage?: boolean;
}

export const ChatBot: FC<Props> = ({ primaryColor, fullPage = false }) => {
  const viewer = useContext(Viewer);
  const shouldShow = useShowAi(viewer?.profile?.email);

  if (!shouldShow) {
    return <></>;
  }

  return (
    <Theme primaryColor={primaryColor}>
      <ChatProvider userName={viewer?.profile?.name}>
        <ChatBotInner fullPage={fullPage} />
      </ChatProvider>
    </Theme>
  );
};
