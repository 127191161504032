import { FunctionReturnType } from "../components/feedback/FeedbackTypes";
import { useLocalStorage } from "./useLocalStorage";
import { useTimeout } from "./useTimeout";

const FEEDBACK_INIT_DATE_TIME = "feedbackInitDateTime::";

export const useFeedbackTimer = () => {

  const localStore = useLocalStorage();
  const init = (fn: FunctionReturnType, seconds: number) => {
    useTimeout(fn, seconds);
  }

  const clearDisplayTimer = () => {
    localStore.removeItem(FEEDBACK_INIT_DATE_TIME);
  };

  return {
    init,
    clearDisplayTimer,
  }
};
