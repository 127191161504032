import { useTranslation, Viewer } from "@equiem/web-ng-lib";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementLocale, StripeElementsOptions } from "@stripe/stripe-js";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

export interface StripeContext {
  stripe: Promise<Stripe | null> | null;
}

export const StripeContext = createContext<StripeContext>({
  stripe: null,
});

let stripeCache: Promise<Stripe | null> | null = null;
let optionsCache: StripeElementsOptions | undefined;

export const StripeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();

  const viewer = useContext(Viewer);
  const [stripe, setStripe] = useState(stripeCache);

  useEffect(() => {
    if (stripeCache == null && viewer?.stripePk != null) {
      setStripe(stripeCache = loadStripe(viewer.stripePk));
    }

    return () => {
      setStripe(null);
    };
  }, [viewer?.stripePk]);

  const options = useMemo(() => {
    return (
      optionsCache ?? {
        locale: i18n.language as StripeElementLocale,
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&display=swap",
          },
        ],
      }
    );
  }, [i18n.language]);

  return (
    <Elements stripe={stripe} options={options}>{ children }</Elements>
  );
}
