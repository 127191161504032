import { useTranslation } from "@equiem/web-ng-lib";
import { Button, stringNotEmpty } from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext, useEffect, useMemo } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Item } from "../../contexts/CartInterfaces";
import { Cart } from "../../contexts/CartProvider";
import { ModType, ProductVisibility, useCartProductQuery } from "../../generated/gateway-client";
import { PriceFormat } from "../PriceFormat";
import { useCartSubTotalCalculator } from "./checkout/hooks/useCartSubTotalCalculator";
import { UserCartItemSkeleton } from "./UserCartItemSkeleton";

interface Props {
  item: Extract<Item, { type: "PLAIN" }> | Extract<Item, { type: "SUBSCRIPTION" }>;
  setInvalid: (id: string) => void;
  finishLoading: (id: string) => void;
}

export const UserCartItem: React.FC<Props> = ({ item, setInvalid, finishLoading }) => {
  const { t } = useTranslation();

  const removeValue = t("main.remove");
  const selection = [removeValue, ...(Array(100).fill(null).map((_, i) => i + 1))];

  const { updateQuantity, updateSubtotal } = useContext(Cart);

  const { data, loading } = useCartProductQuery({
    variables: { uuid: item.uuid },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const product = data?.product;
  const { subtotal } = useCartSubTotalCalculator(item, product);

  useEffect(() => {
    if (loading) {
      return;
    }

    finishLoading(item.id);

    if (product == null) {
      return;
    }

    updateSubtotal(item.id, subtotal);
  }, [loading, product, subtotal]);

  const modChoiceValid = useMemo(() => {
    if (product == null) {
      return false;
    }

    // When no modchoices and we have still some.
    const emptyModChoices = product.mods == null || product.mods.length === 0;
    if (emptyModChoices && item.selected.modChoices.length > 0) {
      return false;
    }

    // Nothing to validate.
    if (emptyModChoices) {
      return true;
    }

    // When there is mandatory mods and nothing selected.
    const mandatoryMods = product.mods.flatMap((m) => m.mandatory ? [m.uuid] : []);
    if (mandatoryMods.length > 0 && item.selected.modChoices.length === 0) {
      return false;
    }

    const choiceMissing = item.selected.modChoices.some((selected) => {
      const mod = product.mods.find((m) => m.uuid === selected.modUuid);
      if (mod == null) {
        return true;
      }

      if (mod.type === ModType.FreeText) {
        return false;
      }

      const missing = selected.modChoiceUuids.some((uuid) => {
        const modChoice = (mod.modChoices ?? []).find((mc) => mc.choice?.uuid === uuid);

        return modChoice == null || !stringNotEmpty(modChoice.choice?.name);
      });

      return missing;
    });

    return !choiceMissing;
  }, [product, item]);

  const prodNotAvailable = useMemo(() => product == null || !product.available || product.visibility === ProductVisibility.Hidden, [product]);

  const invalid = product == null || product.outOfStock || prodNotAvailable || !product.vendor.open || !modChoiceValid;

  useEffect(() => {
    if (!loading && invalid) {
      setInvalid(item.id);
    }
  }, [loading, invalid])

  if (loading) {
    return <Row><Col><UserCartItemSkeleton /></Col></Row>;
  }
  if (product == null) {
    return null;
  }

  return (
    <>
      <Row className={`pb-3 cart-item ${invalid ? "invalid" : ""}`} key={item.id}>
        {item.type === "PLAIN" ? (
          <Col md="2" className="mb-2 mb-md-0">
            <Form.Control
              as="select"
              custom
              defaultValue={item.quantity}
              onChange={(event) => {
                if (event.target.value != null) {
                  updateQuantity(item.id, event.target.value === removeValue ? 0 : +event.target.value);
                }
              }}
            >
              {selection.map((val) => (
                <option value={val} key={val}>
                  {val}
                </option>
              ))}
            </Form.Control>
          </Col>
        ) : (
            <Col md="2">
              <Button variant="solid" onClick={() => {
                updateQuantity(item.id, 0);
              }}>{removeValue}</Button>
            </Col>
          )}
        <Col>
          <div className="description">
            <Link href="/store/product/[uuid]" as={`/store/product/${product.uuid}`} passHref>
              <a className="font-weight-bold">{product.name ?? ""}</a>
            </Link>
            {
              item.selected.modChoices.map((selected) => {
                const mod = product.mods.find((m) => m.uuid === selected.modUuid);
                if (mod == null) {
                  return null;
                }

                if (mod.type === ModType.FreeText) {
                  return stringNotEmpty(selected.value) ? (
                    <div className="cart-item-mods" key={`cart-user-selected-${selected.modUuid}`}>
                      <div>{mod.name}: {selected.value}</div>
                    </div>
                  ) : null;
                }

                const value = selected.modChoiceUuids.flatMap((uuid) => {
                  const modChoice = (mod.modChoices ?? []).find((mc) => mc.choice?.uuid === uuid);

                  return modChoice == null || !stringNotEmpty(modChoice.choice?.name) ? [] : [modChoice.choice?.name];
                });

                return value.length > 0 ? (
                  <div className="cart-item-mods" key={`cart-user-selected-${selected.modUuid}`}>
                    <div>{mod.name}: {value.join(", ")}</div>
                  </div>
                ) : null;
              })
            }
          </div>
          {product.outOfStock ? (<Alert variant="warning">{t("main.productCurrentlyNotAvailable")}</Alert>) : null}
          {prodNotAvailable ? (<Alert variant="warning">{t("main.productNoLongerAvailable")}</Alert>) : null}
          {!product.vendor.open ? (<Alert variant="warning">{t("main.vendorCurrentlyClosed")}</Alert>) : null}
          {!modChoiceValid ? (<Alert variant="warning">{t("main.oneOfChoicesCurrentlyNotAvailable")} <br/> {t("main.clearCartAndTryAgain")}</Alert>) : null}
        </Col>
        <Col md="2" className="text-right price">
          <PriceFormat
            price={subtotal * item.quantity}
            billingCycle={product.__typename === "SubscriptionProduct" ? product.billingCycle : undefined}
            freeTxt={t("main.free")}
          />
        </Col>
      </Row>
      <style jsx global>{`
        .cart-item.invalid .description, .cart-item.invalid .price {
          text-decoration: line-through;
          opacity: 0.6;
        }
      `}</style>
    </>
  );
}
