import { MobileView, Session, Site, stringNotEmpty, Theme, Viewer } from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext } from "react";
import { Cookie } from "../contexts/CookieContext";
import { MainSubmenu } from "../contexts/MainSubmenuProvider";
import { Personalized } from "../contexts/Personalized";
import { SearchContext } from "../contexts/SearchContext";
import { useLogoBackground } from "../hooks/useLogoBackground";
import { Search } from "./icons/Search";
import { LocalePickerIcon } from "./LocalePickerIcon";
import { NotificationBell } from "./notifications/NotificationBell";
import { SearchModal } from "./search/SearchModal";
import { Burger } from "./sidemenu/Burger";
import { SideMenu } from "./sidemenu/SideMenu";
import { SiteNavbarCart } from "./SiteNavbarCart";
import { SiteNavbarIconLink } from "./SiteNavbarIconLink";

interface Props {
  hideBurgerMenu?: boolean;
}

export const SiteNavbar: React.FC<Props> = ({ hideBurgerMenu = false }) => {
  const search = useContext(SearchContext);
  const { inMobileView } = useContext(MobileView)
  const site = useContext(Site);
  const session = useContext(Session);
  const cookie = useContext(Cookie);
  const subnavs = useContext(MainSubmenu);
  const { whiteBg, bgColor, fgColor } = useLogoBackground();
  const { breakpoints } = useContext(Theme);

  return inMobileView ? null : (
    <div className="site-nav-cont">
      <div className={`site-nav d-flex align-items-center ${whiteBg ? " border-bottom shadow-sm" : ""}`}>
        {stringNotEmpty(site.logo) ? (
          <Link href="/" passHref>
            <a className="logo-container d-block h-100">
              <img src={site.logo} />
            </a>
          </Link>
        ) : null}
        <div className="d-flex justify-content-end align-items-center ml-auto">
          <Personalized>
            <Viewer.Consumer>{(viewer) => !session.authenticated || !cookie.hasConsented || viewer?.termsAccepted !== true ? null : (
              <>
                <SiteNavbarIconLink
                  className="search"
                  tabIndex={0}
                  onClick={(e) => {
                    e.preventDefault();
                    search.show();
                  }}>
                  <Search size={18} />
                </SiteNavbarIconLink>
                <LocalePickerIcon className="ml-2 ml-md-3" />
                <NotificationBell className="ml-2 ml-md-3" />
              </>
            )}</Viewer.Consumer>
            {search.isShown ? <SearchModal key="search" /> : null}
          </Personalized>
          { hideBurgerMenu ? null : <Burger className="open-side-menu ml-2 ml-md-3" /> }
        </div>
      </div>
      <SiteNavbarCart />
      { subnavs.submenus.map((x) => <React.Fragment key={x.id}>{x.el}</React.Fragment>) }
      <SideMenu />
      <style jsx>{`
        .site-nav-cont {
          position: sticky;
          top: 0;
          // Should cover the chat icon, number is like in plutoland!
          z-index: 2000000001 !important;
        }
        .site-nav {
          background: ${bgColor};
          color: ${fgColor} !important;
          padding: 8px;
        }
        .site-nav :global(a) {
          color: inherit;
        }
        @media (min-width: ${breakpoints.md}px) {
          .site-nav {
            padding: 8px 32px;
            min-height: 72px;
          }
        }
      `}</style>
    </div>
  );
}
