import { Site, stringNotEmpty } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { VendorExternalLabel } from "../../store/VendorExternalLabel";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  vendor: SearchNode<"VendorContentEdge" | "DealVendorContentEdge">;
}

export const VendorSearchResult: React.FC<Props> = ({ vendor }) => {
  const site = useContext(Site);

  const thirdParty = stringNotEmpty(vendor.thirdPartyUrl);
  const style = { opacity: ".6" };
  const body = (
    <>
      <div className="pb-1" style={style}>{vendor.excerpt}</div>
      {thirdParty ? <VendorExternalLabel /> : null}
    </>
  );
  const footer = (
    <span style={style}>{site.store.title}</span>
  );

  return (
    <SearchResult
      noOpacity
      href={thirdParty ? vendor.thirdPartyUrl! : "/store/vendor/[uuid]"}
      as={thirdParty ? undefined : `/store/vendor/${vendor.uuid}`}
      target={thirdParty ? "_blank" : ""}
      title={vendor.name}
      body={body}
      imageUrl={vendor.image ?? undefined}
      footer={footer}
    />
  );
}
