import { Site } from "@equiem/web-ng-lib";
import moment from "moment";
import { useContext } from "react";
import { useFeedbackContext } from "../contexts/FeedbackContext";
import {
  SiteFeedbackDocument,
  useDismissFeedbackMutation,
  useUpsertFeedbackQuestionResponseMutation,
} from "../generated/gateway-client";

export const useFeedbackSubmission = () => {

  const site = useContext(Site);
  const context = useFeedbackContext();
  const [mutation] = useUpsertFeedbackQuestionResponseMutation();
  const [mutation2] = useDismissFeedbackMutation();

  const submitFeedback = async (score: number, dismissed = false) => {
    const hundredYearsInMonths = 100 * 12;
    const monthsToAdd = context.question?.repeatDurationMonths === 0 ?
      hundredYearsInMonths : context.question?.repeatDurationMonths;
    const result = await mutation({
      variables: {
        input: {
          siteUuid: site.uuid,
          feedbackUuid: context.question!.uuid,
          metricTargetType: context.question!.metricTargetType,
          response: score,
          dismissed,
          responseExpiryDate: moment(moment.now()).add(monthsToAdd, "months").valueOf(),
          uuid: context.response.uuid ?? null,
          comment: context.response.comment ?? null,
          metricTargetDescription: context.question?.title!,
        },
      },
    });

    if (result.data?.upsertFeedbackQuestionResponse.uuid != null) {
      context.submitResponse(result.data?.upsertFeedbackQuestionResponse.uuid);
    }
  };

  const dismissFeedback = async () => {
    await mutation2({
      variables: {
        feedbackUuid: context.question!.uuid,
      },
      refetchQueries: [SiteFeedbackDocument],
    });
  }

  return {
    submitFeedback,
    dismissFeedback,
  };
}
