import { Session, SessionContext } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import {
  PageViewPageType,
  useAnonymousPageViewMetricMutation,
  usePageViewMetricMutation,
} from "../../generated/gateway-client";

type Metric = "page_view" | "anonymous_page_view";

const getPageViewMetricCallback = (metric: Metric, session: SessionContext) => {
  switch (metric) {
    case "page_view":
      return usePageViewMetricMutation({
        client:
          session.authenticated && session.side === "client"
            ? session.authenticatedClient
            : undefined,
      });
    case "anonymous_page_view":
      return useAnonymousPageViewMetricMutation();
  }
};

interface Props {
  pageViewType: PageViewPageType;
  pageTitle?: string;
  categories?: string[];
  metric?: Metric;
  crossReference?: string;
}

export const CortexPageViewMetric: React.FC<Props> = ({
  pageViewType,
  pageTitle,
  categories,
  metric = "page_view",
  crossReference,
}) => {
  const router = useRouter();
  const session = useContext(Session);
  const site = useContext(Site);

  const [pageViewMetricCallback] = getPageViewMetricCallback(metric, session);

  useEffect(() => {
    if (
      session.side !== "client" ||
      /\bnewrelic-monitor=true\b/.test(window.location.search)
    ) {
      return;
    }

    setTimeout(() => {
      const title = pageTitle ?? document.title;
      const pageUrl = window.location.href;

      pageViewMetricCallback({
        variables: {
          pageViewInput: {
            timestamp: new Date().getTime(),
            siteUuid: site.uuid,
            pageTitle: title,
            pageUrl,
            pageType: pageViewType,
            categories,
            crossReference,
          },
        },
      }).catch((e: any) => {
        console.log(`${metric} metric send failed`, e);
      });
    });
  }, [router.asPath]);

  return <></>;
};
