import { useShowError } from "@equiem/web-ng-lib";
import { useCallback, useContext } from "react";
import { BrowserNotifications } from "../contexts/BrowserNotifications";
import { PushNotificationMessage } from "./usePushNotifications";

export const useShowBrowserNotification = ({ onClick }: {
  onClick: (message: PushNotificationMessage) => void;
}) => {
  const browserNotifications = useContext(BrowserNotifications);
  const showError = useShowError();

  return useCallback((message: PushNotificationMessage) => {
    if (browserNotifications.enabled && message.notification?.title != null) {
      try {
        const browserNotification = new Notification(message.notification.title, {
          body: message.notification.body ?? undefined,
          tag: message.notification.tag ?? undefined,
          icon: message.notification.webIcon ?? undefined,
        });

        browserNotification.addEventListener("click", () => {
          window.focus();
          onClick(message);
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }, [showError, onClick, browserNotifications.enabled]);
};
