import React, { useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineBell } from "react-icons/ai";
import { PushNotificationsContext } from "../../contexts/PushNotificationsContext";
import { SideMenuContext } from "../../contexts/SideMenuProvider";
import { useLogoBackground } from "../../hooks/useLogoBackground";
import { usePushNotifications } from "../../hooks/usePushNotifications";
import { SiteNavbarIconLink } from "../SiteNavbarIconLink";

interface Props {
  className?: string;
}

let lastTotalCountCache: number = 0;

export const NotificationBell: React.FC<Props> = ({ className = "" }) => {
  const sideMenu = useContext(SideMenuContext);
  const { connected } = useContext(PushNotificationsContext);
  const { totalCount } = usePushNotifications();
  const [ring, setRing] = useState(false);
  const [lastTotalCount, setLastTotalCount] = useState(lastTotalCountCache);
  const count = useMemo(() => totalCount ?? 0, [totalCount]);
  const { bgColor, fgColor } = useLogoBackground();

  useEffect(() => {
    if (totalCount == null) {
      return;
    }

    if (totalCount <= lastTotalCount) {
      setLastTotalCount(totalCount);

      return;
    }

    setLastTotalCount(totalCount);
    setRing(true);

    const timer = setTimeout(() => {
      setRing(false);
    }, 3500);

    return () => {
      clearTimeout(timer);
    };
  }, [totalCount]);

  useEffect(() => {
    lastTotalCountCache = lastTotalCount;
  }, [lastTotalCount]);

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          sideMenu.openAndShowDetails("notification");
        }
      }}
      className={`notification-bell d-inline-flex justify-content-center align-items-center ${connected ? "connected" : ""} ${className}`} tabIndex={0}>
      <SiteNavbarIconLink className="widget" disabled={!connected} onClick={() => {
        sideMenu.openAndShowDetails("notification");
      }}>
        <span className={`icon ${ring ? "ring" : ""}`}><AiOutlineBell size={22} /></span>
        { count > 0 ? <span className="count">{count < 100 ? count : "99+"}</span> : null }
      </SiteNavbarIconLink>
      <style jsx>{`
        .notification-bell {
          user-select: none;
          position: relative;
        }
        .count {
          position: absolute;
          top: 0;
          right: ${count < 100 ? "-.3" : "-.5"}rem;
          border-radius: ${count < 100 ? "50%" : ".3rem"};
          background: ${fgColor};
          color: ${bgColor};
          font-size: .75rem;
          font-weight: 600;
          width: ${count < 100 ? "1.1" : "1.4"}rem;
          height: 1.1rem;
          text-align: center;
          line-height: 1.25rem;
          display: inline-block;
        }
        .ring :global(svg) {
          animation: ring 1.5s ease-in-out 2;
        }
        @keyframes ring {
          0%   { transform: rotate(0) scale(1.2); }
          10%  { transform: rotate(25deg); }
          20%  { transform: rotate(-25deg); }
          30%  { transform: rotate(15deg); }
          40%  { transform: rotate(-15deg); }
          50%  { transform: rotate(5deg); }
          60%  { transform: rotate(-5deg); }
          70%  { transform: rotate(0) scale(1); }
          100% { transform: rotate(0); }
        }
      `}</style>
    </div>
  );
};
