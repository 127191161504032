import React from "react";

export const PartyPopper: React.FC<{ size?: number }> = ({ size = 85 }) => (
  <svg width={size} height={size} viewBox="0 0 85 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.4682 0L45.2044 4.26382L51.5211 10.5806C52.3897 11.5676 52.824 12.7125 52.824 14.0153C52.824 15.3182 52.3897 16.542 51.5211 17.4106L37.5058 31.6628L41.4538 35.9266L55.7849 21.6744C57.8774 19.3451 58.9039 16.7789 58.9039 14.0153C58.9039 11.2517 57.8774 8.64608 55.7849 6.31677L49.4682 0ZM33.7552 7.97492L29.4914 12.2387L31.8997 14.4496C32.7682 15.3182 33.2025 16.5026 33.2025 17.9633C33.2025 19.4241 32.7682 20.6085 31.8997 21.477L29.4914 23.6879L33.7552 27.9517L35.9661 25.5434C38.0585 23.2141 39.1245 20.6874 39.1245 17.9633C39.1245 15.1208 38.0585 12.5546 35.9661 10.1858L33.7552 7.97492ZM75.0116 14.2522C72.2875 14.2522 69.7608 15.3182 67.4315 17.4106L45.2044 39.6772L49.4682 43.6252L71.4979 21.6744C72.4849 20.6874 73.6693 20.1742 75.0116 20.1742C76.3539 20.1742 77.5383 20.6874 78.5253 21.6744L80.9336 24.0827L85 19.8189L82.7891 17.4106C80.4598 15.3182 77.8542 14.2522 75.0116 14.2522ZM19.7399 25.8593L0 81.131L55.2717 61.3911L19.7399 25.8593ZM67.1157 37.9401C64.3521 37.9401 61.8254 39.006 59.4566 41.0985L53.1793 47.3758L57.4431 51.6396L63.7204 45.3623C64.7074 44.3753 65.8128 43.8621 67.1157 43.8621C68.4185 43.8621 69.6029 44.3753 70.5899 45.3623L76.9856 51.6396L81.131 47.5732L74.8142 41.0985C72.4849 39.006 69.8792 37.9401 67.1157 37.9401Z"
      strokeWidth="0"
    />
  </svg>
);
