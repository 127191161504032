import { Site, stringNotEmpty } from "@equiem/web-ng-lib";
import React, { Fragment, useContext } from "react";

export const Tawk: React.FC = () => {
  const { liveChatPk } = useContext(Site);

  return stringNotEmpty(liveChatPk) ? (
    <Fragment key={liveChatPk}>
      <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/${liveChatPk}';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();` }} />
    </Fragment>
  ) : null;
};
