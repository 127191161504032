import React from "react";

interface Props {
  width: string;
  height?: string;
  className?: string;
  dark?: boolean;
  borderRadius?: string;
}

export const SkeletonLine: React.FC<Props> = ({ width, height = "0.8rem", className = "", dark = false, borderRadius }) => (
  <span className={`shimmer d-inline-block mw-100 ${dark ? "dark" : ""} ${className}`} style={{
    width,
    height,
    borderRadius: borderRadius ?? undefined,
  }}></span>
);
