export const useLocalStorage = () => {
  const localStorageExists = () => typeof localStorage !== "undefined";
  const setItem = (key: string, value: string): void => {
    if (localStorageExists()) {
      localStorage.setItem(key, value);
    }
  };

  const getItem = (key: string) => {
    if (localStorageExists()) {
      return localStorage.getItem(key);
    }

    return null;
  };

  const removeItem = (key: string): void => {
    if (localStorageExists()) {
      localStorage.removeItem(key);
    }
  };

  return {
    localStorageExists,
    setItem,
    getItem,
    removeItem,
  }
};
