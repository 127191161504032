import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { DealTag } from "../../store/products/DealTag";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  product: SearchNode<"DealContentEdge">;
}

export const DealProductSearchResult: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  const description = (
    <>
      <div>{product.vendor.name}</div>
      <div>{product.excerpt}</div>
    </>
  );

  return (
    <SearchResult
      href="/store/deal/[uuid]"
      as={`/store/deal/${product.uuid}`}
      title={product.name}
      body={description}
      imageUrl={product.image ?? undefined}
      imageSuffix={product.showOverlay ? <DealTag /> : undefined}
      footer={product.showOverlay ? t("main.deal") : null}
    />
  );
};
