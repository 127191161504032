import Link from "next/link";
import React, { MouseEventHandler, useContext } from "react";
import { Theme } from "../contexts/ThemeContext";

interface Props {
  href?: string;
  as?: string;
  target?: React.HTMLAttributeAnchorTarget;
  focusable?: boolean
  className?: string;
  orientation?: "portrait" | "landscape";
  onClick?: MouseEventHandler;
  // Only applies in landscape orientation.
  lineClamp?: number;
}

export const EngulfingLink: React.FC<Props> = ({
  children,
  href,
  as,
  target,
  focusable = true,
  className = "",
  orientation = "portrait",
  lineClamp = 1,
  onClick = () => undefined,
}) => {
  const { breakpoints } = useContext(Theme);
  const anchorProps = { onClick, className: `${className} ${orientation}`, tabIndex: !focusable ? -1 : 0 };

  return (
    <>
      {href != null ? (
        <Link href={href} as={as} passHref><a target={target} {...anchorProps}>{children}</a></Link>
      ) : (
        <a {...anchorProps} target={target}>{children}</a>
      )}
      <style jsx>{`
      a {
        cursor: pointer;
      }
      a:focus {
        outline: none;
      }
      a:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      @media (min-width: ${breakpoints.sm}px) {
        a.landscape {
          width: 100%;
          height: 100%;
          max-height: ${lineClamp * 1.45}rem;
          display: -webkit-box;
          -webkit-line-clamp: ${lineClamp};
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    `}
      </style>
    </>
  );
};
