import React from "react";
import { Container } from "react-bootstrap";
import { CSSProperties } from "styled-components";
import { Personalized } from "../../contexts/Personalized";
import { Footer } from "../Footer";
import { PageBody, Props as PageBodyProps } from "../PageBody";
import { SiteNavbar } from "../SiteNavbar";

const noMarginStyle: CSSProperties = {
  maxWidth: "100%",
}

interface Props {
  prefix?: JSX.Element;
  personalized?: boolean
  background?: PageBodyProps["background"];
  noMargin?: boolean;
  hideBurgerMenu?: boolean;
  hideFooter?: boolean;
  children?: React.ReactNode;
}

export const GeneralLayout: React.FC<Props> = ({
  children,
  prefix,
  personalized = false,
  background,
  noMargin = false,
  hideBurgerMenu = false,
  hideFooter = false,
}) => {
  const content = (
    <>
      <SiteNavbar hideBurgerMenu={hideBurgerMenu} />
      {prefix}
      <PageBody className={!noMargin ? "p-3" : ""} background={background}>
        <Container fluid="md" style={noMargin ? noMarginStyle : {}} className="p-0">
          {children}
        </Container>
      </PageBody>
      {!hideFooter ? <Footer /> : null}
    </>
  );

  return (
    <>
      {personalized ? (
        <Personalized>{content}</Personalized>
      ) : (
        content
      )}
    </>
  );
}
