import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { Search } from "../icons/Search";

interface Props {
  term: string;
}

export const NoResults: React.FC<Props> = ({ term }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center my-4">
      <div className="icon">
        <Search size={30} />
      </div>
      <p className="message my-4">{t("main.noResults", { term })}</p>
      <style jsx>{`
        .icon {
          opacity: 0.3;
        }
        .message {
          opacity: 0.5;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      `}</style>
    </div>
  );
};
