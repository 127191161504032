import { Session } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import Head from "next/head";
import React, { useContext } from "react";

// @ts-ignore
import js from "!!raw-loader!./script.js";

export const NewRelic: React.FC = () => {
  const site = useContext(Site);
  const session = useContext(Session);

  if (process.env.newrelicEnabled !== "true") {
    return null;
  }

  const origin = site.gatewayEndpoint != null
    ? new URL(site.gatewayEndpoint).origin
    : null;

  const init = {
    distributed_tracing: {
      enabled: true,
      cors_use_newrelic_header: true,
      cors_use_tracecontext_headers: true,
      allowed_origins: [ origin ],
    },
    privacy: {
      cookies_enabled: session.authenticated,
    },
  };

  const loaderConfig = {
    accountID: process.env.newrelicAccountID,
    trustKey: process.env.newrelicTrustKey,
    agentID: process.env.newrelicAgentID,
    licenseKey: process.env.newrelicLicenseKey,
    applicationID: process.env.newrelicApplicationID,
  };

  const info = {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: process.env.newrelicLicenseKey,
    applicationID: process.env.newrelicApplicationID,
    sa: 1,
  };

  return (
    <Head>
      <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
;window.NREUM||(NREUM={});NREUM.init=${JSON.stringify(init)};
${js}
;NREUM.loader_config=${JSON.stringify(loaderConfig)}
;NREUM.info=${JSON.stringify(info)}
    `}}></script>
    </Head>
  );
}
