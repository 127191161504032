import { Dropdown, LocalePickerOptions } from "@equiem/react-end-user-ui";
import {
  debugLanguageOptionList,
  languageOptionList,
} from "@equiem/web-ng-lib";
import React from "react";
import { AiOutlineGlobal } from "react-icons/ai";

import { SiteNavbarIconLink } from "./SiteNavbarIconLink";

export const LocalePickerIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <>
    <div className="locale-picker">
      <SiteNavbarIconLink
        className={`p-0 ${className ?? ""}`}
        tabIndex={0}
        onClick={() => undefined}
      >
        <Dropdown.Icon
          className="trigger"
          placement="bottom-end"
          icon={AiOutlineGlobal}
        >
          <LocalePickerOptions
            languages={languageOptionList}
            debugLanguages={debugLanguageOptionList}
            showSelected
            showDebugLanguages={process.env.showDebugLanguages === "true"}
          />
        </Dropdown.Icon>
      </SiteNavbarIconLink>
    </div>
    <style jsx>{`
      .locale-picker :global(.trigger) {
        width: 100%;
        height: 100%;
        background: none;
        color: inherit;
      }
    `}</style>
  </>
);
