import { useTranslation } from "@equiem/web-ng-lib";
import { Button, Site, Theme, Viewer } from "@equiem/web-ng-lib";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { Personalized } from "../../contexts/Personalized";
import { SideMenuContext } from "../../contexts/SideMenuProvider";
import { NoticationSummary } from "../notifications/NoticationSummary";
import { MenuLinks } from "./MenuLinks";
import { SideMenuOverlay } from "./SideMenuOverlay";

export const SideMenu: React.FC = () => {
  const { t } = useTranslation();

  const sideMenu = useContext(SideMenuContext);
  const site = useContext(Site);
  const { breakpoints } = useContext(Theme);
  const viewer = useContext(Viewer);

  // Prevent a brief flash of the side menu on page navigation, caused by the
  // slide transition playing when the component is re-mounted. We only need to
  // wait one frame for the element to be laid out correctly in its initial
  // position.
  const router = useRouter();
  const [enableAnimations, setEnableAnimations] = useState(false);
  useEffect(() => {
    const id = requestAnimationFrame(() => {
      setEnableAnimations(true);
    });

    return () => {
      cancelAnimationFrame(id);
      setEnableAnimations(false);
    };
  }, [router.asPath]);

  return (
    <>
      <SideMenuOverlay />
      <div className={`site-navbar border-grey ${enableAnimations ? "navbar-slide" : ""} ${sideMenu.isShown ? "open" : ""}`}>
        <div className="wrapper">
          {
            viewer == null ? (
              <div className="d-flex flex-column h-100 nav-content">
                <div className="flex-grow-1"><MenuLinks /></div>
              </div>
            ) : (
              <Personalized>
                {
                  sideMenu.activeDetails === "notification" ? <NoticationSummary /> : sideMenu.activeDetails == null ? (
                    <div className="d-flex flex-column h-100 nav-content">
                      <div className="flex-grow-1"><MenuLinks /></div>
                      <Button
                        variant="outline"
                        href="/api/logout"
                        contrast
                        className="w-100 logout d-flex align-items-center justify-content-center"
                        borderWidth="2px"
                        roundedRadius="24px">
                        {t("main.logOut")}
                      </Button>
                    </div>
                  ) : null
                }
              </Personalized>
            )
          }
        </div>
      </div>
      <style jsx>{`
        .site-navbar {
          position: fixed;
          z-index: ${sideMenu.zIndex.sidemenu};
          top: 0;
          height: 100%;
          right: 0;
          padding: 24px 16px 16px;
          width: 100%;
          border-left: 1px solid #ccc;
          transform: translate(100%, 0);
          background: ${site.primaryColour};
          color: ${site.primaryContrastColour};
        }
        .navbar-slide {
          transition: 0.5s ease-in-out;
        }
        .nav-content {
          overflow-y: auto;
        }
        @media (min-width: ${breakpoints.md}px) {
          .site-navbar {
            width: 420px;
          }
        }
        .site-navbar.open {
          transform: translate(0, 0);
        }
        .wrapper {
          position: relative;
          height: 100%;
        }
        .site-navbar :global(.logout) {
          height: 40px;
          font-size: 14px;
        }
        .site-navbar :global(.logout:hover) {
          background: ${site.primaryContrastColour};
          color: ${site.primaryColour};
        }
        @media (min-width: ${breakpoints.md}px) {
          .site-navbar {
            padding: 32px;
          }
        }
      `}</style>
    </>
  );
}
