import moment, { Moment, unitOfTime } from "moment-timezone";

// Format: left is Luxon, right is moment.
const formatMap = {
  "d LLL y": "D MMM Y",
  "h:mma": "h:mma",
  "H": "H",
  "h:mm a": "h:mm a",
  "d LLL y, h:mma": "D MMM Y, h:mma",
  "ccc d LLL, h:mma": "ddd D MMM, h:mma",
  "ccc d LLL y": "ddd D MMM Y",
  "ccc d LLL y, h:mma": "ddd D MMM Y, h:mma",
  "ccc d LLL": "ddd D MMM",
  "ccc": "ddd",
  "d": "D",
  "d LLL": "D MMM",
  "MMM": "MMM",
  "ccc d MMM": "ddd D MMM",
  "ccc, dd LLLL yyyy": "ddd, DD MMMM YYYY",
  "x": "x",
  "e": "d",
  "LLLL yyyy": "MMMM YYYY",
  "cccc, d LLLL YYYY": "dddd, D MMM Y",
};

interface DateAmount {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

interface DateSet {
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
}

/**
 * Adapter for moment that has same interface as Luxon.
 *
 * To allow easy switch to Luxon in future when browser support requirements permit.
 */
export class DateTime {
  public readonly moment: Moment;

  private constructor(_moment: Moment) {
    this.moment = _moment;
  }

  public static fromMillis(date: number, { zone }: { zone?: string } = {}) {
    return zone != null
      ? new DateTime(moment(date).tz(zone))
      : new DateTime(moment(date));
  }

  public static today() {
    return new DateTime(moment());
  }

  public toFormat(format: keyof typeof formatMap): string {
    return this.moment.format(formatMap[format]);
  }

  public toISO() {
    return this.moment.format();
  }

  public hasSame(asDate: DateTime, granularity: unitOfTime.StartOf) {
    return this.moment.isSame(asDate.moment, granularity);
  }

  public startOf(granularity: unitOfTime.StartOf): DateTime {
    return new DateTime(this.moment.clone().startOf(granularity));
  }

  public endOf(granularity: unitOfTime.StartOf): DateTime {
    return new DateTime(this.moment.clone().endOf(granularity));
  }

  public get daysInMonth() {
    return this.moment.daysInMonth();
  }

  public static weekdays() {
    return moment.weekdaysShort();
  }

  public set({ year, month, day, hour, minute, second }: DateSet): DateTime {
    const newMoment = this.moment.clone();

    if (year != null) {
      newMoment.set("year", year);
    }
    if (month != null) {
      newMoment.set("month", month);
    }
    // There is no longer date in luxon.
    if (day != null) {
      newMoment.set("date", day);
    }
    if (hour != null) {
      newMoment.set("hour", hour);
    }
    if (minute != null) {
      newMoment.set("minute", minute);
    }
    if (second != null) {
      newMoment.set("second", second);
    }

    return new DateTime(newMoment);
  }

  public plus(amount: DateAmount): DateTime {
    return this.addOrSubtract("add", amount);
  }

  public minus(amount: DateAmount): DateTime {
    return this.addOrSubtract("subtract", amount);
  }

  public diff<G extends unitOfTime.Diff>(other: DateTime, granularity: G): {
    [g in G]: number;
  } {
    // tslint:disable-next-line: no-object-literal-type-assertion
    return {
      [granularity]: this.moment.diff(other.moment, granularity, true),
    } as { [g in G]: number };
  }

  public valueOf(): number {
    return this.moment.valueOf();
  }

  protected addOrSubtract(op: "add" | "subtract", { years, months, days, hours, minutes, seconds }: DateAmount): DateTime {
    const newMoment = this.moment.clone();

    if (years != null) {
      newMoment[op](years, "years")
    }

    if (months != null) {
      newMoment[op](months, "months")
    }

    if (days != null) {
      newMoment[op](days, "days")
    }

    if (hours != null) {
      newMoment[op](hours, "hours")
    }

    if (minutes != null) {
      newMoment[op](minutes, "minutes")
    }

    if (seconds != null) {
      newMoment[op](seconds, "seconds")
    }

    return new DateTime(newMoment);
  }
}
