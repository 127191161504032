import { useTranslation } from "@equiem/web-ng-lib";
import { Theme, Viewer } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { FeedbackQuestion } from "../components/feedback/FeedbackTypes";
import { useFeedbackContext } from "../contexts/FeedbackContext";
import { useFeedbackTimer } from "../hooks/useFeedbackTimer";
import { FeedbackActions } from "./feedback/FeedbackActions";
import { FeedbackScoreWidget } from "./feedback/FeedbackScoreWidget";
import { Cross } from "./icons/Cross";

export interface FeedbackProps {
  question: FeedbackQuestion;
}

export const FeedbackTitle: React.FC<{}> = () => {
  const { t } = useTranslation();

  const feedbackContext = useFeedbackContext();
  const viewer = useContext(Viewer);

  const toggle = () => {
    feedbackContext.toggleVisibility("hidden");
    feedbackContext.dismissForever();
  }

  return (
    <>
      <div className="mb-1 nps-title">
        <strong>{feedbackContext.submitted ? t("main.thanks", { name: viewer?.profile?.name ?? "" }) : t("main.hi", { name: viewer?.profile?.name ?? "" })},</strong>
        { feedbackContext.submitted ? <div className="cross-right text-muted" onClick={toggle}><Cross size={12}/></div> : null }
      </div>
      <style jsx global>{`
        .cross-right {
          position: absolute;
          right: 9px;
          top: 4px;
          cursor: pointer;
        }
        .nps-title {
          font-size: 1.0625rem;
        }
      `}
      </style>
    </>
  );
};

export const FeedbackSubject: React.FC<{}> = () => {
  const { t } = useTranslation();

  const feedbackContext = useFeedbackContext();

  return (
    <>
      <p className="mb-3 nps-subject pl-3 pr-3">
      {
        !feedbackContext.submitted ?
        feedbackContext?.question?.title :
        t("main.ratingHasBeenRecordedAs")
      }
      </p>
      <style jsx global>
        {`
          .nps-subject {
            font-size: 0.9375em;
          }
        `}
      </style>
    </>
  );
};

export const FeedbackBox: React.FC<FeedbackProps> = ({ question }) => {
  const timer = useFeedbackTimer();
  const feedbackContext = useFeedbackContext();

  timer.init(
    () => {
      if (!feedbackContext.dismissed) {
        feedbackContext.setFeedbackQuestion(question);
        feedbackContext.toggleVisibility("shown");
      }
    },
    2,
  );

  const { breakpoints } = useContext(Theme);
  if (feedbackContext.visibility === "hidden") {
    return null
  };

  const dismiss = () => {
    feedbackContext.toggleVisibility("hidden");
    timer.clearDisplayTimer();
  }

  return (
    <>
      <div className="feedback-container text-center rounded">
        <FeedbackTitle />
        <FeedbackSubject />
        <FeedbackScoreWidget />
        <FeedbackActions
          onDismiss={() => {
            feedbackContext.dismiss();
            dismiss();
          }}
        />
      </div>
      <style jsx global>{`
        .feedback-container {
          background: #ffffff;
          width: 450px;
          position: fixed;
          bottom: 10px;
          right: 10px;
          color: #000;
          padding: 15px 10px;
          z-index: 100;
          box-shadow: 0 0 10px rgb(0 0 0 / 30%);
          border: 1px solid #ccc;
        }
        .button-container {
          width: 60%;
        }
        @media (max-width: ${breakpoints.md - 1}px) {
          .feedback-container {
            width: 375px;
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            bottom: 0;
            right: 0;
          }
        }
      `}
      </style>
    </>
  )
};
