import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
const sizeFactors = {
  sm: 1,
  md: 2,
  lg: 3,
};

export const scale = (
  val: number,
  size: keyof typeof sizeFactors,
) => val * sizeFactors[size];

export type IconProps<P extends {} = {}> = P & {
  color?: string;
  size?: keyof typeof sizeFactors | number;
};
export type Icon<P extends {} = {}> = React.FC<IconProps<P>>;

export type IconImplProps<P extends {} = {}> = P & {
  color: string;
  size: keyof typeof sizeFactors | number;
  width: number;
  height: number;
};
export type IconImpl<P extends {} = {}> = React.FC<IconImplProps<P>>;

// tslint:disable-next-line: only-arrow-functions
export function withDefaults<P extends {} = {}>(
  { width, height }: { width: number; height: number },
  Component: IconImpl<P>,
): Icon<P> {
  return ({ color, size = "sm", ...props }) => {
    const branding = useContext(Site);
    const w = typeof size === "number" ? size : scale(width, size);
    const h = typeof size === "number" ? size : scale(height, size);

    return (
      <Component
        color={ color ?? branding.whiteContrastColour }
        width={w}
        height={h}
        size={size}
        {...props as P}
      />
    );
  };
};
