import { useTranslation } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { Cart } from "../../contexts/CartProvider";

export const UserCartButton: React.FC = () => {
  const { t } = useTranslation();

  const { itemCount, show, showCartBtn } = useContext(Cart);
  const cartEmpty = itemCount === 0;
  const className = cartEmpty ? "" : "exist";
  const hideClass = showCartBtn ? "d-md-none d-flex" : "d-none";

  return (
    <>
      <div className={`${hideClass} user-cart ${className}`}>
        <button
          type="button"
          className="round-cart text-center d-flex justify-content-center align-items-center px-4"
          onClick={show}
          disabled={cartEmpty}
        >
          <FaShoppingCart size="20px" className="mr-2 d-none d-sm-inline-block" />
          {t("main.shoppingCart")}
          <div className="count px-2 ml-2">{itemCount}</div>
        </button>
      </div>
      <style jsx>{`
        .user-cart {
          transition: right 0.5s;
          position: fixed;
          bottom: 24px;
          right: -200rem;
          width: 100%;
          // 1 above bootstrap $zindex-sticky.
          z-index: 1031;
          pointer-events: none;
          padding-right: 0;
          justify-content: center;
        }
        .user-cart.exist {
          right: 0px;
        }
        .round-cart {
          outline: none;
          box-shadow: 0 4px 10px rgba(0,0,0,0.6);
          font-size: 16px;
          font-weight: 600;
          height: 56px;
          color: #000;
          background: #fff;
          border: none;
          border-radius: 56px;
        }
        .round-cart :global(button) {
          text-decoration: none !important;
        }
        .count {
          background: #FF3737;
          border-radius: 22px;
          color: #fff;
          line-height: 22px;
          padding-top: 2px;
        }
        .user-cart button {
          pointer-events: all;
        }
      `}</style>
    </>
  );
}
