import { Site } from "@equiem/web-ng-lib";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ContentSortType, SearchContentType } from "../generated/gateway-client";

// tslint:disable:no-empty
export const SearchContext = createContext({
  isShown: false,
  term: "",
  types: [] as SearchContentType[],
  sort: ContentSortType.Relevance,
  show: () => {},
  hide: () => {},
  toggle: () => {},
  updateTerm: (_term: string) => {},
  updateTypes: (_types: SearchContentType[]) => {},
  toggleType: (_type: SearchContentType) => {},
  toggleStoreType: () => {},
  typeOn: (_type: SearchContentType): boolean => false,
  allTypesOn: (): boolean => false,
  updateSortType: (_type: ContentSortType) => {},
});
// tslint:enable:no-empty

export const SearchProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const site = useContext(Site);

  const store = [
    SearchContentType.Vendor,
    SearchContentType.DealVendor,
    SearchContentType.PlainProduct,
    SearchContentType.SubscriptionProduct,
    SearchContentType.BookableProduct,
    SearchContentType.DealProduct,
  ];
  const allTypes = [
    SearchContentType.NewsPost,
    SearchContentType.EventPost,
    SearchContentType.BuildingInfoPost,
    ...(site.store.navEnabled ? store : []),
  ];

  const initialSearch = {
    isShown: false,
    term: "",
    types: allTypes,
    sort: ContentSortType.Relevance,
  };

  const [search, setSearch] = useState(initialSearch);
  const router = useRouter();

  const show = useCallback(() => {
    setSearch({ ...search, isShown: true });
  }, [search]);

  const hide = useCallback(() => {
    setSearch(initialSearch);
  }, [search]);

  const toggle = useCallback(() => {
    if (search.isShown) {
      hide();
    }
    else {
      show();
    }
  }, [search]);

  const updateTerm = useCallback(debounce((term: string) => {
    setSearch({ ...search, term });
  }, 500), [search]);

  const updateTypes = useCallback((types: SearchContentType[]) => {
    setSearch({ ...search, types: types.length > 0 ? types : allTypes });
  }, [search]);

  const toggleType = useCallback((type: SearchContentType) => {
    const allOn = search.types.length === allTypes.length;
    updateTypes(allOn ? [type] : search.types.includes(type)
      ? search.types.filter((t) => t !== type)
      : [...search.types, type],
    );
  }, [search]);

  const typeOn = useCallback((type: SearchContentType) => search.types.includes(type), [search]);
  const allTypesOn = useCallback(() => search.types.length === allTypes.length, [search]);

  const toggleStoreType = useCallback(() => {
    const allOn = search.types.length === allTypes.length;
    const value = allOn ? store : search.types.includes(store[0])
      ? search.types.filter((t) => !store.includes(t))
      : [...search.types, ...store];
    updateTypes(value);
  }, [search]);

  const updateSortType = useCallback((sort: ContentSortType) => {
    setSearch({ ...search, sort })
  }, [search])

  useEffect(() => {
    setSearch(initialSearch);
  }, [router.asPath]);

  const context = {
    ...search,
    show,
    hide,
    toggle,
    updateTerm,
    updateTypes,
    toggleType,
    typeOn,
    allTypesOn,
    toggleStoreType,
    updateSortType,
  };

  return (
    <SearchContext.Provider value={context}>
      {children}
    </SearchContext.Provider>
  );
}
