import React from "react";

export const Search: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="none"
      strokeWidth="2"
      d="M9.55704 18.0007C14.283 18.0007 18.1141 14.195 18.1141 9.50037C18.1141 4.80575 14.283 1 9.55704 1C4.83112 1 1 4.80575 1 9.50037C1 14.195 4.83112 18.0007 9.55704 18.0007Z"
    />
    <path
      strokeWidth="2"
      strokeLinecap="square"
      fill="none"
      d="M16.7976 16.6929L23.3799 23.2316"
    />
  </svg>
)
