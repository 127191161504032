import { PushNotificationSubscriber } from "@equiem/user-pubsub";
import { Site } from "@equiem/web-ng-lib";
import React, { createContext, useMemo } from "react";
import { useContext, useEffect } from "react";
import { useAuthenticatedViewer } from "../hooks/useAuthenticatedViewer";
import { SubscriberState, useSubscriber } from "../hooks/useSubscriber";
import { PubsubContext } from "./Pubsub";

let cache: SubscriberState<PushNotificationSubscriber> = {
  subscriber: null,
  subscribed: false,
  connected: false,
};

export const PushNotificationsContext = createContext<SubscriberState<PushNotificationSubscriber>>(cache);

/**
 * Instantiates and caches a singleton instance of the PushNotificationSubscriber.
 *
 * The caching is important to avoid reconnecting / resubscribing whenever the user navigates between pages.
 */
export const PushNotificationsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children } = {}) => {
  const viewer = useAuthenticatedViewer();
  const pubsub = useContext(PubsubContext);
  const site = useContext(Site);
  const subscriber = useMemo(() => cache.subscriber ?? (
    pubsub.clientProvider != null &&
    viewer?.uuid != null &&
    viewer.uuid !== ""
      ? new PushNotificationSubscriber(
        pubsub.clientProvider,
        console,
        { siteUuid: site.uuid, userUuid: viewer.uuid },
      )
      : null
  ), [pubsub.clientProvider, site.uuid, viewer?.uuid]);

  const state = useSubscriber(subscriber, cache);

  useEffect(() => {
    cache = state;
  }, [state]);

  return (
    <PushNotificationsContext.Provider value={state}>
      {children}
    </PushNotificationsContext.Provider>
  );
};
