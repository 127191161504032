import { Theme } from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext } from "react";

interface Props {
  href: string;
  as?: string;
  target?: React.HTMLAttributeAnchorTarget;
  title: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
  imageUrl?: string;
  imageSuffix?: React.ReactNode;
  noOpacity?: boolean;
}

export const SearchResult: React.FC<Props> = ({ href, target, as, title, body, footer, imageUrl, imageSuffix, noOpacity = false }) => {
  const { breakpoints } = useContext(Theme);

  return (
    <Link href={href} as={as} passHref>
      <a target={target} className="search-result text-body active-shadow d-flex justify-space-between">
        <div className="image position-relative">{imageUrl == null ? null : <img src={imageUrl} />}{imageSuffix}</div>
        <div className="main d-flex flex-column">
          <h3 className="title mb-2">{title}</h3>
          <div className="body mb-2 text-dark">{body}</div>
          <div className="footer">{footer}</div>
        </div>
        <style jsx>{`
          .search-result {
            border-radius: 8px;
            padding: 16px;
            background: #fff;
            width: 100%;
            margin: 0 0 16px;
          }
          a {
            text-decoration: none;
          }
          .main {
            flex-grow: 1;
            width: calc(100% - 235px - 1rem);
            overflow: hidden;
            font-size: 12px;
            padding-left: 8px;
          }
          .title {
            font-size: 12px;
          }
          .body, .footer {
            opacity: ${noOpacity ? "1" : ".6"};
          }
          .link {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .footer {
            margin-top: auto;
          }
          .image {
            width: 197px;
            max-width: 35%;
            border-radius: 4px;
          }
          .image img {
            width: 100%;
            border-radius: 4px;
          }
          @media (min-width: ${breakpoints.md}px) {
            .search-result {
              border-radius: 8px;
              padding: 21px;
              margin: 0 0 22px;
            }
            .title {
              font-size: 16px;
            }
            .main {
              padding-left: 16px;
              font-size: 14px;
            }
            .footer {
              font-size: 13px;
            }
          }
        `}</style>
      </a>
    </Link>
  );
}
