import React from "react";

export const ThumbsDown: React.FC<{ size?: number }> = ({ size = 111 }) => (
  <svg width={size} height={size} viewBox="0 0 111 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.3752 0.333328H25.7502C21.6347 0.333328 18.1143 2.16666 16.6268 4.80666L1.65266 30.6567C1.20641 31.5 0.958496 32.38 0.958496 33.3333V40.6667C0.958496 44.7 5.421 48 10.8752 48H42.1622L37.4518 64.7567L37.3031 65.93C37.3031 67.4333 38.146 68.8267 39.4847 69.8167L44.7406 73.6667L77.416 49.5033C79.201 48.1833 80.2918 46.35 80.2918 44.3333V7.66666C80.2918 3.63333 75.8293 0.333328 70.3752 0.333328ZM70.3752 44.3333L48.856 60.2467L55.5002 40.6667H10.8752V33.3333L25.7502 7.66666H70.3752V44.3333ZM110.042 0.333328H90.2085V44.3333H110.042V0.333328Z"
    />
  </svg>
);
