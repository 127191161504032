import React from "react";

export type MobileViewContext = {
  inMobileView: false;
} | {
  inMobileView: true;
  appId?: string;
}

export const MobileView = React.createContext<MobileViewContext>({
  inMobileView: false
});
