import React from "react";

import { useGetBuildingQuery } from "../generated/gateway-client";
import { formatters, useTranslation } from "../generated/localisation";

interface Props {
  /** in the minor unit of the currency */
  amount: number;
  currencyCode?: string | null | undefined;
  buildingUuid?: string | null | undefined;
  className?: string;
  zeroValueText?: string;
}
const defaultCurrency = "AUD";

export const CurrencyAmount: React.FC<Props> = ({
  amount,
  currencyCode,
  buildingUuid,
  className,
  zeroValueText,
}) => {
  const { i18n } = useTranslation();
  const currency = useCurrencyCode(buildingUuid, currencyCode);

  if (zeroValueText != null && amount == 0) {
    return <span className={className}>{zeroValueText}</span>;
  }

  const value = formatCurrency(amount, currency, i18n.language);

  return (
    <span className={className}>{value}</span>
  );
};

const formatCurrency = (amount: number, currency: string, language?: string) => {
  return formatters.currency(amount / 100, language, { currency });
};

const useCurrencyCode = (buildingUuid?: string | null | undefined, explicitCurrencyCode?: string | null | undefined) => {
  const building = useGetBuildingQuery({
    variables: { uuid: buildingUuid ?? "" },
    skip: buildingUuid == null || explicitCurrencyCode != null,
  });
  const buildingCurrency = building.data?.buildingPublic?.taxes.currency.code;

  return explicitCurrencyCode ?? buildingCurrency ?? defaultCurrency;
};
