import { useTranslation } from "@equiem/web-ng-lib";
import { Session, ViewerContext } from "@equiem/web-ng-lib";
import { useContext, useEffect } from "react";
import { useViewerQuery } from "../generated/gateway-client";

let lastEmailVerified = true;

export const useAuthenticatedViewer = (): ViewerContext => {
  const { t } = useTranslation();

  const session = useContext(Session);
  const result = useViewerQuery({
    notifyOnNetworkStatusChange: true,
    skip: session.side === "server" || session.authenticatedClient == null || !session.authenticated,
    client: session.side === "client" ? session.authenticatedClient ?? undefined : undefined,
  });

  const profile = result.data?.viewer.profile ?? null;

  useEffect(() => {
    if (profile?.emailVerified != null) {
      if (profile.emailVerified && !lastEmailVerified && session.refresh != null) {
        console.log("Email just verified. Refreshing session...");
        session.refresh().catch();
      }
      lastEmailVerified = profile.emailVerified;
    }
  }, [profile?.emailVerified, session.refresh]);

  return {
    loading: result.loading,
    termsAccepted: result.data?.viewer.currentDestination?.termsAccepted ?? null,
    permissions: {
      readComments: result.data?.viewer.currentDestination?.permissions.readComments ?? false,
      writeComments: result.data?.viewer.currentDestination?.permissions.writeComments ?? false,
      canSubscribeToEmails: result.data?.viewer.currentDestination?.permissions.canSubscribeToEmails ?? true,
      canSubscribeToNotifications: result.data?.viewer.currentDestination?.permissions.canSubscribeToNotifications ?? true,
    },
    optInNewsletter: result.data?.viewer.profile?.siteProfiles.edges[0]?.node?.subscribedToEmails ?? null,
    uuid: result.data?.viewer.uuid ?? "",
    stripePk: result.data?.paymentGatewayPublicKey ?? null,
    refetch: async () => {
      await result.refetch();
    },
    profile: profile == null ? undefined : {
      uuid: profile.uuid,
      updated: profile.updated,
      avatar: profile.avatar ?? undefined,
      email: profile.email,
      emailVerified: profile.emailVerified,
      subscribedToEmails: profile.siteProfiles.edges[0]?.node?.subscribedToEmails ?? false,
      subscribedToNotifications: profile.siteProfiles.edges[0]?.node?.subscribedToNotifications ?? false,
      isAppointmentOrganizer: profile.isAppointmentOrganizer ?? false,
      name: profile.firstName ?? profile.displayName,
      firstName: profile.firstName ?? t("main.unknown"),
      lastName: profile.lastName ?? t("main.unknown"),
      mobileNumber: profile.mobileNumber ?? "",
      referenceId: profile.referenceId ?? t("main.unknown"),
      paymentGatewayCustomerId: profile.paymentGatewayCustomerId ?? "",
      company: {
        uuid: profile.company?.uuid ?? "",
        name: profile.company?.name ?? t("main.unknownCompany"),
      },
      cards: profile.cards,
      siteProfile: profile.siteProfiles.edges[0]?.node,
    },
  };
};
