import { useTranslation } from "@equiem/web-ng-lib";
import { Button, Theme } from "@equiem/web-ng-lib";
import React, { useContext, useState } from "react";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { useFeedbackSubmission } from "../../hooks/useFeedbackSubmission";
import { FeedbackComment } from "./FeedbackComment";
import { FeedbackIcon } from "./FeedbackIcon";
import { Score } from "./FeedbackTypes";

export const Feedback: React.FC<{}> = () => {
  const { t } = useTranslation();
  const initScores: Score[] = [1, 2, 3, 4, 5].map((score) => ({
    score,
    selected: false,
  }));

  const feedbackContext = useFeedbackContext();
  const submission = useFeedbackSubmission();
  const { breakpoints } = useContext(Theme);
  const [scores, setScores] = useState<Score[]>(initScores);

  const selectScore = (selected: Score) => {
    setScores(() => scores.map((s) => ({ ...s, selected: (s.score === selected.score)})));
  };

  const submitScore = async (selected: Score) => {
    if (!feedbackContext.submitted) {
      feedbackContext.submitFeedback(selected);
      selectScore(selected);
      await submission.submitFeedback(selected.score);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center smiley-feedback">
        {
          scores.map((score, k) =>
            <Button
            key={k}
            className={ feedbackContext.submitted ? "nps-box rating-submitted p-2" : "nps-box p-2" }
            type="button"
            variant={score.selected ? "solid" : "outline"}
            size="sm"
            onClick={async () => {
              await submitScore(score);
            }}
            >
              <FeedbackIcon score={score.score} />
            </Button>,
          )
        }
      </div>

      <div className="d-flex justify-content-between text-muted">
        <p className="unsatisfied">{feedbackContext.question?.unsatisfied_text_label ?? t("main.extremelyUnsatisfied")}</p>
        <p className="satisfied">{feedbackContext.question?.satisfied_text_label ?? t("main.extremelySatisfied")}</p>
      </div>

      {
        feedbackContext.submitted ?
          <div className="d-flex flex-column justify-content-center align-items-center rounded p-2 mt-2 feedback-comment-container">
            <FeedbackComment />
          </div>
          :
          null
      }
      <style jsx global>
        {`
          .smiley-feedback {
            margin-top: -15px;
            height: 48px;
          }
          .nps-box {
            border: none !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            color: ${feedbackContext.question?.accentColour ?? "#cccccc"} !important;
            width: 60px;
          }
          .rating-submitted {
            cursor: inherit !important;
            background: none !important;
            opacity: 0.8 !important;
          }
          .rating-submitted.btn-solid {
            background: #ffffff;
            color: ${feedbackContext.question?.accentColour ?? "#cccccc"};
            opacity: 1 !important;
          }
          .rating-submitted:hover,
          .rating-submitted:focus,
          .nps-box:hover,
          .nps-box:focus {
            box-shadow: none !important;
          }
          .unsatisfied {
            width: 51px;
            font-size: 11px;
            margin-left: 68px;
            margin-bottom: 0;
          }
          .satisfied {
            width: 51px;
            font-size: 11px;
            margin-right: 67px;
            margin-bottom: 0;
          }
          @media (max-width: ${breakpoints.md - 1}px) {
            .unsatisfied {
              margin-left: 20px;
            }
            .satisfied {
              margin-right: 20px;
            }
          }
        `}
      </style>
    </>
  );
};
