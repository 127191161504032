import type { ApolloError } from "@apollo/client";
import { useTranslation, useApolloErrorTranslation } from "../generated/localisation";
import { useCallback } from "react";
import type { Static } from "runtypes";
import { Array, Number, Optional, Partial, Record, String, Null, Union } from "runtypes";
import { useAlert } from "react-alert";

const MutationError = Record({
  message: String,
  extensions: Optional(Record({ code: String })),
});

const MutationErrors = Partial({
  message: String,
  graphQLErrors: Union(Array(MutationError), Null),
  networkError: Union(
    Partial({
      message: String,
      statusCode: Number,
      result: Partial({
        errors: Array(MutationError),
      }),
    }),
    Null,
  ),
});

type MutationErrors = Static<typeof MutationErrors>;

const getFriendlyErrors = (errors: MutationErrors) => {
  const allErrors =
    errors.graphQLErrors != null && errors.graphQLErrors.length > 0
      ? errors.graphQLErrors
      : errors.networkError?.result?.errors ?? [];

  const friendlyErrors = allErrors
    .filter((e) => ["BAD_USER_INPUT", "FORBIDDEN", "UNAUTHENTICATED"].includes(e.extensions?.code ?? ""))
    .map((e) => e.message);

  if (friendlyErrors.length === 0) {
    const fallback = (errors.message ?? errors.networkError?.message ?? "").trim();
    if (fallback !== "") {
      friendlyErrors.push(fallback);
    }
  }

  return friendlyErrors;
};

export const useShowError = () => {
  const alert = useAlert();
  const { tError } = useApolloErrorTranslation();
  const { t } = useTranslation();

  return useCallback(
    (e: unknown) => {
      console.error(e);
      if ((e as ApolloError)?.graphQLErrors?.some((gqlError) => gqlError?.extensions?.localisableErrorParams != null)) {
        alert.error(tError(e as ApolloError));
        return;
      }

      if (MutationErrors.guard(e)) {
        const friendlyErrors = getFriendlyErrors(e);
        if (friendlyErrors.length > 0) {
          alert.error(friendlyErrors[0]);
          return;
        }
      }

      alert.error(t("common.somethingWrong"));
    },
    [alert, tError, t],
  );
};
