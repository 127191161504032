import React from "react";

export const Cross: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      d="M7.43333 9.08275L0.833496 2.48292L2.48316 0.833252L9.083 7.43309L15.6828 0.833252L17.3325 2.48292L10.7327 9.08275L17.3325 15.6826L15.6828 17.3323L9.083 10.7324L2.48316 17.3323L0.833496 15.6826L7.43333 9.08275Z"
    />
  </svg>
)
