import { relayStylePagination } from "@apollo/client/utilities";
import { TypedTypePolicies } from "../../generated/apollo-helpers";

// tslint:disable-next-line: no-unsafe-any
const replace = (_existing: any, incoming: any) => incoming;

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      contentFeed: relayStylePagination(["filters"]),
      commentsForContent: relayStylePagination(),
      savedPushNotifications: relayStylePagination(),
      trendingContentFeed: relayStylePagination(),
      newsContentFeed: relayStylePagination(),
      upcomingEventsContentFeed: relayStylePagination(),
      vendors: relayStylePagination(),
      dealVendors: relayStylePagination(),
      curatedFeed: relayStylePagination(["uuid"]),
      visitorAppointments: relayStylePagination(["beginDate"]),
      visitorReceptionsAvailable: { merge: false },
      reqMgt: relayStylePagination(),
    },
  },
  Occurrence: {
    fields: {
      eventPost: { merge: true },
    },
  },
  User: {
    fields: {
      currentDestination: { merge: true },
    },
  },
  VendorCategory: {
    fields: {
      vendorList: relayStylePagination(),
    },
  },
  Profile: {
    fields: {
      cards: { merge: replace },
      siteProfiles: { merge: replace },
    },
  },
  CartSuccess: {
    merge: replace,
  },
  Order: {
    merge: replace,
  },
};
