import { useState } from "react";

export const useFormGroupControl = ({ id, name, value = "" }: { id?: string; name: string; value?: string }) => {
  const [empty, setEmpty] = useState(value.length === 0);
  const [focused, setFocused] = useState(false);

  return {
    empty,
    setEmpty: (newEmpty: boolean) => {
      if (newEmpty !== empty) {
        setEmpty(newEmpty);
      }
    },
    focused,
    setFocused,
    id: id ?? `${name}-field`,
  };
};
