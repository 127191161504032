import { formatters, useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { BookableProductQuery } from "../../../generated/gateway-client";

interface Props {
  session: Extract<NonNullable<BookableProductQuery["product"]>, { __typename?: "BookableProduct" }>["groupedSessions"][0]["sessions"][0];
  showDate?: boolean;
}

export const ProductBookableItem: React.FC<Props> = ({ session, showDate = false }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <span className="font-weight-bold">
        {showDate ? `${formatters.datelong(session.startDate, i18n.language, { timezone: session.timezone })} @ ` : ""} {`${formatters.timeshort(session.startDate, i18n.language, { timezone: session.timezone })}, `}
        {formatters.durationshort(session.duration, i18n.language)}
      </span>, {" "} { session.displayName}
    </>
  );
}
