import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { stringNotEmpty } from "../lib/stringNotEmpty";
import { Site } from "./SiteContext";

interface PageTitleContext {
  pageTitle: string | null;
  setPageTitle(value: string | null): void;
}

export const PageTitleContext = createContext<PageTitleContext>({
  pageTitle: null,
  setPageTitle: () => undefined,
});

export const PageTitleProvider: React.FC = ({ children }) => {
  const site = useContext(Site);
  const router = useRouter();

  const [title, setTitle] = useState<string | null>(null);

  const pageTitle = title != null ? `${title} | ${site.name}` : site.name;

  const setPageTitle = useCallback((value: string | null) => {
    setTitle(stringNotEmpty(value) ? value : null);
  }, []);

  useEffect(() => setTitle(null), [router.asPath]);

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};
