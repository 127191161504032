import { useTranslation } from "@equiem/web-ng-lib";
import { Button, ProgressCircle, Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchQuery } from "../../hooks/useSearch";
import { BookableProductSearchResult } from "./results/BookableProductSearchResult";
import { BuildingInfoSearchResult } from "./results/BuildingInfoSearchResult";
import { DealProductSearchResult } from "./results/DealProductSearchResult";
import { EventSearchResult } from "./results/EventSearchResult";
import { NewsSearchResult } from "./results/NewsSearchResult";
import { PlainProductSearchResult } from "./results/PlainProductSearchResult";
import { SubscriptionProductSearchResult } from "./results/SubscriptionProductSearchResult";
import { VendorSearchResult } from "./results/VendorSearchResult";

interface Props {
  searchQuery: ReturnType<typeof useSearchQuery>;
  scrollableTarget?: React.ReactNode | null;
}

export const SearchResults: React.FC<Props> = ({
  searchQuery: { nodes, loading, fetchMore, hasMore, fetchingMore, pageNum },
  scrollableTarget,
}) => {
  const { t } = useTranslation();

  const site = useContext(Site);
  const results = !loading || fetchingMore ? nodes : [];
  const pauseInfiniteScroll = pageNum % 3 === 0;

  return (
    <div className="search-results">
      { results.length === 0 ? null : (
        <InfiniteScroll
          next={fetchMore}
          dataLength={results.length}
          hasMore={hasMore && !loading && !pauseInfiniteScroll}
          loader={<span/>}
          scrollableTarget={scrollableTarget}
        >
          { results.map((node) => {
            switch (node.__typename) {
              case "Deal": return <DealProductSearchResult key={node.uuid} product={node} />;
              case "BookableProduct": return <BookableProductSearchResult key={node.uuid} product={node} />;
              case "SubscriptionProduct": return <SubscriptionProductSearchResult key={node.uuid} product={node} />;
              case "PlainProduct": return <PlainProductSearchResult key={node.uuid} product={node} />;
              case "Occurrence": return <EventSearchResult key={node.uuid} occurrence={node} />;
              case "NewsPost": return <NewsSearchResult key={node.uuid} newsPost={node} />;
              case "BuildingInfoPost": return <BuildingInfoSearchResult key={node.uuid} buildingInfoPost={node} />;
              case "DealVendor":
              case "Vendor": return <VendorSearchResult key={node.uuid} vendor={node} />;
              default: return null;
            }
          })}
        </InfiniteScroll>
      )}
      <div className="text-center my-3">
        { loading
          ? <ProgressCircle size="md" color={site.whiteContrastColour} />
          : !hasMore ? null : (
            <Button
              variant="outline"
              className="my-3"
              onClick={async () => {
                await fetchMore();
              }}
            >{t("main.loadMore")}</Button>
          )
        }
      </div>
      <style jsx>{`
      .search-results :global(.infinite-scroll-component) {
        overflow: visible !important;
      }
      `}</style>
    </div>
  );
}
