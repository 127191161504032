import { PageTitleContext, Site, Theme } from "@equiem/web-ng-lib";
import Head from "next/head";
import React, { useContext } from "react";

export const SiteHead: React.FC = () => {
  const { pageTitle } = useContext(PageTitleContext);
  const site = useContext(Site);
  const size = site.logoSize === "m" ? 12 : (site.logoSize === "l" ? 14 : 11);
  const msize = site.logoSize === "m" ? 9 : (site.logoSize === "l" ? 11 : 8);
  const { colors: { greyBackground }, breakpoints } = useContext(Theme);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <link rel="icon" href={site.favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {/* direct crawlers to follow all the links which would then get an explicit noindex from each page to not be included in search engine results */}
        <meta name="robots" content="noindex,follow" />
      </Head>
      <style jsx global>{`
        .bg-grey {
          background: ${greyBackground};
        }
        .bg-site {
          background-color: ${site.primaryColour} !important;
        }
        .bg-site-contrast {
          background-color: ${site.primaryContrastColour} !important;
        }
        .modal-header,
        .modal-header .close,
        .modal-header .h4 {
          background-color: ${site.primaryColour};
          border-color: ${site.primaryColour};
          color: ${site.primaryContrastColour};
        }
        .text-site {
          color: ${site.whiteContrastColour} !important;
        }
        .text-site-contrast {
          color: ${site.primaryContrastColour} !important;
        }
        .text-grey {
          color: ${greyBackground} !important;
        }
        .border-site {
          border-color: ${site.whiteContrastColour} !important;
        }
        .border-grey {
          border-color: ${greyBackground} !important;
        }
        .border-site-contrast {
          border-color: ${site.primaryContrastColour} !important;
        }
        .custom-select.sub-nav-select {
          border-color: ${site.whiteContrastColour} !important;
          color: ${site.whiteContrastColour} !important;
        }
        a,
        a:hover,
        a:focus,
        a:visited,
        a:active {
          color: ${site.whiteContrastColour};
        }
        .bg-site a,
        .bg-site a:hover,
        .bg-site a:focus,
        .bg-site a:visited,
        .bg-site a:active {
          color: ${site.primaryContrastColour};
        }
        .logo-container {
          max-width: ${msize}rem;
        }
        .logo-container img {
          max-height: 6rem;
          max-width: 100%;
          flex-shrink: 0;
        }
        .list-group-item.active {
          background-color: ${site.primaryColour} !important;
          color: ${site.primaryContrastColour} !important;
        }
        @media (min-width: ${breakpoints.md}px) {
          .logo-container {
            max-width: ${size}rem;
          }
        }
      `}</style>
    </>
  );
}
