import { ApolloProvider } from "@apollo/react-hoc";
import { AuthenticationContext, getAnonymousGateway, getAuthenticatedGateway, MobileView, MobileViewContext, Session, SessionContext } from "@equiem/web-ng-lib";
import React, { useCallback, useState } from "react";
import { useAlert } from "react-alert";

interface Props {
  gatewayEndpoint: string;
  authState: AuthenticationContext;
  setAuthState: (state: AuthenticationContext) => void;
  children?: React.ReactNode;
  isInMobileView: boolean;
}

export const SessionProvider: React.FC<Props> = ({
  children,
  gatewayEndpoint,
  authState,
  setAuthState,
  isInMobileView,
}) => {
  const alert = useAlert();
  const [locale, setLocale] = useState("en");
  const [mobileView, setMobileView] = useState<MobileViewContext>({
    inMobileView: isInMobileView,
  })

  const { client: authenticatedClient, authenticate } = getAuthenticatedGateway(
    gatewayEndpoint,
    setAuthState,
    alert,
    isInMobileView,
    setMobileView,
    locale,
  );

  const refresh = useCallback(async () => {
    await authenticate(true, isInMobileView);
  }, [authenticate]);

  const session: SessionContext = typeof window === "undefined" ? {
    ...authState,
    side: "server" as const,
  } : {
    ...authState,
    side: "client",
    authenticatedClient,
    refresh,
    setLocale,
  };

  return (
    <ApolloProvider client={getAnonymousGateway(gatewayEndpoint, alert)}>
      <Session.Provider value={session}>
        <MobileView.Provider value={mobileView}>{children}</MobileView.Provider>
      </Session.Provider>
    </ApolloProvider>
  );
};
