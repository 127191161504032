import { useTranslation } from "@equiem/web-ng-lib";
import { Site, Theme } from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { SearchContext } from "../../contexts/SearchContext";
import { useQuickLinksQuery } from "../../generated/gateway-client";
import { useLogoBackground } from "../../hooks/useLogoBackground";
import { useSearchQuery } from "../../hooks/useSearch";
import { Search } from "../icons/Search";
import { OpenCloseBtn } from "../sidemenu/OpenCloseBtn";
import { NoResults } from "./NoResults";
import { QuickLinks } from "./QuickLinks";
import { SearchFilters } from "./SearchFilters";
import { SearchResults } from "./SearchResults";

export const SearchModal: React.FC = () => {
  const { t } = useTranslation();

  const site = useContext(Site);
  const { breakpoints } = useContext(Theme);
  const search = useContext(SearchContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollableTargetRef = useRef<HTMLDivElement>(null);
  const searchQuery = useSearchQuery();
  const { skipped, loading, nodes, term } = searchQuery;
  const quickLinksQuery = useQuickLinksQuery();
  const noResults = !skipped && !loading && nodes.length === 0;
  const { whiteBg, bgColor, fgColor } = useLogoBackground();

  useEffect(() => {
    if (search.isShown && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [search.isShown]);

  useEffect(() => {
    if (scrollableTargetRef.current != null) {
      scrollableTargetRef.current.scrollTop = 0;
    }
  }, [term]);

  return (
    <>
      <Modal
        show={search.isShown}
        keyboard={true}
        scrollable={true}
        dialogClassName="search"
        onHide={() => {
          search.hide();
        }}
      >
        <Modal.Header className={`p-0 d-block${whiteBg ? " shadow-sm border-bottom" : ""}`}>
          <div className="header d-flex justify-content-between align-items-center">
            { site.logo == null ? null : (
              <Link href="/" passHref>
                <a onClick={() => {
                  search.hide();
                }} className="logo-container d-block">
                  <img src={site.logo} />
                </a>
              </Link>
            )}
            <a className="dialog-close ml-auto" style={{ color: fgColor }} onClick={() => {
              search.hide();
            }}>
              <OpenCloseBtn open={true} />
            </a>
          </div>
          <div className="body mx-auto">
            <div className="search-container position-relative">
              <input
                ref={inputRef}
                className="search-input pr-4"
                placeholder={t("main.search")}
                defaultValue={search.term}
                onChange={(e) => {
                  search.updateTerm(e.currentTarget.value);
                }}
              />
              <span className="icon"><Search size={20} /></span>
            </div>
            {
              search.term.length > 0 && !skipped ? (
                <div className="pb-2 pb-md-4">
                  <SearchFilters />
                </div>
              ) : null
            }
          </div>
        </Modal.Header>
        { /* tslint:disable-next-line: no-unsafe-any */}
        <Modal.Body ref={scrollableTargetRef as any} className="p-0">
          <div className="body search-body mx-auto">
            { noResults ? <NoResults term={term} /> : null }
            { skipped ? (
              <div className="ql-container white-boxie">
                <QuickLinks quickLinksQuery={quickLinksQuery} />
              </div>
            ) : null }
            <SearchResults
              searchQuery={searchQuery}
              scrollableTarget={scrollableTargetRef.current as React.ReactNode}
            />
          </div>
        </Modal.Body>
      </Modal>
      <style jsx>{`
      .body {
        width: 90%;
        max-width: 907px;
      }
      .m-title {
        font-size: 10px;
        font-weight: 600;
      }
      :global(.modal-dialog.search) {
        margin: 0;
        width: 100vw;
        min-height: 100vh;
        max-width: none;
        max-height: none;
        background: white;
        transition: opacity 500ms !important;
        transform: none !important;
        opacity: 0.5;
      }
      .modal-body .body {
        min-height: 10rem;
      }
      .search-container {
        margin: 24px 0;
      }
      :global(.modal-dialog.search .modal-header) {
        z-index: 1;
        background: none;
      }
      :global(.modal-dialog.search .header) {
        background: ${bgColor};
        padding: 8px 16px;
      }
      :global(.modal-dialog.search .modal-content) {
        background: #f9f9f9;
        height: 100vh;
        max-height: 100vh;
      }
      :global(.modal.show .modal-dialog.search) {
        opacity: 1;
      }
      .body :global(.white-boxie) {
        background: #fff;
        border-radius: 8px;
        padding: 16px 24px;
      }
      .search-input {
        font-size: 12px;
        background: none;
        border: 1px solid #9E9C9C;
        border-width: 0 0 1px 0;
        border-radius: 0;
        outline: none;
        width: 100%;
        appearance: none;
        line-height: 1.8;
        height: 2.25em;
      }
      .search-input:focus {
        border-color: ${bgColor};
      }
      .search-input::placeholder {
        color: #212529;
        opacity: 0.5;
      }
      .icon {
        color: ${bgColor};
        position: absolute;
        top: 0;
        right: 0;
      }
      .dialog-close {
        padding: .75rem 0;
        cursor: pointer;
      }
      @media (min-width: ${breakpoints.md}px) {
        :global(.modal-dialog.search .header) {
          min-height: 72px;
          padding: 8px 32px;
        }
        .search-container {
          margin: 56px 0;
        }
        .search-input {
          font-size: 1.25rem;
          line-height: 1.8;
          height: 2.25em;
        }
        .icon {
          top: .75rem;
        }
      }
      `}</style>
    </>
  );
}
