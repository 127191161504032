import React from "react";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { FeedbackMetricTargetType } from "../../generated/gateway-client";
import { Feedback } from "./Feedback";
import { Nps } from "./Nps";

type FeedbackScoreWidgetsMap = Record<FeedbackMetricTargetType, React.FC<{}>>;

export const FeedbackScoreWidget: React.FC<{}> = () => {
  const context = useFeedbackContext();
  const type: FeedbackMetricTargetType = context.question?.metricTargetType ?? FeedbackMetricTargetType.Nps;

  const feedbackTypes: FeedbackScoreWidgetsMap = {
    NPS: Nps,
    FACE: Feedback,
  }

  const Component = feedbackTypes[type] ?? Nps;

  return <div><Component /></div>;
};
