import React from "react";

export const Clock: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg className="clock-icon" width={size} height={size} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      transform="scale(1.25) translate(-1,-1.5)"
      d="M8.64553 2.58185C4.60402 2.58185 1.33127 5.99596 1.33127 10.2026C1.33127 14.4093 4.60402 17.8234 8.64553 17.8234C12.6944 17.8234 15.9744 14.4093 15.9744 10.2026C15.9744 5.99596 12.6944 2.58185 8.64553 2.58185ZM8.6529 16.5333C5.29253 16.5333 2.57078 13.7003 2.57078 10.2026C2.57078 6.70494 5.29253 3.87197 8.6529 3.87197C12.0133 3.87197 14.735 6.70494 14.735 10.2026C14.735 13.7003 12.0133 16.5333 8.6529 16.5333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      transform="scale(1.25) translate(-1,-1.5)"
      d="M9.1947 5.45557H8.08501V10.5049L11.9689 13.1559L12.5238 12.1207L9.1947 9.87376V5.45557Z"
    />
  </svg>
)
