import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { SubscriptionBillingCycle } from "../generated/gateway-client";
import { PriceFormatForCurrency } from "./PriceFormatForCurrency";

interface Props {
  price: number;
  freeTxt: string;
  billingCycle?: SubscriptionBillingCycle;
}

export const PriceFormat: React.FC<Props> = (props) => {
  const site = useContext(Site);

  return (
    <PriceFormatForCurrency {...props} currencyCode={site.taxRegion.currency.code} />
  );
}
