import React, { useEffect } from "react";
import { AlertTemplateProps } from "react-alert";
import { Alert } from "react-bootstrap";
import { Button } from "./Button";
import { useTranslation } from "../generated/localisation";

/**
 * Alert template.
 */
export const AlertTemplate: React.FC<AlertTemplateProps> = ({
  message: messageInput,
  options,
  close,
}) => {
  // This may be rendered before localisation init! Provide a default value for
  // all calls to `t`!
  const { t } = useTranslation();

  useEffect(() => {
    // Only success message.
    if (options.type !== "success") {
      return;
    }

    const timer = setTimeout(() => {
      close();
    }, 6000);

    // tslint:disable-next-line: no-void-expression
    return () => clearTimeout(timer);
  }, []);

  const message =
    typeof messageInput === "string"
      ? messageInput.replace(/^(Error\: )?(GraphQL error\: )?/gi, "").trim()
      : messageInput;

  return (
    <Alert
      variant={options.type === "error" ? "danger" : options.type}
      className="d-flex align-items-center"
    >
      {message}
      <Button
        variant="link"
        className="close py-0 pr-0"
        style={{ textDecoration: "underline" }}
        onClick={close}
      >
        {
          // This component needs to handle notifications generated before
          // localisation is initialized. This is almost never a relevant
          // concern, so the types don't account for this.
          t("lib.dismiss" as any, { defaultValue: "Dismiss" })
        }
      </Button>
      <style jsx global>{`
        [role="alert"] .btn-link {
          color: white !important;
        }
      `}</style>
    </Alert>
  );
};
