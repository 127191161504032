import { Button, Check, CreditCardBrand, NewCreditCard, ProgressCircle, Site, useShowError, Viewer } from "@equiem/web-ng-lib";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CardFragmentFragment } from "../../generated/gateway-client";
import { useTranslation } from "../../generated/localisation";

interface Props {
  selectedCard?: CardFragmentFragment;
  onHide: () => void;
  onSave: (card?: CardFragmentFragment) => Promise<void>;
}

export const PaymentCardSelector: React.FC<Props> = ({
  selectedCard: selectedCardInput,
  onHide,
  onSave,
}) => {
  const { t } = useTranslation();

  const viewer = useContext(Viewer);
  const site = useContext(Site);
  const showError = useShowError();
  const cards = viewer?.profile?.cards ?? [];
  const [addingCard, setAddingCard] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedCardUuid, setSelectedCardUuid] = useState(selectedCardInput?.uuid)
  const selectedCard = useMemo(() => (
    cards.find((c) => c.uuid === selectedCardUuid)
  ), [selectedCardUuid, cards]);

  useEffect(() => {
    if (selectedCardInput != null) {
      setSelectedCardUuid(selectedCardInput.uuid);
    }
  }, [selectedCardInput]);

  return (
    <div className="payment-card-selector">
      {cards.length === 0 ? (
        <NewCreditCard
          autofocus={true}
          setDefault={true}
          onCancel={() => {
            onHide();
          }}
          onCardAdded={({ uuid }) => {
            setSelectedCardUuid(uuid);
            onHide();
          }}
        />
      ) : (
          <>
            <div className="border-top">
              {cards.map((card) => (
                <div
                  key={card.uuid}
                  className="card-radio d-flex py-3 px-1 border-bottom"
                  onClick={() => {
                    setSelectedCardUuid(card.uuid);
                  }}
                >
                  <Check
                    type="radio"
                    name="selectedCard"
                    checked={selectedCardUuid === card.uuid}
                    onChange={() => {
                      setSelectedCardUuid(card.uuid);
                    }}
                  />
                  <div>
                    <div>{card.description ?? t("lib.untitled")}</div>
                    <div className="d-flex align-items-center">
                      <CreditCardBrand brand={card.brand} />
                      <span>**** **** **** {card.last4}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {!addingCard ? (
                <Button variant="link" className="pl-0 mt-2" onClick={() => {
                  setAddingCard(true);
                }}>{t("lib.addNewCard")}</Button>
              ) : (
                  <NewCreditCard
                    autofocus={true}
                    setDefault={false}
                    onCancel={() => {
                      setAddingCard(false);
                    }}
                    onCardAdded={(e) => {
                      setAddingCard(false);
                      setSelectedCardUuid(e.uuid);
                    }}
                  />
                )}
            </div>
            <div className="mt-3">
              <Button variant="outline" type="button" className="mr-2" disabled={addingCard || saving} onClick={() => {
                setSelectedCardUuid(selectedCardInput?.uuid);
                onHide();
              }}>{t("lib.back")}</Button>
              <Button
                variant="solid"
                disabled={addingCard || saving}
                type="button"
                onClick={() => {
                setSaving(true);
                onSave(selectedCard)
                  .then(onHide)
                  .catch((e) => {
                    showError(e);
                    setSaving(false);
                  });
              }}>
                { saving ? <ProgressCircle color={site.primaryContrastColour} size="sm" className="mr-1" /> : null }
                {t("lib.save")}
              </Button>
            </div>
          </>
        )
      }
      <style jsx>{`
        .card-radio {
          cursor: pointer;
        }
        .card-radio:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      `}</style>
    </div>
  );
};
