import { Site } from "@equiem/web-ng-lib";
import color from "color";
import React, { forwardRef, InputHTMLAttributes, useContext, useMemo } from "react";

type Props = {
  className?: string;
  color?: "primary" | "contrast";
  size?: "sm" | "md" | "lg";
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

export const SlideToggle = forwardRef<HTMLInputElement, Props>(({
  className = "",
  color: inputColor = "primary",
  size = "md",
  // tslint:disable-next-line:trailing-comma
  ...props
}, ref) => {
  const site = useContext(Site);

  const colors = useMemo(() => inputColor === "primary" ? {
    offBorder: "#aaa",
    onBorder: site.primaryColour,
    offDot: "#aaa",
    onDot: site.primaryContrastColour,
    onBg: site.primaryColour,
    offBg: "#eee",
    disabledBg: "#ddd",
    disabledOpacity: 1,
  } : {
    offBorder: site.primaryContrastColour,
    onBorder: site.primaryContrastColour,
    offDot: site.primaryContrastColour,
    onDot: site.primaryContrastColour,
    onBg: color(site.primaryContrastColour).fade(0.5),
    offBg: "transparent",
    disabledBg: color(site.primaryContrastColour).fade(0.5),
    disabledOpacity: 0.5,
  }, [inputColor, props.disabled, site.primaryColour, site.primaryContrastColour]);

  return (
    <div className={`slide-toggle ${size} ${className}`}>
      <input type="checkbox" ref={ref} {...props} />
      <div className="dot"></div>
      <style jsx>{`
      .sm {
        font-size: .8rem;
      }
      .lg {
        font-size: 1.2rem;
      }
      .slide-toggle, .dot, input {
        display: inline-block;
        width: 2em;
        height: 1.2em;
      }
      .slide-toggle {
        position: relative;
      }
      input {
        opacity: 0;
        cursor: pointer;
      }
      .dot {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        border: 1px solid ${colors.offBorder};
        border-radius: 1rem;
        background: ${colors.offBg};
        cursor: pointer;
      }
      .dot:after {
        content: "";
        position: absolute;
        top: .12em;
        left: .12em;
        pointer-events: none;
        border-radius: 50%;
        background: ${colors.offDot};
        width: .8em;
        height: .8em;
        transition: left 100ms;
        cursor: pointer;
      }
      :checked+.dot {
        background-color: ${colors.onBg};
        border-color: ${colors.onBorder};
      }
      :checked+.dot:after {
        background: ${colors.onDot};
        left: 1em;
      }
      :disabled+.dot {
        opacity: ${colors.disabledOpacity};
        background: ${colors.disabledBg};
      }
      :focus+.dot {
        border-color: ${colors.offBorder};
        box-shadow: 0 0 0 0.15em ${color(colors.offBorder).fade(0.8)};
      }
      :checked:focus+.dot {
        border-color: ${colors.onBorder};
        box-shadow: 0 0 0 0.15em ${color(colors.onBorder).fade(0.8)};
      }
    `}</style>
    </div>
  )
});
