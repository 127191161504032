import { formatters, useTranslation } from "@equiem/web-ng-lib";
import { useRouter } from "next/router";
import React, { useEffect, useReducer } from "react";
import { CartReducerState, Item, ItemInput } from "./CartInterfaces";
import { Action, CartReducer } from "./CartReducer";

const emptyState: CartReducerState = {
  items: [],
  updated: null,
  itemCount: 0,
  total: null,
  showCartModal: false,
  showCartBtn: true,
  discountCode: null,
};

const init = {
  add: () => {
    throw new Error("Not implemented.");
  },
  remove: () => {
    throw new Error("Not implemented.");
  },
  updateSubtotal: () => {
    throw new Error("Not implemented.");
  },
  allowToAdd: () => {
    throw new Error("Not implemented.");
  },
  updateQuantity: () => {
    throw new Error("Not implemented.");
  },
  empty: () => {
    throw new Error("Not implemented.");
  },
  emptyCache: () => {
    throw new Error("Not implemented.");
  },
  restore: () => {
    throw new Error("Not implemented.");
  },
  addDiscountCode: () => {
    throw new Error("Not implemented.");
  },
  productType: () => {
    throw new Error("Not implemented.");
  },
  show: () => {
    throw new Error("Not implemented.");
  },
  hide: () => {
    throw new Error("Not implemented.");
  },
  toggleCartBtn: () => {
    throw new Error("Not implemented.");
  },
  ...emptyState,
}

export type CartContext = {
  add: (item: ItemInput, quantity?: number) => void;
  remove: (ids: string[]) => void;
  allowToAdd: (item: ItemInput) => {
    allow: boolean;
    code: string;
    msg: string;
  };
  empty: () => void;
  addDiscountCode: (code: string | null) => void;
  emptyCache: () => void;
  updateSubtotal: (id: string, subtotal: number) => void;
  updateQuantity: (id: string, qty: number) => void;
  restore: (state: CartReducerState) => void;
  productType: () => "PLAIN" | "SUBSCRIPTION" | "BOOKING" | undefined;
  show: () => void;
  hide: () => void;
  toggleCartBtn: (toggle: boolean) => void;
} & CartReducerState;

export const Cart = React.createContext<CartContext>(init);

// If you change this name, don't forget to change it in cypress test.
const cartName = "Cart";

export const withCache = (reducer: React.Reducer<CartReducerState, Action>) => (s: CartReducerState, a: Action) => {
  const newS = reducer(s, a);
  localStorage.setItem(cartName, JSON.stringify(newS));

  return newS;
};

export const CartProvider: React.FC<{
  initialState?: CartReducerState;
  loadFromLocalStorage?: boolean;
  children?: React.ReactNode;
}> = ({
  children,
  initialState = emptyState,
  loadFromLocalStorage = true,
}) => {
  const { t, i18n } = useTranslation();

  const router = useRouter();

  if (typeof localStorage === "undefined" && loadFromLocalStorage) {
    return null;
  }

  let initState = initialState;
  let reducer = CartReducer;

  if (loadFromLocalStorage) {
    reducer = withCache(CartReducer);
    const load = localStorage.getItem(cartName);
    if (load != null) {
      const data = JSON.parse(load);
      if (!CartReducerState.guard(data)) {
        // If the guard fails, something has changed, and the cart must be discarded.
        console.warn("Discarding invalid cart data.", data);
        localStorage.removeItem(cartName);
      }
      else {
        initState = { ...data, showCartModal: false, showCartBtn: true };
      }
    }
  }

  const [state, dispatch] = useReducer(reducer, initState);

  const contextValues: CartContext = {
    ...state,
    toggleCartBtn: (value: boolean) => {
      dispatch({ type: "TOGGLECARTBTN", value });
    },
    add: (item, quantity) => {
      dispatch({ type: "ADDITEM", item, quantity });
    },
    remove: (ids: string[]) => {
      dispatch({ type: "REMOVEITEMS", ids });
    },
    updateSubtotal: (id: string, subtotal: number) => {
      dispatch({ type: "UPDATESUBTOTAL", id, subtotal });
    },
    addDiscountCode: (code: string | null) => {
      dispatch({ type: "ADD_DISCOUNT_CODE", code })
    },
    show: () => {
      dispatch({ type: "SHOWCART" });
    },
    hide: () => {
      dispatch({ type: "HIDECART" });
    },
    empty: () => {
      localStorage.removeItem(cartName);
      dispatch({ type: "RESTORE", state: emptyState });
    },
    emptyCache: () => {
      localStorage.removeItem(cartName);
    },
    allowToAdd: (item) => {
      // Can only add 1 vendor at a time.
      if (state.items.some((i) => i.vendor.uuid !== item.vendor.uuid)) {
        const cartNotEmptyMsg = (type: Item["type"], count: number) => `${{
          BOOKING: t("main.cartContainsBookingsFromVendorMsg", { count, vendor: state.items[0].vendor.name }),
          PLAIN: t("main.cartContainsItemsFromVendorMsg", { count, vendor: state.items[0].vendor.name }),
          SUBSCRIPTION: t("main.cartContainsSubscriptionsFromVendorMsg", { count, vendor: state.items[0].vendor.name }),
        }[type]}`;

        return {
          allow: false,
          code: "CART_NOT_EMPTY",
          msg: cartNotEmptyMsg(state.items[0].type, state.itemCount),
        };
      }

      // Can only add 1 product type at a time.
      if (state.items.some((i) => i.type !== item.type)) {
        const containsDifferentTypeMsg = (typePresent: Item["type"], count: number, typeNew: Item["type"]) => `${{
          BOOKING: {
            PLAIN: t("main.cartContainsItemsOnBookingAddMsg", { count }),
            SUBSCRIPTION: t("main.cartContainsSubscriptionsOnBookingAddMsg", { count }),
          },
          PLAIN: {
            BOOKING: t("main.cartContainsBookingsOnItemAddMsg", { count }),
            SUBSCRIPTION: t("main.cartContainsSubscriptionsOnItemAddMsg", { count }),
          },
          SUBSCRIPTION: {
            PLAIN: t("main.cartContainsItemsOnSubscriptionAddMsg", { count }),
            BOOKING: t("main.cartContainsBookingsOnSubscriptionAddMsg", { count }),
          },
        }[typeNew][typePresent]}`;

        return {
          allow: false,
          code: "CONTAINS_DIFFERENT_PRODUCT_TYPE",
          msg: containsDifferentTypeMsg(state.items[0].type, state.itemCount, item.type),
        };
      }

      // Subscription can only be 1.
      if (item.type === "SUBSCRIPTION" && state.items.length > 0) {
        return {
          allow: false,
          code: "ONLY_ONE_SUBSCRIPTION",
          msg: t("main.cartContainsSubscriptionMsg"),
        };
      }

      if (item.type === "BOOKING") {
        // We don't actually need to do this since we have CONTAINS_DIFFERENT_PRODUCT_TYPE validation but just in case.
        const bookingItems = state.items.filter((i): i is Extract<Item, { type: "BOOKING" }> => i.type === "BOOKING");

        // Booking can only have 1 product.
        if (item.type === "BOOKING" && bookingItems.some((i) => i.uuid !== item.uuid)) {
          return {
            allow: false,
            code: "ONLY_SINGLE_BOOKING_PRODUCT",
            msg: t("main.cartContainsSessionsFromDifferentBookingMsg"),
         };
        }

        // Booking can have multiple sessions from 1 product.
        if (item.type === "BOOKING") {
          const identical = bookingItems.find((i) =>
            i.selected.session.sessionTemplateUuid === item.selected.session.sessionTemplateUuid &&
            i.selected.session.start === item.selected.session.start &&
            i.selected.session.end === item.selected.session.end,
          );

          if (identical != null) {
            return {
              allow: false,
              code: "ONLY_SINGLE_BOOKING_SESSION",
              msg: t("main.bookingForDateAlreadyInCart", {
                date: formatters.datelong(identical.selected.session.start, i18n.language, { timezone: item.timezone }),
                time: formatters.timeshort(identical.selected.session.start, i18n.language, { timezone: item.timezone }),
              }),
            };
          }
        }
      }

      return { allow: true, code: "OK", msg: "OK" };
    },
    updateQuantity: (id, quantity) => {
      dispatch({ type: "UPDATEQTY", id, quantity });
    },
    productType: () => state.items[0]?.type,
    restore: (stateInput: CartReducerState) => {
      dispatch({ type: "RESTORE", state: stateInput });
    },
  }

  useEffect(() => {
    if (state.showCartModal) {
      dispatch({ type: "HIDECART" })
    }
  }, [router.asPath]);

  return (
    <Cart.Provider value={contextValues} >
      { children }
    </Cart.Provider>
  );
}
