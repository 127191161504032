import React, { Component } from "react";
import { ErrorMessage } from "./ErrorMessage";
import {
  withTranslation,
  type WithTranslationProps,
} from "../generated/localisation";

interface Props extends WithTranslationProps {
  errorContent?: React.ReactNode | ((error: any) => React.ReactNode);
}
interface State {
  error?: any;
}

class ErrorBoundaryInternal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public static getDerivedStateFromError(error: any) {
    return { error };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  private renderError() {
    const { error } = this.state;

    if (typeof this.props.errorContent === "function") {
      return this.props.errorContent(error);
    }

    if ("errorContent" in this.props) {
      return this.props.errorContent;
    }

    return (
      <ErrorMessage className="text-center">
        {error instanceof Error
          ? error.message
          : this.props.t("lib.sorryUnknownError")}
      </ErrorMessage>
    );
  }

  public render() {
    if (this.state.error != null) {
      return this.renderError();
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryInternal);
