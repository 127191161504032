import React from "react";
import { withDefaults } from "./Icon";

type Props = { className?: string; strokeWidth?: number } & (
  { mode?: "indeterminate"; progress?: number } | { mode: "determinate"; progress: number }
);

export const ProgressCircle = withDefaults<Props>(
  { width: 16, height: 16 },
  ({ width, height, color, className, mode, progress = 0, strokeWidth = 6 }) => {

  const offset = 187;
  const progressNum = mode === "determinate"
    ? (1 - (progress / 100)) * offset
    : 0.3 * offset;

  return (
    <span className={className}>
      <svg
        className={`progress-circle${mode !== "determinate" ? " spin" : " progress"}`}
        width={width} height={height}
        viewBox="0 0 66 66"
      >
        <circle
          className="path"
          cx="33" cy="33" r="30"
          fill="none"
          strokeWidth={strokeWidth} strokeMiterlimit="10" strokeLinecap="square"
        />
      </svg>

      <style jsx>{`
        .path {
          stroke-dasharray: ${offset}px;
          stroke-dashoffset: ${progressNum}px;
          stroke: ${color};
          transform-origin: center;
        }

        .spin {
          animation: rotate 1.5s linear infinite;
        }

        .spin .path {
          animation: dash 1.5s ease-in-out infinite;
        }

        .progress .path {
          transition: stroke-dashoffset 350ms;
        }

        @keyframes rotate {
          0% { transform: rotate(0deg) }
          100% { transform: rotate(360deg) }
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: ${offset}px;
          }
          50% {
            stroke-dashoffset: ${offset/4}px;
            transform:rotate(135deg);
          }
          100% {
            stroke-dashoffset: ${offset}px;
            transform:rotate(360deg);
          }
        }
      `}</style>
    </span>
  );
});
