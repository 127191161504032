import color from "color";
import React from "react";
import { useLogoBackground } from "../hooks/useLogoBackground";

interface Props {
  hoverOpacity?: number;
  activeOpacity?: number;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
  width?: string;
  height?: string;
  children?: React.ReactNode;
}

export const SiteNavbarIconLink: React.FC<Props> = ({
  children,
  onClick,
  tabIndex,
  activeOpacity = 0.2,
  hoverOpacity = 0.15,
  className = "",
  width = "40px",
  height = "40px",
  disabled = false,
}) => {
  const { fgColor } = useLogoBackground();
  const baseColor = color(fgColor);
  const activeColor = baseColor.alpha(activeOpacity).rgb().string();
  const hoverColor = baseColor.alpha(hoverOpacity).rgb().string();
  const borderColor = baseColor.alpha(0.2).rgb().string();

  return (
    <>
      <a className={`icon-btn-bg ${className}`} tabIndex={tabIndex} onClick={onClick}>
        {children}
      </a>
      <style jsx>{`
        .icon-btn-bg {
          outline: none;
          user-select: none;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          border: 1px solid ${borderColor};
          border-radius: 100%;
          transition: .25s ease-in-out;
          padding: 5px;
          width: ${width};
          height: ${height};
          cursor: pointer;
          opacity: ${disabled ? 0.5 : 1};
        }
        .icon-btn-bg:hover {
          background: ${hoverColor};
        }
        .icon-btn-bg:active {
          background: ${activeColor};
        }
      `}</style>
    </>
  );
}
