import { useTranslation } from "@equiem/web-ng-lib";
import { Session } from "@equiem/web-ng-lib";
import React, { useContext, useEffect } from "react";

export const ApolloLocaleProvider: React.FC = ({ children }) => {
  const { setLocale } = useContext(Session);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (setLocale) {
      setLocale(i18n.language);
    }
  }, [setLocale, i18n.language])

  return <>{children}</>;
};
