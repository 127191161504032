import { useApolloClient } from "@apollo/client";
import { PushNotificationPayloads } from "@equiem/user-pubsub";
import { useMemo } from "react";

export const useOrderAdjustmentHandler = () => {
  const { cache } = useApolloClient();

  return useMemo(() => ({
    onMessage: async (_data: Partial<PushNotificationPayloads.Data.OrderAdjustmentData>) => {
      cache.evict({ fieldName: "activityFeedV2", broadcast: true });
      cache.evict({ fieldName: "customerOrders", broadcast: true });
      cache.gc();
    },
  }), [cache]);
};
