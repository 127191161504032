import { MutableRefObject } from "react";

type RefCallback<T> = { bivarianceHack(instance: T | null): void }["bivarianceHack"];

type Ref<T> = RefCallback<T> | MutableRefObject<T> | null;

export const provideRef = <T>(ref: Ref<T> | null, instance: T) => {
  if (ref != null) {
    if (typeof ref === "function") {
      ref(instance);
    }
    else {
      ref.current = instance;
    }
  }
};
