import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { SubscriptionBillingCycle } from "../../../generated/gateway-client";
import { PriceFormat } from "../../PriceFormat";
import { SearchNode } from "./SearchEdge";
import { SearchResult } from "./SearchResult";

interface Props {
  product: SearchNode<"SubscriptionProductContentEdge">;
}

export const SubscriptionProductSearchResult: React.FC<Props> = ({
  product,
}) => {
  const { t } = useTranslation();

  const description = (
    <>
      <div>{product.vendor.name}</div>
      <div>{product.excerpt}</div>
    </>
  );

  const cycle =
    product.billingCycle === SubscriptionBillingCycle.Weekly
      ? t("main.week")
      : t("main.month");
  const footer = (
    <>
      <PriceFormat price={product.unitPrice} freeTxt={t("main.free")} />/{cycle}
    </>
  );

  return (
    <SearchResult
      href="/store/product/[uuid]"
      as={`/store/product/${product.uuid}`}
      title={product.name}
      body={description}
      imageUrl={product.image ?? undefined}
      footer={footer}
    />
  );
};
