import React from "react";
import { AiOutlineCreditCard } from "react-icons/ai";
import { CardBrand } from "../../generated/gateway-client";

interface Props {
  brand: CardBrand;
}

export const CreditCardBrand: React.FC<Props> = ({ brand }) => (
  <>
    { brand === CardBrand.Unknown ? (
      <span className="mr-1 d-inline-block unknown">
        <AiOutlineCreditCard size="30" />
      </span>
    ) : (
      <span className="mr-1 d-inline-block image">
        <img src={`/images/credit-card-brands/${brand.toLowerCase()}.png`} width="40" />
      </span>
    )}
    <style jsx>{`
    .unknown {
      width: 40px;
      height: 35px;
    }
    .image {
      height: 35px;
      line-height:35px;
    }
  `}</style>
  </>
);
