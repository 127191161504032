import { AccessTokenManagerSync, ClientProvider } from "@equiem/user-pubsub";
import { Session } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import React, { createContext, useContext, useEffect, useState } from "react";
import { PushNotificationsContextProvider } from "./PushNotificationsContext";

export interface PubsubContext {
  tokenManager: AccessTokenManagerSync | null;
  clientProvider: ClientProvider | null;
};

let cache: PubsubContext = {
  tokenManager: null,
  clientProvider: null,
};

export const PubsubContext = createContext<PubsubContext>({
  tokenManager: null,
  clientProvider: null,
});

export const PubsubProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const session = useContext(Session);
  const site = useContext(Site);
  const [state, setState] = useState<PubsubContext>(cache);

  useEffect(() => {
    if (state.tokenManager == null && session.accessToken != null && site.webSocketEndpoint != null) {
      const tokenManager = new AccessTokenManagerSync(session.accessToken);

      setState({
        tokenManager,
        clientProvider: new ClientProvider(
          site.webSocketEndpoint,
          tokenManager,
          "web",
          "subscriber",
        ),
      });
    }
    else if (state.tokenManager != null && session.accessToken != null) {
      state.tokenManager.setAccessToken(session.accessToken);
    }
  }, [session]);

  useEffect(() => {
    cache = state;
  }, [state]);

  return (
    <PubsubContext.Provider value={state}>
      <PushNotificationsContextProvider>
        {children}
      </PushNotificationsContextProvider>
    </PubsubContext.Provider>
  )
};
