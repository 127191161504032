import React, { createContext } from "react";
import Head from "next/head";

interface Iframely {
  load(containerElement?: HTMLElement, url?: string): void;
  load(linkElement?: HTMLAnchorElement): void;
  load(): void;
}

declare global {
  interface Window {
    iframely?: Iframely;
  }
}

export const IframelyContext = createContext<Iframely>({
  load(element?: HTMLElement, url?: string) {
    window.iframely?.load(element, url);
  },
});

export const IframelyProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <>
    <Head>
      <script
        src={`//cdn.iframe.ly/embed.js?key=${process.env.iframelyApiKey}`}
        async
      ></script>
    </Head>
    {children}
  </>
);
