import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import { FieldError } from "react-hook-form";
import { useFormGroupControl } from "./useFormGroupControl";
import { FormGroup } from "./FormGroup";

interface Props {
  className?: string;
  inline?: boolean;
  inputClass?: string;
  error?: FieldError;
  id?: string;
  label: string;
  name: string;
  required?: boolean;
  value?: string | null;
  suffix?: JSX.Element | null;
  labelMode?: "animated" | "placeholder";
  autoFocus?: boolean;
  autoComplete?: string;
  type?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  maxLength?: number;
}

export const TextField = forwardRef<HTMLInputElement, Props>(({
  className = "",
  inline = false,
  inputClass = "",
  error,
  id: inputId,
  label,
  name,
  required = false,
  value = "",
  suffix = null,
  labelMode = "animated",
  autoFocus = false,
  autoComplete,
  type = "text",
  disabled = false,
  onChange = () => undefined,
  maxLength,
}, ref) => {
  const { id, empty, focused, setEmpty, setFocused } = useFormGroupControl({ id: inputId, name, value: value ?? undefined });

  return (
    <FormGroup
      empty={empty}
      focused={focused}
      label={labelMode === "animated" ? label : undefined}
      required={required}
      className={className}
      error={error}
      id={id}
    >
      <Form.Control
        as="input"
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        className={(inline ? "col mr-2 " : "") + inputClass}
        defaultValue={value ?? undefined}
        placeholder={labelMode === "placeholder" ? label : undefined}
        onChange={(e) => {
          setEmpty(e.target.value.length === 0);
          onChange(e.target.value);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        ref={ref}
        isInvalid={error != null}
        maxLength={maxLength}
      />
      {suffix}
      {inline ? (<style jsx>{`
        .form-group {
          width: 100%;
        }`}
        </style>
      ) : null}
    </FormGroup>
  );
});
