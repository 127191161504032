import React, { useState } from "react";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { SmileyAngryFace } from "../icons/feedback/SmileyAngryFace";
import { SmileyFrownFace } from "../icons/feedback/SmileyFrownFace";
import { SmileyGrinFace } from "../icons/feedback/SmileyGrinFace";
import { SmileyMehFace } from "../icons/feedback/SmileyMehFace";
import { SmileySmileFace } from "../icons/feedback/SmileySmileFace";
import { FeedbackIcon as FeedbackIconType } from "./FeedbackTypes";

export const FeedbackIcon: React.FC<{ score: number }> = ({ score }) => {
  const scores = [1, 2, 3, 4, 5] as const;
  type ScoreType = typeof scores[number];
  const feedbackContext = useFeedbackContext();

  const [hovered, setHovered] = useState(false);

  const icons: { [k in ScoreType]: FeedbackIconType } = {
    1: SmileyAngryFace,
    2: SmileyFrownFace,
    3: SmileyMehFace,
    4: SmileySmileFace,
    5: SmileyGrinFace,
  };

  const Icon: FeedbackIconType = icons[score as ScoreType];
  let selected = false;

  if (feedbackContext.submitted) {
    selected = icons[feedbackContext.response.value as ScoreType] === Icon;
  }

  return (
    <div
      onMouseOver={() => {
        if (!feedbackContext.submitted) {
          setHovered(true);
        };
      }}
      onMouseOut={() => {
        if (!feedbackContext.submitted) {
          setHovered(false);
        };
      }}
    >
      <Icon size={ selected || hovered ? 48 : 32 } />
    </div>
  );
};
