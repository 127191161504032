import {
  IframelyProvider,
  PageTitleProvider,
  Site,
  SiteContext,
} from "@equiem/web-ng-lib";
import React from "react";
import { IconContext } from "react-icons";
import { NewRelic } from "../components/newrelic/NewRelic";
import { SiteHead } from "../components/SiteHead";

export const SiteProviders: React.FC<{
  site: SiteContext;
  children?: React.ReactNode;
}> = ({ site, children }) => (
  <Site.Provider value={site}>
    <PageTitleProvider>
      <NewRelic />
      <IconContext.Provider
        value={{
          size: "1em",
          style: { verticalAlign: "middle", marginBottom: ".15em" },
        }}
      >
        <IframelyProvider>
          <SiteHead />
          {children}
        </IframelyProvider>
      </IconContext.Provider>
    </PageTitleProvider>
  </Site.Provider>
);
