import React from "react";

export const UserCartItemSkeleton: React.FC = () => (
  <div className="skeleton-cart">
    <div className="body text-left overflow-hidden">
      <div className="line shimmer"></div>
      <div className="line shimmer"></div>
      <div className="line shimmer"></div>
      <div className="line shimmer"></div>
    </div>
    <style jsx>{`
      .skeleton-cart {
        min-height:100px;
        background: #fff;
      }
      .body {
        line-height: 1;
        height: 100px;
      }
      .line {
        display: inline-block;
        padding: .25rem;
      }
      .line:nth-child(1) { width: 70%; margin: 0 0 .5rem 0; padding: .5rem }
      .line:nth-child(2) { width: 90% }
      .line:nth-child(3) { width: 80% }
      .line:nth-child(4) { width: 55% }
    `}</style>
  </div>
);
