import { useTranslation } from "@equiem/web-ng-lib";
import { Button } from "@equiem/web-ng-lib";
import React from "react";
import { Form } from "react-bootstrap";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { useFeedbackSubmission } from "../../hooks/useFeedbackSubmission";

export const FeedbackComment: React.FC<{}> = () => {
  const { t } = useTranslation();
  const feedbackContext = useFeedbackContext();
  const submission = useFeedbackSubmission();

  const submitFeedback = async () => {
    feedbackContext.toggleVisibility("hidden");
    feedbackContext.dismissForever();
    if (typeof feedbackContext.response.comment !== "undefined") {
      await submission.submitFeedback(1);
    }
  };

  return (
    <>
      <Form.Control
        as="textarea"
        autoFocus
        className="mt-3 font-smaller pt-1"
        placeholder={t("main.howCanWeFurtherImproveYourExperience")}
        onChange={(e) => {
          feedbackContext.setResponseComment(e.target.value);
        }}
      />
      <span className="align-self-start text-muted mt-1 font-10">* {t("main.yourCommentAnonymous")}</span>

      <Button type="button" className="mt-3 mb-1" variant="solid" onClick={submitFeedback}>{t("main.submitComment")}</Button>
      <style jsx global>
        {`
          .font-smaller {
            font-size: 0.8rem;
          }
          .font-10 {
            font-size: 10px;
          }
        `}
      </style>
    </>
  );
};
