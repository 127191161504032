import { PushNotificationPayloads } from "@equiem/user-pubsub";
import { useMemo } from "react";
import { useAuthenticatedViewer } from "../useAuthenticatedViewer";

export const useProfileUpdatedHandler = () => {
  const viewer = useAuthenticatedViewer();

  return useMemo(() => ({
    onMessage: async (data: Partial<PushNotificationPayloads.Data.ProfileUpdatedData>) => {
      if (
        viewer.profile?.updated != null &&
        data.updatedAt != null &&
        parseInt(data.updatedAt, 10) > viewer.profile.updated
      ) {
        await viewer.refetch();
      }
    },
  }), [viewer]);
};
