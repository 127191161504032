import React from "react";
import { ThumbsDown } from "./icon/ThumbsDown";

interface Props {
  children: string;
  className?: string;
}

export const ErrorMessage: React.FC<Props> = ({
  children,
  className = "",
}) => (
  <div className={`p-5 ${className}`}>
    <span style={{ opacity: 0.3 }}><ThumbsDown /></span>
    <p className="my-3" style={{ opacity: 0.5 }}>{children}</p>
  </div>
);
