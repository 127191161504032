import { Message, PushNotificationPayload, PushNotificationSubscriber } from "@equiem/user-pubsub";
import { notNullOrUndefined, useShowError } from "@equiem/web-ng-lib";
import { uniqBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { PushNotificationsContext } from "../contexts/PushNotificationsContext";
import { PushNotificationsQuery, useDeletePushNotificationMutation, usePushNotificationsQuery } from "../generated/gateway-client";
import { usePushNotificationHandler } from "./usePushNotificationHandler";

export interface PushNotificationMessage {
  uuid: string;
  timestamp: number;
  notification?: NonNullable<PushNotificationsQuery["savedPushNotifications"]["edges"][number]["node"]>["notification"];
  data?: Extract<PushNotificationPayload, { type: "created" }>["data"];
}
export interface PushNotifications {
  messages: PushNotificationMessage[];
  totalCount: number;
  subscriber: PushNotificationSubscriber | null;
  subscribed: boolean;
  loading: boolean;
  error: string | Error | null;
}

let cacheConnected = false;
let lastMessageTime: number | undefined;

/**
 * Hook which queries for saved push notifications via GraphQL, and receives notifications via websockets,
 * and merges the 2 into 1 set of messages.
 */
export const usePushNotifications = () => {
  const first = 10;
  const showError = useShowError();
  const { subscriber, connected, error: subscribeError } = useContext(PushNotificationsContext);
  const [deleteNotification] = useDeletePushNotificationMutation();

  const { data, error, loading, updateQuery, fetchMore, refetch, variables } = usePushNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { first },
    skip: !connected,
  });

  useEffect(() => {
    // Refetch query when connection re-established.
    if (connected && !cacheConnected) {
      refetch(variables)?.catch();
    }
    cacheConnected = connected;
  }, [connected]);

  const deleteNotificationAndUpdate = useMemo(() => async ({ uuid }: PushNotifications["messages"][number]) => {
    try {
      await deleteNotification({ variables: { uuid } });
    }
    catch (e) {
      showError(e);
    }

    updateQuery((prev) => {
      const edges = prev.savedPushNotifications.edges;
      const newEdges = edges.filter((e) => e.node?.uuid !== uuid);

      return {
        savedPushNotifications: {
          ...prev.savedPushNotifications,
          edges: newEdges,
          totalCount: prev.savedPushNotifications.totalCount - (edges.length - newEdges.length),
        },
      };
    });
  }, [updateQuery]);

  const handler = usePushNotificationHandler({ onDelete: deleteNotificationAndUpdate });

  /**
   * Call onMessage handler for any messages received since the last one
   * regardless of the source (ie. via WebSocket or GraphQL query).
   */
  useEffect(() => {
    const nextTimestamp = data?.savedPushNotifications.edges[0]?.node?.timestamp;
    const nextMessageTime = nextTimestamp ?? 0;
    if (lastMessageTime != null && lastMessageTime < nextMessageTime) {
      const newMessages = data?.savedPushNotifications.edges.filter(
        (e) => (lastMessageTime! < (e.node?.timestamp ?? 0)),
      ).filter(notNullOrUndefined).map((e) => e.node).filter(notNullOrUndefined) ?? [];

      if (newMessages.length > 0) {
        newMessages.forEach((newMessage) => {
          handler.onMessage(newMessage);
        });
      }
    }

    lastMessageTime = nextTimestamp;
  }, [data?.savedPushNotifications.edges[0]?.node?.timestamp, handler]);

  const onMessage = useCallback((message: Message<PushNotificationPayload>) => {
    const payload = message.payload;

    switch (payload.type) {
      case "deleted": {
        updateQuery((prev) => {
          const edges = prev.savedPushNotifications.edges;
          const newEdges = edges.filter((e) => e.node?.uuid !== payload.uuid);

          return {
            savedPushNotifications: {
              ...prev.savedPushNotifications,
              edges: newEdges,
              totalCount: prev.savedPushNotifications.totalCount - (edges.length - newEdges.length),
            },
          };
        });
        break;
      }

      case "created": {
        handler.onMessage({ uuid: message.uuid, timestamp: message.timestamp, ...payload });

        if (payload.notification == null) {
          // Don't add silent notifications to results.
          break;
        }

        updateQuery((prev) => {
          const edges = prev.savedPushNotifications.edges;

          const newEdges = [
            {
              __typename: "PushNotificationEdge" as const,
              node: {
                __typename: "PushNotification" as const,
                timestamp: message.timestamp,
                uuid: message.uuid,
                data: payload.data,
                notification: {
                  __typename: "PushNotificationNotification" as const,
                  body: payload.notification?.body ?? null,
                  tag: payload.notification?.tag ?? null,
                  title: payload.notification?.title ?? null,
                  webIcon: payload.notification?.webIcon ?? null,
                },
              },
            },
            ...edges,
          ];

          const uniqueSortedEdges = uniqBy(
            newEdges,
            (e) => e.node?.notification?.tag ?? e.node?.uuid,
          ).sort((a, b) => {
            const ta = a.node?.timestamp ?? 0;
            const tb = b.node?.timestamp ?? 0;

            return ta > tb ? -1 : (ta < tb ? 1 : 0);
          });

          return {
            savedPushNotifications: {
              ...prev.savedPushNotifications,
              edges: uniqueSortedEdges,
              totalCount: prev.savedPushNotifications.totalCount + (uniqueSortedEdges.length - edges.length),
            },
          };
        });
        break;
      }
    }
  }, [updateQuery, handler]);

  useEffect(() => {
    if (subscriber == null) {
      return;
    }

    subscriber.on("message", onMessage).catch(showError);

    return () => {
      subscriber.off("message", onMessage).catch(showError);
    };
  }, [subscriber, onMessage]);

  const justFetchMore = useMemo(() => async () => {
    const after = data?.savedPushNotifications.pageInfo.endCursor;
    if (after == null) {
      return;
    }

    await fetchMore({ variables: { after } });
  }, [fetchMore, data?.savedPushNotifications.pageInfo.endCursor]);

  return {
    messages: data?.savedPushNotifications.edges.map((e) => (
      e.node == null ? null : {
        uuid: e.node.uuid,
        timestamp: e.node.timestamp,
        notification: e.node.notification,
        data: e.node.data,
      }
    )).filter(notNullOrUndefined) ?? [],
    totalCount: data?.savedPushNotifications.totalCount,
    hasMore: data?.savedPushNotifications.pageInfo.hasNextPage ?? false,
    fetchMore: justFetchMore,
    loading: (!connected && subscribeError == null) || loading,
    error: subscribeError ?? error ?? null,
    deleteNotification: deleteNotificationAndUpdate,
  };
};
