import { useTranslation } from "@equiem/web-ng-lib";
import { Theme } from "@equiem/web-ng-lib";
import React, { useContext, useMemo } from "react";
import { PushNotificationsContext } from "../../contexts/PushNotificationsContext";
import { SideMenuContext } from "../../contexts/SideMenuProvider";
import { usePushNotifications } from "../../hooks/usePushNotifications";
import { Notifications } from "./Notifications";

interface Props {
  className?: string;
}

export const NoticationSummary: React.FC<Props> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { breakpoints } = useContext(Theme);
  const sideMenu = useContext(SideMenuContext);
  const { connected } = useContext(PushNotificationsContext);
  const {
    messages,
    totalCount,
    loading,
    hasMore,
    fetchMore,
    deleteNotification,
  } = usePushNotifications();
  const count = useMemo(() => totalCount ?? 0, [totalCount]);

  return (
    <div className={className}>
      <div className="title">{t("main.notificationsCount", { count })}</div>
      <Notifications
        messages={messages}
        loading={loading || !connected}
        hasMore={hasMore}
        fetchMore={fetchMore}
        deleteNotification={deleteNotification}
        onClickNotification={() => {
          sideMenu.hide();
        }}
      />
      <style jsx>{`
        .title {
          font-weight: 600;
          font-size: 10px;
        }
        @media (min-width: ${breakpoints.md}px) {
          .title {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  )
}
